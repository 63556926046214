import { useEffect, useMemo, useState } from "react";
import { BaseModalActions, BaseModalContent, BaseModalHeader } from "@/components/BaseModal";
import BaseButton from "@/components/base-button";
import BaseInput from "@/components/base-input";
import PhoneInput from "@/components/base-input/phoneInput";
import BaseTextarea from "@/components/base-textarea";
import Checkbox from "@/components/checkbox";
import styles from "./styles.module.scss";
import { SntRegistry } from "@/types";

interface IEditOwnerModalProps {
  isOpen: boolean;
  owner: SntRegistry.CreatedOwner | SntRegistry.SectionOwner | null;
  ownerChange: SntRegistry.SectionDetailsResponse["Included"];
  readonly: boolean;
  onClose: () => void;
  onEdit: (owner: SntRegistry.CreatedOwner | SntRegistry.SectionOwner) => void;
  onAdd: (owner: Omit<SntRegistry.CreatedOwner, "Id">) => void;
  left: number;
}

const EditOwnerModal: React.FC<IEditOwnerModalProps> = ({
  isOpen,
  owner,
  ownerChange,
  readonly,
  left,
  onClose,
  onEdit,
  onAdd,
}) => {
  const [form, setForm] = useState<SntRegistry.OwnerForm>(new SntRegistry.OwnerForm());

  const isEditDisabled: boolean = useMemo(() => {
    return ownerChange.find((it) => it.OwnerId === owner?.Id && !it.Attributes.Updated) != null;
  }, [owner, ownerChange]);

  const title: string = useMemo(() => {
    if (readonly) return "Подробнее";
    if (owner) return "Редактировать собственника";
    return "Добавить собственника";
  }, [readonly, owner]);

  const updateFormFieldValue = (
    key: "FullName" | "RegistrationAddress" | "MailingAddress" | "Email" | "Phone",
    value: string
  ) => {
    setForm((prevState) => ({
      ...prevState,
      [key]: { ...form[key], value },
    }));
  };

  const handleClose = () => {
    onClose();
  };

  const isFormValid = () => {
    const validator = SntRegistry.OwnerFormValidator.isInvalid(form);

    if (validator) {
      setForm((prevState) => ({
        ...prevState,
        FullName: {
          ...form.FullName,
          error: validator.FullName,
        },
        RegistrationAddress: {
          ...form.RegistrationAddress,
          error: validator.RegistrationAddress,
        },
        MailingAddress: {
          ...form.MailingAddress,
          error: validator.MailingAddress,
        },
        Email: {
          ...form.Email,
          error: validator.Email,
        },
        Phone: {
          ...form.Phone,
          error: validator.Phone,
        },
      }));
    }
    return !validator;
  };

  const handleSubmit = () => {
    Object.keys(form).forEach((key: string) => {
      if (typeof (form as any)[key] === "object" && (form as any)[key]?.error) {
        setForm((prevState) => ({
          ...prevState,
          [key]: { ...(form as any)[key], error: "" },
        }));
      }
    });
    setForm((prevState) => ({ ...prevState, error: "" }));

    if (!isFormValid()) {
      return;
    }

    if (owner) {
      onEdit({
        ...owner,
        FullName: form.FullName.value,
        RegistrationAddress: form.RegistrationAddress.value,
        MailingAddress: form.MailingAddress.value,
        Email: form.Email.value,
        Phone: form.Phone.value,
        IsMember: form.IsMember,
      });
    } else {
      onAdd({
        FullName: form.FullName.value,
        RegistrationAddress: form.RegistrationAddress.value,
        MailingAddress: form.MailingAddress.value,
        Email: form.Email.value,
        Phone: form.Phone.value,
        IsMember: form.IsMember,
      });
    }
  };

  useEffect(() => {
    if (isOpen) {
      setForm(new SntRegistry.OwnerForm(owner ?? undefined));
    }
  }, [isOpen]);

  return (
    <div className={styles.owner_modal} style={{ left: `${left}px` }}>
      <BaseModalHeader title={title} onClose={handleClose} />

      <BaseModalContent sx={{ padding: "16px 24px" }}>
        <div className={styles.owner_form}>
          <div className={styles.full_field}>
            <BaseInput
              value={form.FullName.value}
              disabled={readonly || isEditDisabled}
              label="Данные собственника"
              required={form.FullName.isRequired}
              placeholder="Введите ФИО"
              errorMessage={form.FullName.error}
              maxlength={form.FullName.maxlength}
              onChange={(value) => updateFormFieldValue("FullName", value)}
            />
          </div>

          <div>
            <BaseTextarea
              minRows={1}
              value={form.RegistrationAddress.value}
              disabled={readonly}
              label="Адрес регистрации"
              required={form.RegistrationAddress.isRequired}
              placeholder="Введите адрес регистрации"
              errorMessage={form.RegistrationAddress.error}
              maxlength={form.RegistrationAddress.maxlength}
              onChange={(value) => updateFormFieldValue("RegistrationAddress", value)}
            />
          </div>

          <div>
            <BaseTextarea
              minRows={1}
              value={form.MailingAddress.value}
              disabled={readonly}
              label="Почтовый адрес"
              required={form.MailingAddress.isRequired}
              placeholder="Введите почтовый адрес"
              errorMessage={form.MailingAddress.error}
              maxlength={form.MailingAddress.maxlength}
              onChange={(value) => updateFormFieldValue("MailingAddress", value)}
            />
          </div>

          <div>
            <BaseInput
              value={form.Email.value}
              disabled={readonly}
              label="Адрес электронной почты"
              required={form.Email.isRequired}
              placeholder="Например: (Locolo@gmail.com)"
              errorMessage={form.Email.error}
              maxlength={form.Email.maxlength}
              onChange={(value) => updateFormFieldValue("Email", value)}
            />
          </div>

          <div>
            <PhoneInput
              value={form.Phone.value}
              disabled={readonly}
              label="Номер телефона"
              required={form.Phone.isRequired}
              placeholder="Введите номер телефона"
              errorMessage={form.Phone.error}
              onChange={(value) => updateFormFieldValue("Phone", value)}
            />
          </div>

          <div className={styles.full_field}>
            <div className="flex middle" style={{ gap: "10px" }}>
              <Checkbox
                checked={form.IsMember}
                disabled={readonly}
                onChange={(value) =>
                  setForm((prevState) => ({
                    ...prevState,
                    IsMember: value,
                  }))
                }
              />
              <p className="sf-text-regular text--primary">Является членом садоводства</p>
            </div>
          </div>
        </div>
      </BaseModalContent>

      <BaseModalActions>
        {readonly ? (
          <BaseButton onClick={handleClose}>Закрыть</BaseButton>
        ) : (
          <>
            <BaseButton variant="secondary" onClick={handleClose}>
              Отменить
            </BaseButton>
            <BaseButton onClick={handleSubmit}>{owner ? "Сохранить" : "Добавить"}</BaseButton>
          </>
        )}
      </BaseModalActions>
    </div>
  );
};

export default EditOwnerModal;
