import { Profile } from "@/types/profile";
import { OCCV2Author } from ".";
import { OCCV2Order } from "../order";

export function getAdministratorBody(
  administrator: OCCV2Author.Person | null,
  OccId?: OCCV2Order.Base["Id"]
): OCCV2Author.SetAdministratorBody | undefined {
  switch (administrator?.Type) {
    case OCCV2Author.PersonType.Individual:
      return {
        OccId,
        TypeId: administrator.Type,
        Object: {
          Name: administrator.Name,
          Phone: administrator.Phone ? String((administrator as OCCV2Author.Individual).Phone) : "",
          Surname: administrator.Surname,
          Patronymic: administrator.Patronymic,
          PermanentResidencePlace: administrator.PermanentResidencePlace,
          Email: administrator.Email,
          Series: administrator.Series,
          Number: administrator.Number,
          IssuedBy: administrator.IssuedBy,
          IssueDate: administrator.IssueDate,
          DepartmentCode: administrator.DepartmentCode,
        },
      } as OCCV2Author.SetAdministratorBodyIndividual;

    case OCCV2Author.PersonType.LegalEntity:
      return {
        OccId,
        TypeId: administrator.Type,
        Object: {
          Name: administrator.Name,
          Phone: administrator.Phone?.toString(),
          Location: administrator.Location,
          MailingAddress: administrator.MailingAddress,
          Site: administrator.Site,
          LegalForm: administrator.EntityLegalForm,
        },
      } as OCCV2Author.SetAdministratorBodyLegalEntity;

    case OCCV2Author.PersonType.ManagementCompany:
      return {
        OccId,
        TypeId: administrator.Type,
        Object: {
          Name: administrator.Name,
          Phone: administrator.Phone?.toString(),
          FoundationAgreementDetails: administrator.FoundationAgreementDetails,
          LegalForm: administrator.UkLegalForm,
          OGRN: administrator.UkOGRN,
        },
      } as OCCV2Author.SetAdministratorBodyManagementCompany;
  }
}

export function getAuthorBody(
  author: OCCV2Author.Person | null,
  OccId?: OCCV2Order.Base["Id"]
): OCCV2Author.SetAuthorBody | undefined {
  switch (author?.Type) {
    case OCCV2Author.PersonType.Individual:
      return {
        OccId,
        TypeId: author.Type,
        Object: {
          Name: author.Name,
          Phone: author.Phone ? String((author as OCCV2Author.Individual).Phone) : "",
          Surname: author.Surname,
          Patronymic: author.Patronymic,
          RoomAddress: author.RoomAddress,
        },
      } as OCCV2Author.SetAuthorBodyIndividual;

    case OCCV2Author.PersonType.LegalEntity:
      return {
        OccId,
        TypeId: author.Type,
        Object: {
          Name: author.Name,
          Phone: author.Phone?.toString(),
          Location: author.Location,
          EntityLegalForm: author.EntityLegalForm,
          EntityOGRN: author.EntityOGRN,
        },
      } as OCCV2Author.SetAuthorBodyLegalEntity;

    case OCCV2Author.PersonType.ManagementCompany:
      return {
        OccId,
        TypeId: author.Type,
        Object: {
          Name: author.Name,
          Phone: author.Phone?.toString(),
          FoundationAgreementDetails: author.FoundationAgreementDetails,
          UkLegalForm: author.UkLegalForm,
          UkOGRN: author.UkOGRN,
        },
      } as OCCV2Author.SetAuthorBodyManagementCompany;
  }
}

export function getAuthor(
  body: OCCV2Author.SetAuthorBody,
  current: OCCV2Author.Person | null,
  UkId: Profile.CompanyData["Id"]
): OCCV2Author.Person {
  let author: OCCV2Author.Base = {
    Type: body.TypeId,
    Name: body.Object.Name,
    Phone: body.Object.Phone ? parseInt(body.Object.Phone) : null,
  };

  if (body.TypeId === OCCV2Author.PersonType.ManagementCompany) {
    author = {
      ...author,
      UkId,
      LegalForm: body.Object.UkLegalForm,
      UkLegalForm: body.Object.UkLegalForm,
      OGRN: body.Object.UkOGRN,
      UkOGRN: body.Object.UkOGRN,
      FoundationAgreementDetails: body.Object.FoundationAgreementDetails,
    } as OCCV2Author.ManagementCompany;
  } else if (body.TypeId === OCCV2Author.PersonType.Individual) {
    author = {
      ...author,
      Surname: body.Object.Surname,
      Patronymic: body.Object.Patronymic,
      RoomAddress: body.Object.RoomAddress,
      OwnershipDocumentDetails:
        current?.Type === OCCV2Author.PersonType.Individual ? current.OwnershipDocumentDetails : null,
      PermanentResidencePlace:
        current?.Type === OCCV2Author.PersonType.Individual ? current.PermanentResidencePlace : null,
      Email: current?.Type === OCCV2Author.PersonType.Individual ? current.Email : null,
      Series: current?.Type === OCCV2Author.PersonType.Individual ? current.Series : null,
      Number: current?.Type === OCCV2Author.PersonType.Individual ? current.Number : null,
      IssuedBy: current?.Type === OCCV2Author.PersonType.Individual ? current.IssuedBy : null,
      IssueDate: current?.Type === OCCV2Author.PersonType.Individual ? current.IssueDate : null,
      DepartmentCode: current?.Type === OCCV2Author.PersonType.Individual ? current.DepartmentCode : null,
    } as OCCV2Author.Individual;
  } else if (body.TypeId === OCCV2Author.PersonType.LegalEntity) {
    author = {
      ...author,
      LegalForm: body.Object.EntityLegalForm,
      EntityLegalForm: body.Object.EntityLegalForm,
      OGRN: body.Object.EntityOGRN,
      EntityOGRN: body.Object.EntityOGRN,
      FoundationAgreementDetails:
        current?.Type === OCCV2Author.PersonType.LegalEntity ? current.FoundationAgreementDetails : null,
      Location: current?.Type === OCCV2Author.PersonType.LegalEntity ? current.Location : null,
      MailingAddress: current?.Type === OCCV2Author.PersonType.LegalEntity ? current.MailingAddress : null,
      Site: current?.Type === OCCV2Author.PersonType.LegalEntity ? current.Site : null,
    } as OCCV2Author.LegalEntity;
  }

  return author as OCCV2Author.Person;
}

export function getAdministrator(
  body: OCCV2Author.SetAdministratorBody,
  current: OCCV2Author.Person | null,
  UkId: Profile.CompanyData["Id"]
): OCCV2Author.Person {
  let administrator: OCCV2Author.Base = {
    Type: body.TypeId,
    Name: body.Object.Name,
    Phone: body.Object.Phone ? parseInt(body.Object.Phone) : null,
  };

  if (body.TypeId === OCCV2Author.PersonType.ManagementCompany) {
    administrator = {
      ...administrator,
      UkId,
      LegalForm: body.Object.LegalForm,
      UkLegalForm: body.Object.LegalForm,
      OGRN: body.Object.OGRN,
      UkOGRN: body.Object.OGRN,
      FoundationAgreementDetails: body.Object.FoundationAgreementDetails,
    } as OCCV2Author.ManagementCompany;
  } else if (body.TypeId === OCCV2Author.PersonType.Individual) {
    administrator = {
      ...administrator,
      Surname: body.Object.Surname,
      Patronymic: body.Object.Patronymic,
      RoomAddress: current?.Type === OCCV2Author.PersonType.Individual ? current.RoomAddress : null,
      OwnershipDocumentDetails:
        current?.Type === OCCV2Author.PersonType.Individual ? current.OwnershipDocumentDetails : null,
      PermanentResidencePlace: body.Object.PermanentResidencePlace,
      Email: body.Object.Email,
      Series: body.Object.Series,
      Number: body.Object.Number,
      IssuedBy: body.Object.IssuedBy,
      IssueDate: body.Object.IssueDate,
      DepartmentCode: body.Object.DepartmentCode,
    } as OCCV2Author.Individual;
  } else if (body.TypeId === OCCV2Author.PersonType.LegalEntity) {
    administrator = {
      ...administrator,
      LegalForm: body.Object.LegalForm,
      EntityLegalForm: body.Object.LegalForm,
      OGRN: current?.Type === OCCV2Author.PersonType.LegalEntity ? current.OGRN : null,
      EntityOGRN: current?.Type === OCCV2Author.PersonType.LegalEntity ? current.EntityOGRN : null,
      FoundationAgreementDetails:
        current?.Type === OCCV2Author.PersonType.LegalEntity ? current.FoundationAgreementDetails : null,
      Location: body.Object.Location,
      MailingAddress: body.Object.MailingAddress,
      Site: body.Object.Site,
    } as OCCV2Author.LegalEntity;
  }

  return administrator as OCCV2Author.Person;
}
