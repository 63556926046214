import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate, useParams } from "react-router";
import { paths } from "@/consts/routes";
import { OccInitiatives } from "@/types";
import { RootState, useAppDispatch } from "@/app/store";
import { OccInitiativeActions } from "@/app/store/slices/occInitiatives";
import { showSnackbar, SnackbarType } from "@/app/store/slices/snackbar";
import PageHeader from "@/components/PageHeader";
import Tabs from "@/components/V2/Tabs";

const UKOccInitiativeItemLayout: React.FC = () => {
  const urlParams = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const initiative = useSelector((state: RootState) => state.occInitiatives.selected.details);
  const tabs = [
    {
      text: "Инициатива",
      link: paths
        .uk()
        .fullPath()
        .occInitiatives.item.details(initiative?.Id ?? ""),
    },
    {
      text: "Вопросы",
      link: paths
        .uk()
        .fullPath()
        .occInitiatives.item.questions(initiative?.Id ?? ""),
    },
    {
      text: "Собственники",
      link: paths
        .uk()
        .fullPath()
        .occInitiatives.item.owners(initiative?.Id ?? ""),
    },
  ];

  const initiativeId: OccInitiatives.ListItem["Id"] | null = useMemo(() => {
    return urlParams.initiativeId ? urlParams.initiativeId : null;
  }, [urlParams.initiativeId]);

  function handleBackClick() {
    navigate(paths.uk().fullPath().occInitiatives.subroot);
  }

  async function fetch() {
    if (!initiativeId) {
      throw new Error("");
    }

    try {
      await dispatch(OccInitiativeActions.getInitiativeDetails({ initiativeId })).unwrap();
    } catch (error: any) {
      dispatch(
        showSnackbar({
          key: "occ-initiative-error",
          title: "Что-то пошло не так.",
          body: error?.Data?.Message ?? "Попробуйте позже",

          type: SnackbarType.ERROR,
        })
      );
    }
  }

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageHeader
        title={<>Инициатива {initiative && <span className="color--text-secondary">от {initiative?.SentAt}</span>}</>}
        defaultLink={paths.uk().fullPath().occInitiatives.subroot}
        onLinkClick={handleBackClick}
      />

      {initiative && (
        <>
          <Tabs links={tabs} />

          <div>
            <Outlet />
          </div>
        </>
      )}
    </>
  );
};

export default UKOccInitiativeItemLayout;
