import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { paths } from "@/consts/routes";
import { RootState, useAppDispatch } from "@/app/store";
import { togggleGisZhkhAccessPeriodEndedMessage } from "@/app/store/slices/snt/myOcc";
import SystemMessage from "@/features/SystemMessage";
import BaseButton from "../base-button";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Content from "./Content";
import styles from "./styles.module.scss";

interface ILayoutProps {
  children: JSX.Element | JSX.Element[];
}

const Layout: React.FC<ILayoutProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isGisZhkhEnabled = useSelector((state: RootState) => state.user.isGisZhkhEnabled);
  const isAccessPeriodEndedMessageShown = useSelector(
    (state: RootState) => state.sntMyOcc.gisZhkh.isAccessPeriodEndedMessageShown
  );

  function hideAccessPeriodEndedMessage() {
    dispatch(togggleGisZhkhAccessPeriodEndedMessage(false));
  }

  function navigateToGisZhkh() {
    navigate(paths.uk().fullPath().giszhkh);
    hideAccessPeriodEndedMessage();
  }

  return (
    <div className={styles.layout}>
      <Header />
      <div className={styles.wrapper}>
        <aside>
          <Sidebar />
        </aside>
        <div>
          <Content>
            <>
              {isGisZhkhEnabled && (
                <SystemMessage
                  name="gis-zhkh-access-ended"
                  date={new Date().formatDateTime()}
                  isOpen={isAccessPeriodEndedMessageShown && pathname !== paths.uk().fullPath().giszhkh}
                  onClose={hideAccessPeriodEndedMessage}
                >
                  <div className="flex-col" style={{ gap: "4px" }}>
                    <h4 className="headline-h4 sf-text-bold color--text-primary">
                      Завершился период предоставления доступа к ГИС ЖКХ
                    </h4>
                    <p className="text--primary sf-text-regular color--text-primary">
                      Если вы хотите использовать сервис регулярно, рекомендуется либо оставить доступ активным, либо
                      каждый раз подтверждать разрешение на интеграцию с системой
                    </p>
                    <div style={{ marginTop: "4px" }}>
                      <BaseButton onClick={navigateToGisZhkh}>Предоставить права доступа</BaseButton>
                    </div>
                  </div>
                </SystemMessage>
              )}
              {children}
            </>
          </Content>
        </div>
      </div>
    </div>
  );
};
export default Layout;
