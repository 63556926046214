import { useSelector } from "react-redux";
import { Profile } from "@/types";
import { RootState } from "@/app/store";
import InstitutionMunicipalityMenu from "./InstitutionMunicipality";
import InstitutionAgricultureMinistryMenu from "./InstitutionAgricultureMinistry";
import CompanyUkMenu from "./CompanyUk";
import CompanySntMenu from "./CompanySnt";

const MenuByProfile: React.FC = () => {
  const profile = useSelector((state: RootState) => state.user.profile);

  if (
    profile?.role === Profile.UserRole.Institution &&
    profile.institution_type === Profile.InstitutionType.Municipality
  ) {
    return <InstitutionMunicipalityMenu />;
  }

  if (
    profile?.role === Profile.UserRole.Institution &&
    profile.institution_type === Profile.InstitutionType.AgricultureMinistry
  ) {
    return <InstitutionAgricultureMinistryMenu />;
  }

  if (profile?.role === Profile.UserRole.Company && profile.company_type === Profile.CompanyType.UK) {
    return <CompanyUkMenu />;
  }

  if (profile?.role === Profile.UserRole.Company && profile.company_type === Profile.CompanyType.SNT) {
    return <CompanySntMenu />;
  }

  return null;
};

export default MenuByProfile;
