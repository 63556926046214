import { paths } from "@/consts/routes";
import { DigitalGardenIcon, MapIcon } from "@/consts/icons-v2";
import MenuItem from "../../MenuItem";

const InstitutionAgricultureMinistryMenu: React.FC = () => {
  return (
    <>
      <MenuItem
        path={paths.institution().fullPath().sntsList}
        text="Садоводства РТ"
        icon={DigitalGardenIcon("var(--lkl-c--icons-primary)")}
      />
      <MenuItem path={paths.institution().map} text="Карта РТ" icon={MapIcon("var(--lkl-c--icons-primary)")} />
    </>
  );
};

export default InstitutionAgricultureMinistryMenu;
