import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { paths } from "@/consts/routes";
import { OccInitiatives } from "@/types";
import { DictionariesService } from "@/services/v2";
import { QuestionTooltip16Icon } from "@/consts/icons-v2";
import { getDraftStatus } from "@/app/store/slices/dictionary";
import { showSnackbar, SnackbarType } from "@/app/store/slices/snackbar";
import { OccInitiativeActions } from "@/app/store/slices/occInitiatives";
import { RootState, useAppDispatch } from "@/app/store";
import HeadlineAlertV2 from "@/components/V2/HeadlineAlertV2";
import BaseTooltip from "@/components/base-tooltip";
import BaseButton from "@/components/base-button";
import UploadFileModal from "@/components/upload-file-modal";
import InitiativeStatus from "@/components/OCC/InitiativeStatus";
import BaseFileItem from "@/components/base-file-item";
import styles from "./styles.module.scss";

const tooltip =
  "Согласно пункту 6 статьи 45 Жилищного кодекса Российской Федерации, собственники, владеющие не менее чем 10% от общей площади всех помещений в многоквартирном доме, имеют право обратиться в письменной форме в управляющую организацию для организации проведения общего собрания собственников помещений в многоквартирном доме. В обращении о проведении общего собрания собственников помещений в многоквартирном доме должны быть четко сформулированы вопросы, подлежащие включению в повестку дня собрания";

const UKOccInitiativeItemDetails: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const initiative = useSelector((state: RootState) => state.occInitiatives.selected.details);
  const draftStatus = useSelector(getDraftStatus);

  function openDocument(file: OccInitiatives.FullDetails["AnswerFile"]) {
    if (!file) {
      return;
    }

    window.open(file?.URL);
  }

  function goToOcc() {
    if (!initiative?.OccId) {
      createOcc();
      return;
    }

    if (initiative.Status.Occ?.Id === draftStatus?.id) {
      navigate(paths.uk().fullPath().createOCC.item.create(initiative.OccId), {
        state: { returnPath: location.pathname },
      });
    } else {
      navigate(paths.myOccById(initiative?.OccId).index);
    }
  }

  async function sendDecisionInitiative(accept: boolean, file: File | null) {
    if (!file || !initiative) {
      return;
    }

    try {
      const { data } = await DictionariesService.saveDocument(file);
      await dispatch(OccInitiativeActions.acceptInitiative({ Accepted: accept, AnswerFileId: data.Data.Id })).unwrap();
      if (accept) {
        const occId = await dispatch(OccInitiativeActions.createOcc(initiative.Id)).unwrap();
        navigate(paths.uk().fullPath().createOCC.item.create(occId), {
          state: { returnPath: location.pathname },
        });
      }
      await dispatch(OccInitiativeActions.getInitiativeDetails({ initiativeId: initiative.Id, force: true })).unwrap();
    } catch (error: any) {
      dispatch(
        showSnackbar({
          key: "accept-occ-initiative-error",
          title: "Что-то пошло не так.",
          body: error?.Data?.Message ?? "Попробуйте позже",

          type: SnackbarType.ERROR,
        })
      );
    }
  }

  async function createOcc() {
    if (!initiative) {
      return;
    }

    try {
      await dispatch(OccInitiativeActions.createOcc(initiative.Id)).unwrap();
      await dispatch(OccInitiativeActions.getInitiativeDetails({ initiativeId: initiative.Id, force: true })).unwrap();
    } catch (error: any) {
      dispatch(
        showSnackbar({
          key: "accept-occ-initiative-error",
          title: "Что-то пошло не так.",
          body: error?.Data?.Message ?? "Попробуйте позже",

          type: SnackbarType.ERROR,
        })
      );
    }
  }

  return (
    <div className={styles["p-occ-initiative-details"]}>
      <div className={styles["p-occ-initiative-details__headline"]}>
        <HeadlineAlertV2
          name="occ-initiative-item"
          title="Обратите внимание"
          verticalMargin="0"
          background="linear-gradient(92.62deg, #1E1F24 0%, #5A2043 100%)"
        >
          <p className="text--primary">
            В случае, если ответ на обращение не поступил в течение 45 дней, собственники вправе обратиться в
            Государственную Жилищную Инспекцию
          </p>
        </HeadlineAlertV2>
      </div>

      <div className={styles["p-occ-initiative-details__card"]}>
        <h3 className="headline-h3 sf-bold color--text-primary">
          Собственники инициировали проведение общего собрания
        </h3>

        <div>
          <div className={styles["p-occ-initiative-details__table"]}>
            <div className="text--primary color--text-primary">
              <span className="sf-text-regular">Дата отправки инициативы:</span>
              <span className="sf-text-semibold">{initiative?.SentAt}</span>
            </div>
            <div className="text--primary color--text-primary">
              <span className="sf-text-regular">Адрес:</span>
              <span className="sf-text-semibold">{initiative?.Address}</span>
            </div>
            <div className="text--primary color--text-primary">
              <span className="sf-text-regular">Статус:</span>
              <span className="sf-text-semibold">
                {initiative && (
                  <InitiativeStatus
                    className="table--middle sf-text-medium"
                    status={initiative?.Status?.Key}
                    model={initiative?.Status}
                  />
                )}
              </span>
            </div>
            {initiative?.Status?.Key &&
              initiative?.Status.DaysText &&
              [OccInitiatives.Status.New, OccInitiatives.Status.WaitOccCreate].includes(initiative.Status.Key) && (
                <div className="text--primary color--text-primary">
                  <span className="sf-text-regular">Будет просрочено через:</span>
                  <span className="sf-text-semibold">{initiative?.Status.DaysText}</span>
                </div>
              )}
          </div>
        </div>

        <div className={styles["p-occ-initiative-details__buttons"]}>
          {initiative?.Status.Key === OccInitiatives.Status.Rejected ? (
            <>
              <h4 className="color--text-primary headline-h4 sf-text-bold">Вы отклонили инициативу собственников</h4>
              <div>
                {initiative.AnswerFile && (
                  <BaseFileItem onSelect={() => openDocument(initiative.AnswerFile)} file={initiative.AnswerFile} />
                )}
              </div>
            </>
          ) : initiative?.Status.Key === OccInitiatives.Status.New ? (
            <>
              <h4 className="color--text-primary headline-h4 sf-text-bold">Ответьте на обращение</h4>
              <div>
                <div className={styles["p-occ-initiative-details__buttons_decline"]}>
                  <UploadFileModal
                    title="Прикрепите ответ на обращение"
                    subtitle="Укажите в ответе причину отклонения инициативы"
                    id="decline-initiative"
                    accept="image/*, .pdf, doc, .docx, .txt,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    submitBtnText="Прикрепить"
                    formatsText="не более 1 документа до 10 MB, PDF, DOC, DOCX, XLS, XLSX, TXT, JPG, JPEG, PNG"
                    onChangeFile={(file) => sendDecisionInitiative(false, file)}
                  >
                    <div className="flex middle">
                      <BaseButton appearance="negative">Отклонить</BaseButton>
                    </div>
                  </UploadFileModal>
                </div>
                <div className={styles["p-occ-initiative-details__buttons_accept"]}>
                  <UploadFileModal
                    title="Вы принимаете инициативу, прикрепите ответ и создайте общее собрание собственников"
                    subtitle="Для удобства, мы предзаполним повестку из инициативы."
                    id="accept-initiative"
                    accept="image/*, .pdf, doc, .docx, .txt,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    submitBtnText="Прикрепить и создать ОСС"
                    formatsText="не более 1 документа до 10 MB, PDF, DOC, DOCX, XLS, XLSX, TXT, JPG, JPEG, PNG"
                    onChangeFile={(file) => sendDecisionInitiative(true, file)}
                  >
                    <div className="flex middle">
                      <BaseButton className={styles["p-occ-initiative-details__shine-btn"]} appearance="accent">
                        Принять
                      </BaseButton>
                    </div>
                  </UploadFileModal>
                </div>
              </div>
            </>
          ) : initiative?.Status.Key === OccInitiatives.Status.Expired ? (
            <>
              <h4 className="color--text-primary headline-h4 sf-text-bold">Ваш ответ на обращение</h4>
              <p className="color--text-secondary text--primary sf-text-regular">Просрочен срок ответа на обращение</p>
            </>
          ) : (
            <>
              {initiative?.AnswerFile && (
                <>
                  {initiative.OccId && (
                    <h4 className="color--text-primary headline-h4 sf-text-bold">
                      Вы ответили на обращение. Для удобства мы создали для вас черновик собрания и предзаполнили
                      повестку вопросами из обращения
                    </h4>
                  )}

                  <BaseFileItem onSelect={() => openDocument(initiative.AnswerFile)} file={initiative.AnswerFile} />
                </>
              )}
              {initiative?.GoToOccEnable ? (
                <>
                  {initiative.OccId ? (
                    <BaseButton appearance="accent" onClick={goToOcc}>
                      Перейти к собранию
                    </BaseButton>
                  ) : (
                    <BaseButton appearance="accent" onClick={createOcc}>
                      Создать собрание
                    </BaseButton>
                  )}
                </>
              ) : initiative?.CreateOccEnable ? (
                <BaseButton appearance="accent" onClick={createOcc}>
                  Создать собрание
                </BaseButton>
              ) : null}
            </>
          )}
        </div>

        <div>
          <div className={styles["p-occ-initiative-details__stats"]}>
            <div className={styles["p-occ-initiative-details__stats_item"]}>
              <p className="text--primary color--text-primary sf-text-semibold">Общая площадь помещений</p>
              <span className="headline-h2 sf-bold color--text-primary">{initiative?.TotalArea?.formatRU()} м2</span>
              <div className="flex middle">
                <div
                  className={styles["p-occ-initiative-details__stats_percentage"]}
                  style={{ backgroundColor: "var(--badge-neutral)" }}
                >
                  <span className="text--primary sf-text-semibold color--text-white">100%</span>
                </div>
              </div>
            </div>
            <div className={styles["p-occ-initiative-details__stats_item"]}>
              <p className="text--primary color--text-primary sf-text-semibold">Площадь проголосовавших</p>
              <span className="headline-h2 sf-bold color--text-primary">{initiative?.VotedArea?.formatRU()} м2</span>
              <div className="flex middle" style={{ gap: "8px" }}>
                <div
                  className={styles["p-occ-initiative-details__stats_percentage"]}
                  style={{ backgroundColor: "var(--badge-positive)" }}
                >
                  <span className="text--primary sf-text-semibold color--text-white">
                    {initiative?.VotedPercentage}%
                  </span>
                </div>
                <BaseTooltip title={tooltip}>{QuestionTooltip16Icon()}</BaseTooltip>
              </div>
            </div>
            <div className={styles["p-occ-initiative-details__stats_item"]}>
              <p className="text--primary color--text-primary sf-text-semibold">Кворум инициативы</p>
              <span className="headline-h2 sf-bold">
                {initiative?.QuorumCompleted ? (
                  <span style={{ color: "var(--badge-positive)" }}>Да</span>
                ) : (
                  <span style={{ color: "var(--badge-negative)" }}>Нет</span>
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UKOccInitiativeItemDetails;
