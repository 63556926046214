import React from "react";
import { ChevronRight16Icon } from "@/consts/icons-v2";
import { Accordion, CustomCollapsed, Expanded } from "@/components/base-accordion";
import styles from "../MenuItem/styles.module.scss";

interface INestedMenuItemProps {
  text: string;
  icon?: JSX.Element;
  children?: JSX.Element | JSX.Element[] | React.ReactNode;
}

const NestedMenuItem: React.FC<INestedMenuItemProps> = ({ text, icon, children }) => {
  return (
    <Accordion className={styles["b-menu-item"]}>
      <CustomCollapsed id={text}>
        <div className={styles["b-menu-item__btn"]}>
          <div>
            <div className={styles["b-menu-item__btn_icon"]}>{icon}</div>
            <span className="lkl-font--sf-text--medium lkl-tpgrhy--text--primary lkl-color--text-primary">{text}</span>
          </div>
          <span className={styles["b-menu-item__btn_chevron"]} role="spinbutton">
            {ChevronRight16Icon("var(--lkl-c--icons-primary)")}
          </span>
        </div>
      </CustomCollapsed>

      <Expanded id={text}>
        <div className={styles["b-menu-item__children"]}>{children}</div>
      </Expanded>
    </Accordion>
  );
};

export default NestedMenuItem;
