import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { PageHeader, BaseLoader, BaseQuerySearch, useInfiniteScroll } from "lokolo-web-ui-library";
import { Institution, Pagination } from "@/types";
import { RootState, useAppDispatch } from "@/app/store";
import { InstitutionActions } from "@/app/store/slices/institution";
import useEmptyBlockHeight from "@/hooks/useEmptyBlockHeight";
import { paths } from "@/consts/routes";
import { ChevronRight16Icon } from "@/consts/icons-v2";
import { BaseDropdown, BaseDropdownMenuItem } from "lokolo-web-ui-library/dist/components/Base/Dropdown";
import AccordionFilters, { AccordionFiltersRowItem } from "@/components/AccordionFilters";
import {
  BaseTable,
  BaseTableHead,
  BaseTableBody,
  BaseTableRow,
  BaseTableHeadCell,
  BaseTableCell,
  BaseContainer,
} from "@/components/base-table";
import HeadlineAlertV2 from "@/components/V2/HeadlineAlertV2";

const InstitutionMunicipalityCompaniesList: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { isLoading, filters, list, pagination, search } = useSelector(
    (state: RootState) => state.institution.companies
  );

  const [filtersLocal, setFiltersLocal] = useState<typeof filters>(filters);
  const { emptyBlockHeight, emptyBlockRef } = useEmptyBlockHeight();
  const { measureRef, hasMore } = useInfiniteScroll<Pagination | null>(
    pagination,
    loadMore,
    (meta) => meta?.Index === meta?.Count
  );

  const isFiltered: boolean = useMemo(() => {
    return filters.LokoloUserExists != null || filters.Type != null || search.length > 0;
  }, [filters, search]);

  async function loadMore() {
    try {
      const meta = await dispatch(InstitutionActions.Companies.loadMore()).unwrap();
      return meta;
    } catch (error: any) {
      return null;
    }
  }

  function handleQueryChange(value: string) {
    if (value !== search) {
      dispatch(InstitutionActions.Companies.setSearchQuery(value));
    }
  }

  async function syncFilters() {
    return await setFiltersLocal(filters);
  }

  async function applyFilters() {
    await dispatch(InstitutionActions.Companies.applyFilters(filtersLocal)).unwrap();
  }

  async function resetFilters() {
    setFiltersLocal({
      LokoloUserExists: null,
      Type: null,
    });
    await dispatch(InstitutionActions.Companies.resetFilters()).unwrap();
  }

  function handleSelect(id: Institution.Company["Id"]) {
    navigate(paths.municipality().fullPath().companies.item(id.toString()));
  }

  async function fetch() {
    try {
      await dispatch(InstitutionActions.Companies.fetchList()).unwrap();
    } catch (error: any) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (list.length > 0) {
      return;
    }

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ paddingBottom: "24px" }}>
      <PageHeader title="Организации">
        <div style={{ width: "361px" }}>
          <BaseQuerySearch value={search} onChange={handleQueryChange} placeholder="Поиск по наименованию" />
        </div>
      </PageHeader>

      <div className="horizontal-offset">
        <div>
          <HeadlineAlertV2
            name="institution-companies"
            title="О разделе"
            verticalMargin="16px 0"
            background="linear-gradient(92.62deg, #1E1F24 0%, #4259AF 100%)"
          >
            <p className="lkl-tpgrhy--text--primary">
              Позволяет легко находить организации в вашем районе, изучать их подробную информацию и просматривать
              статистику по каждой из них
            </p>
          </HeadlineAlertV2>
        </div>

        <AccordionFilters offset="12px 0" onSync={syncFilters} onReset={resetFilters} onApply={applyFilters}>
          <AccordionFiltersRowItem title="Есть ли учетная запись">
            <BaseDropdown
              value={filtersLocal.LokoloUserExists}
              display={filtersLocal.LokoloUserExists === null ? "Все" : filtersLocal.LokoloUserExists ? "Есть" : "Нет"}
              variant="formfield"
              isSelectable={true}
              onSelect={(value) => {
                setFiltersLocal((prevState) => ({ ...prevState, LokoloUserExists: value }));
              }}
            >
              <BaseDropdownMenuItem value={null}>
                <span className="sf-text-regular">Все</span>
              </BaseDropdownMenuItem>
              <BaseDropdownMenuItem value={true}>
                <span className="sf-text-regular">Есть</span>
              </BaseDropdownMenuItem>
              <BaseDropdownMenuItem value={false}>
                <span className="sf-text-regular">Нет</span>
              </BaseDropdownMenuItem>
            </BaseDropdown>
          </AccordionFiltersRowItem>

          <AccordionFiltersRowItem title="Тип">
            <BaseDropdown
              value={filtersLocal.Type}
              display={
                filtersLocal.Type === null
                  ? "Все"
                  : Institution.companyTypes.find((it) => it.value === filtersLocal.Type)?.text
              }
              variant="formfield"
              isSelectable={true}
              onSelect={(value) => {
                setFiltersLocal((prevState) => ({ ...prevState, Type: value }));
              }}
            >
              <BaseDropdownMenuItem value={null}>
                <span className="sf-text-regular">Все</span>
              </BaseDropdownMenuItem>
              {Institution.companyTypes.map((type) => (
                <BaseDropdownMenuItem key={type.value} value={type.value}>
                  <span className="sf-text-regular">{type.text}</span>
                </BaseDropdownMenuItem>
              ))}
            </BaseDropdown>
          </AccordionFiltersRowItem>
        </AccordionFilters>

        <BaseContainer defaultHorizontalOffset defaultTopOffset>
          <BaseTable>
            <BaseTableHead nowrap>
              <BaseTableRow>
                <BaseTableHeadCell>Наименование</BaseTableHeadCell>
                <BaseTableHeadCell>Тип</BaseTableHeadCell>
                <BaseTableHeadCell>Учетная запись Локоло</BaseTableHeadCell>
                <BaseTableHeadCell></BaseTableHeadCell>
              </BaseTableRow>
            </BaseTableHead>

            {isLoading ? (
              <BaseTableBody empty refProp={emptyBlockRef}>
                <BaseTableRow>
                  <BaseTableCell colspan={7} style={{ height: `${emptyBlockHeight}px` }}>
                    <div className="flex center">
                      <BaseLoader size={36} />
                    </div>
                  </BaseTableCell>
                </BaseTableRow>
              </BaseTableBody>
            ) : list.length ? (
              <>
                <BaseTableBody defaultDivider>
                  {list.map((company) => (
                    <BaseTableRow
                      key={company.Id}
                      onClick={() => handleSelect(company.Id)}
                      style={{ verticalAlign: "baseline" }}
                    >
                      <BaseTableHeadCell>
                        <span className="sf-text-semibold">{company.Name}</span>
                      </BaseTableHeadCell>
                      <BaseTableCell nowrap>
                        <span className="sf-text-regular">
                          {Institution.companyTypes.find((type) => type.value === company.Type)?.text ?? ""}
                        </span>
                      </BaseTableCell>
                      <BaseTableCell>
                        <span className="sf-text-regular">{company.LokoloUserExists ? "Есть" : "Нет"}</span>
                      </BaseTableCell>
                      <BaseTableCell>
                        <span>{ChevronRight16Icon("var(--icons-primary)")}</span>
                      </BaseTableCell>
                    </BaseTableRow>
                  ))}
                </BaseTableBody>
                {hasMore && (
                  <tbody ref={measureRef}>
                    <tr>
                      <td colSpan={7}>
                        <div className="flex center" style={{ minHeight: "56px" }}>
                          <BaseLoader size={36} />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )}
              </>
            ) : (
              <BaseTableBody empty refProp={emptyBlockRef}>
                <BaseTableRow>
                  <BaseTableCell colspan={7} style={{ height: `${emptyBlockHeight}px` }}>
                    <div className="center" style={{ display: "flex", flexDirection: "column" }}>
                      {isFiltered ? (
                        <>
                          <h4 className="lkl-tpgrhy--headline--h4 lkl-font--sf-text--bold lkl-color--text-primary">
                            Ничего не нашлось
                          </h4>
                          <p className="lkl-tpgrhy--text--primary lkl-font--sf-text--regular lkl-color--text-primary">
                            Возможно, стоит изменить параметры поиска или сбросить фильтр
                          </p>
                        </>
                      ) : (
                        <>
                          <p className="lkl-tpgrhy--text--primary lkl-font--sf-text--regular lkl-color--text-secondary">
                            Организации не загрузились. Попробуйте войти позднее, мы уже работаем над проблемой
                          </p>
                        </>
                      )}
                    </div>
                  </BaseTableCell>
                </BaseTableRow>
              </BaseTableBody>
            )}
          </BaseTable>
        </BaseContainer>
      </div>
    </div>
  );
};

export default InstitutionMunicipalityCompaniesList;
