import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { paths } from "@/consts/routes";
import { Dictionary, OCCV2Author, OCCV2AuthorForms } from "@/types";
import { arrowRightWhiteIcon } from "@/consts/icons";
import { RootState, useAppDispatch } from "@/app/store";
import { showSnackbar, SnackbarType } from "@/app/store/slices/snackbar";
import { selectStatusById } from "@/app/store/slices/dictionary";
import { setOccId, fetchOccAuthor, setOccAuthor, saveLocalOccChanges } from "@/app/store/slices/createOcc";
import CreateOccItemPageSubheader from "@/features/CreateOccNew/OccItem/PageSubheader";
import { BaseDropdown, BaseDropdownMenuItem } from "@/components/base-dropdown";
import BaseTextarea from "@/components/base-textarea";
import BaseButton from "@/components/base-button";
import BaseInput from "@/components/base-input";
import BaseLabel from "@/components/BaseLabel";
import PhoneInput from "@/components/base-input/phoneInput";
import SegmentedСontrols from "@/components/SegmentedСontrols";
import BottomBar from "@/features/CreateOccNew/OccItem/BottomBar";
import styles from "./styles.module.scss";

const UKCreateOCCSetAuthorStep: React.FC = () => {
  const urlParams = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { occId, order, author } = useSelector((state: RootState) => state.createOcc);
  const company = useSelector((state: RootState) => state.user.company);
  const status = useSelector(selectStatusById(order?.StatusId));

  const [form, setForm] = useState<OCCV2AuthorForms.SetAuthorForm>(new OCCV2AuthorForms.SetAuthorForm());

  const isByInitiative = useMemo(() => {
    return !!order?.InitiativeId;
  }, [order?.InitiativeId]);

  function updateFormFieldValue(key: keyof OCCV2AuthorForms.SetAuthorForm, value: unknown) {
    const field = form[key];
    if ((typeof field === "object" || typeof field === "function") && field !== null && "value" in field) {
      setForm((prevState) => ({
        ...prevState,
        [key]: { ...field, value },
      }));
    }
  }

  function isFormValid() {
    const validator = OCCV2AuthorForms.SetAuthorFormValidator.isInvalid(form);

    setForm((prevState) => ({
      ...prevState,
      Name: {
        ...prevState.Name,
        error: typeof validator === "object" ? validator.Name ?? "" : "",
      },
      Phone: {
        ...prevState.Phone,
        error: typeof validator === "object" ? validator.Phone ?? "" : "",
      },
      FoundationAgreementDetails: {
        ...prevState.FoundationAgreementDetails,
        error: typeof validator === "object" ? validator.FoundationAgreementDetails ?? "" : "",
      },
      UkLegalForm: {
        ...prevState.UkLegalForm,
        error: typeof validator === "object" ? validator.UkLegalForm ?? "" : "",
      },
      UkOGRN: {
        ...prevState.UkOGRN,
        error: typeof validator === "object" ? validator.UkOGRN ?? "" : "",
      },
    }));

    return !validator;
  }

  function handleError(payload: any) {
    if (payload.Errors) {
      Object.keys(payload.Errors).forEach((key: string) => {
        const message = Array.isArray(payload.Errors[key]) ? payload.Errors[key][0] : payload.Errors[key];
        if (typeof (form as any)[key] === "object" && (form as any)[key]?.error) {
          setForm((prevState) => ({
            ...prevState,
            [key]: { ...(form as any)[key], error: message },
          }));
        }
      });
    } else {
      setForm((prevState) => ({ ...prevState, error: payload.Message }));
      dispatch(showSnackbar({ key: "create-occ-author-error", body: payload.Message, type: SnackbarType.ERROR }));
    }
  }

  async function handleOnSubmit(event: React.FormEvent<HTMLFormElement>) {
    if (isByInitiative && occId) {
      navigate(paths.uk().fullPath().createOCC.item.administrator(occId), { state: location.state });
      return;
    }

    event.preventDefault();
    Object.keys(form).forEach((key: string) => {
      if (typeof (form as any)[key] === "object" && (form as any)[key]?.error) {
        setForm((prevState) => ({
          ...prevState,
          [key]: { ...(form as any)[key], error: "" },
        }));
      }
    });

    setForm((prevState) => ({
      ...prevState,
      error: "",
    }));

    if (!isFormValid() || !occId) {
      return;
    }

    setForm((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      const payload: OCCV2Author.SetAuthorBody = {
        TypeId: OCCV2Author.PersonType.ManagementCompany,
        OccId: occId,
        Object: {
          Name: form.Name.value,
          Phone: form.Phone.value,
          FoundationAgreementDetails: form.FoundationAgreementDetails.value,
          UkLegalForm: form.UkLegalForm.value ?? "",
          UkOGRN: form.UkOGRN.value,
        },
      };
      if (status && status.key === Dictionary.OCCStatus.OccPlanned) {
        await dispatch(saveLocalOccChanges({ author: payload })).unwrap();
      } else {
        await dispatch(setOccAuthor(payload)).unwrap();
      }
      navigate(paths.uk().fullPath().createOCC.item.administrator(occId), { state: location.state });
    } catch (error: any) {
      handleError(error.Data);
    } finally {
      setForm((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  }

  async function fetchAuthor() {
    try {
      if (!occId && urlParams?.occId) {
        dispatch(setOccId(parseInt(urlParams?.occId)));
      }

      const data = await dispatch(fetchOccAuthor()).unwrap();
      setForm(new OCCV2AuthorForms.SetAuthorForm(data ?? undefined, company ?? undefined));
    } catch (error) {
      dispatch(
        showSnackbar({
          key: "occ-fetch-author-error",
          body: "Не удалось получить данные автора",
          type: SnackbarType.ERROR,
        })
      );
    }
  }

  useEffect(() => {
    fetchAuthor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isSubmitEnabled: boolean = useMemo(
    () =>
      isByInitiative ||
      (form.UkLegalForm.value !== null &&
        form.UkOGRN.value.length > 0 &&
        form.FoundationAgreementDetails.value.length > 0 &&
        form.Phone.value.length > 0),
    [form, isByInitiative]
  );

  return (
    <div>
      <CreateOccItemPageSubheader>
        <h2 className="headline-h2 sf-bold">Инициатор</h2>
      </CreateOccItemPageSubheader>

      <div className={styles["b-set-author__header"]}>
        <h4 className="headline-h4 sf-text-medium color--text-primary">
          Инициатор - лицо, по чьей инициативе организовывается собрание.
        </h4>

        <SegmentedСontrols
          list={[
            {
              text: "Физическое лицо",
              disabled: !isByInitiative,
              isActive: isByInitiative,
            },
            {
              text: "Управляющая организация",
              disabled: isByInitiative,
              isActive: !isByInitiative,
            },
          ]}
        />
      </div>

      <form className={styles["b-set-author__form"]} onSubmit={handleOnSubmit}>
        {isByInitiative && author?.Type === OCCV2Author.PersonType.Individual ? (
          <>
            <div>
              <BaseInput
                value={[author.Surname, author.Name, author.Patronymic].filter((it) => !!it).join(" ")}
                label="Данные собственника"
                required={true}
                locked={true}
              />
            </div>

            <div>
              <BaseInput value={author.ObjectNumber ?? ""} label="№ помещения" required={true} locked={true} />
            </div>
          </>
        ) : (
          <>
            <div>
              <BaseLabel>Компания</BaseLabel>
              <h4 className="headline-h4 sf-text-bold color--text-primary">{form.Name.value}</h4>
            </div>

            <div>
              <BaseDropdown
                value={form.UkLegalForm.value ?? null}
                display={form.UkLegalForm.value ?? ""}
                isSelectable={true}
                label="Организационно - правовая форма"
                placeholder="Организационно - правовая форма"
                variant="formfield"
                required={true}
                onSelect={(value) => updateFormFieldValue("UkLegalForm", value)}
              >
                {OCCV2AuthorForms.LEGAL_FORMS.map((it, index) => (
                  <BaseDropdownMenuItem key={index} value={it}>
                    <span className="sf-text-medium">{it}</span>
                  </BaseDropdownMenuItem>
                ))}
              </BaseDropdown>
            </div>

            <div>
              <BaseInput
                value={form.UkOGRN.value}
                label="ОГРН"
                required={form.UkOGRN.isRequired}
                placeholder="Введите номер ОГРН"
                errorMessage={form.UkOGRN.error}
                maxlength={form.UkOGRN.maxlength}
                onChange={(value) => updateFormFieldValue("UkOGRN", value)}
              />
            </div>

            <div>
              <BaseTextarea
                minRows={1}
                value={form.FoundationAgreementDetails.value}
                label="Реквизиты договора основания на наличие права инициировать общее собрание собственников"
                required={form.FoundationAgreementDetails.isRequired}
                placeholder="Введите реквизиты договора основания"
                errorMessage={form.FoundationAgreementDetails.error}
                onChange={(value) => updateFormFieldValue("FoundationAgreementDetails", value)}
              />
            </div>

            <div>
              <PhoneInput
                value={form.Phone.value}
                label="Контактный телефон"
                required={form.Phone.isRequired}
                placeholder="Введите телефон"
                errorMessage={form.Phone.error}
                onChange={(value) => updateFormFieldValue("Phone", value)}
              />
            </div>
          </>
        )}
      </form>

      <BottomBar
        prevLink={paths
          .uk()
          .fullPath()
          .createOCC.item.create(occId ?? "new")}
      >
        <BaseButton
          variant="primary"
          disabled={!isSubmitEnabled}
          appearance="positive"
          endIcon={arrowRightWhiteIcon}
          isLoading={form.isLoading}
          onClick={handleOnSubmit}
        >
          Дальше
        </BaseButton>
      </BottomBar>
    </div>
  );
};

export default UKCreateOCCSetAuthorStep;
