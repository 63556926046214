import errorMessages from "@/helpers/errorMessages.json";
import { Dictionary } from "./dictionary";
import { House } from "./house";
import { CheckboxField, Form, FormFiled, FormValidator, SelectField } from "./ui";

export namespace ExternalServices {
  export namespace Service {
    export interface Item {
      Id: number;
      Title: string;
      Enable: boolean;
      Category: Category;
      Price: number;
      HasOrders: boolean;
    }

    export type FullDetails = Item & {
      Description: string;
      HouseIds: Array<House.Item["Id"]>;
    };

    export interface Category {
      Id: number;
      Title: string;
    }

    export interface CreateBody {
      CategoryId: Category["Id"];
      Title: string;
      Description: string;
      Price: number;
      Enable: boolean;
      HouseIds?: Array<House.Item["Id"]>;
    }

    export class CreateForm extends Form {
      Title: FormFiled;
      Category: SelectField<Category>;
      Description: FormFiled;
      Price: FormFiled;
      Enable: CheckboxField;
      HouseIds: Array<House.Item["Id"]>;
      HouseIdsError: string = "";

      constructor(service?: FullDetails | null, isEditing: boolean = false, isViewing: boolean = false) {
        super();
        this.Title = new FormFiled(service?.Title ?? "", true, isEditing || isViewing, 40, 2);
        this.Category = new SelectField(service?.Category ?? null, true, isEditing || isViewing);
        this.Description = new FormFiled(service?.Description ?? "", true, isViewing, 200, 2);
        this.Price = new FormFiled(service?.Price?.toString() ?? "", true, isViewing);
        this.Enable = new CheckboxField(service?.Enable ?? true, true, isViewing);
        this.HouseIds = service?.HouseIds ?? [];
      }
    }

    export class CreateFormValidator {
      static validateHouses(houses: CreateForm["HouseIds"]) {
        if (houses.length === 0) return errorMessages.houses.empty;

        return "";
      }

      public static isInvalid(form: CreateForm, isUK: boolean) {
        const errors = {
          Title: FormValidator.validateByLength(
            form.Title.value.trim(),
            form.Title.isRequired,
            form.Title.minlength ?? 2,
            form.Title.maxlength ?? 40,
            "Необходимо ввести от 2 до 40 символов"
          ),
          Category: FormValidator.getSelectFieldErrorMessage(form.Category.value, form.Category.isRequired),
          Description: FormValidator.validateByLength(
            form.Description.value.trim(),
            form.Description.isRequired,
            form.Description.minlength ?? 2,
            form.Description.maxlength ?? 200,
            "Необходимо ввести от 2 до 200 символов"
          ),
          Price: FormValidator.getFieldErrorMessage(form.Price.value.trim(), form.Price.isRequired),
          HouseIds: isUK ? this.validateHouses(form.HouseIds) : "",
        };

        if (Object.values(errors).some((it) => !!it)) return errors;

        return false;
      }

      public static serialize(form: CreateForm, isUK: boolean, SntAddressId?: number): CreateBody | null {
        if (CreateFormValidator.isInvalid(form, isUK) || !form.Category.value) {
          return null;
        }

        return {
          CategoryId: form.Category.value.Id,
          Title: form.Title.value,
          Description: form.Description.value,
          Price: parseFloat(form.Price.value),
          Enable: !!form.Enable.value,
          HouseIds: isUK ? form.HouseIds : SntAddressId ? [SntAddressId] : undefined,
        };
      }
    }
  }

  export namespace Order {
    export interface Item {
      Id: number;
      Service: {
        Title: Service.Item["Title"];
      };
      CreatedAt: number;
      Address: House.Item["Address"];
      User: {
        FullName: string;
        Phone: string;
      };
      Status: StatusModel;
    }

    export type FullDetails = Item & {
      Description: String;
      StatusHistory: StatusChange[];
    };

    export enum SearchKey {
      Id = "id",
      FullName = "full_name",
      PhoneNum = "phone_num",
    }

    export enum Status {
      New = "new",
      Canceled = "canceled",
      Delayed = "delayed",
      Restored = "restored",
      Scheduled = "scheduled",
      OnMyWay = "on_my_way",
      InWork = "in_work",
      Closed = "closed",
    }

    export type StatusModel = Dictionary.AlternativeDictionaryItem<Status>;

    export type StatusChange = StatusModel & {
      Id: number;
      Author: {
        FullName: string;
      };
      Comment?: string;
      CreatedAt: number;
    };

    export interface SetStatusBody {
      Key: Status;
      Comment?: string;
    }
  }
}
