import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { Dictionary, OCCV2Author, OCCV2AuthorForms } from "@/types";
import { paths } from "@/consts/routes";
import { arrowRightWhiteIcon } from "@/consts/icons";
import { RootState, useAppDispatch } from "@/app/store";
import { showSnackbar, SnackbarType } from "@/app/store/slices/snackbar";
import { selectStatusById } from "@/app/store/slices/dictionary";
import {
  setOccId,
  fetchOccAdministrator,
  setOccAdministrator,
  saveLocalOccChanges,
} from "@/app/store/slices/createOcc";
import CreateOccItemPageSubheader from "@/features/CreateOccNew/OccItem/PageSubheader";
import { BaseDropdown, BaseDropdownMenuItem } from "@/components/base-dropdown";
import BaseTextarea from "@/components/base-textarea";
import BaseButton from "@/components/base-button";
import BaseInput from "@/components/base-input";
import BaseLabel from "@/components/BaseLabel";
import PhoneInput from "@/components/base-input/phoneInput";
import SegmentedСontrols from "@/components/SegmentedСontrols";
import BottomBar from "@/features/CreateOccNew/OccItem/BottomBar";
import styles from "./styles.module.scss";

const UKCreateOCCSetAdministratorStep: React.FC = () => {
  const urlParams = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { occId, order } = useSelector((state: RootState) => state.createOcc);
  const company = useSelector((state: RootState) => state.user.company);
  const status = useSelector(selectStatusById(order?.StatusId));

  const [form, setForm] = useState<OCCV2AuthorForms.SetAdministratorForm>(new OCCV2AuthorForms.SetAdministratorForm());

  function updateFormFieldValue(key: keyof OCCV2AuthorForms.SetAdministratorForm, value: unknown) {
    const field = form[key];
    if ((typeof field === "object" || typeof field === "function") && field !== null && "value" in field) {
      setForm((prevState) => ({
        ...prevState,
        [key]: { ...field, value },
      }));
    }
  }

  function isFormValid() {
    const validator = OCCV2AuthorForms.SetAdministratorFormValidator.isInvalid(form);

    setForm((prevState) => ({
      ...prevState,
      Name: {
        ...prevState.Name,
        error: typeof validator === "object" ? validator.Name ?? "" : "",
      },
      Phone: {
        ...prevState.Phone,
        error: typeof validator === "object" ? validator.Phone ?? "" : "",
      },
      FoundationAgreementDetails: {
        ...prevState.FoundationAgreementDetails,
        error: typeof validator === "object" ? validator.FoundationAgreementDetails ?? "" : "",
      },
      LegalForm: {
        ...prevState.LegalForm,
        error: typeof validator === "object" ? validator.LegalForm ?? "" : "",
      },
      OGRN: {
        ...prevState.OGRN,
        error: typeof validator === "object" ? validator.OGRN ?? "" : "",
      },
    }));

    return !validator;
  }

  function handleError(payload: any) {
    if (payload.Errors) {
      Object.keys(payload.Errors).forEach((key: string) => {
        const message = Array.isArray(payload.Errors[key]) ? payload.Errors[key][0] : payload.Errors[key];
        if (typeof (form as any)[key] === "object" && (form as any)[key]?.error) {
          setForm((prevState) => ({
            ...prevState,
            [key]: { ...(form as any)[key], error: message },
          }));
        }
      });
    } else {
      setForm((prevState) => ({ ...prevState, error: payload.Message }));
      dispatch(showSnackbar({ key: "occ-set-administrator-error", body: payload.Message, type: SnackbarType.ERROR }));
    }
  }

  async function handleOnSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    Object.keys(form).forEach((key: string) => {
      if (typeof (form as any)[key] === "object" && (form as any)[key]?.error) {
        setForm((prevState) => ({
          ...prevState,
          [key]: { ...(form as any)[key], error: "" },
        }));
      }
    });
    setForm((prevState) => ({
      ...prevState,
      error: "",
    }));

    if (!isFormValid() || !occId) {
      return;
    }

    setForm((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      const payload: OCCV2Author.SetAdministratorBody = {
        TypeId: OCCV2Author.PersonType.ManagementCompany,
        OccId: occId,
        Object: {
          Name: form.Name.value,
          Phone: form.Phone.value,
          FoundationAgreementDetails: form.FoundationAgreementDetails.value,
          LegalForm: form.LegalForm.value ?? "",
          OGRN: form.OGRN.value,
        },
      };
      if (status && status.key === Dictionary.OCCStatus.OccPlanned) {
        await dispatch(saveLocalOccChanges({ administrator: payload })).unwrap();
      } else {
        await dispatch(setOccAdministrator(payload)).unwrap();
      }
      navigate(
        paths
          .uk()
          .fullPath()
          .createOCC.item.agenda(occId ?? "new"),
        { state: location.state }
      );
    } catch (error: any) {
      handleError(error.Data);
    } finally {
      setForm((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  }

  async function fetchAdministrator() {
    try {
      if (!occId && urlParams?.occId) {
        dispatch(setOccId(parseInt(urlParams?.occId)));
      }

      const data = await dispatch(fetchOccAdministrator()).unwrap();
      setForm(new OCCV2AuthorForms.SetAdministratorForm(data ?? undefined, company ?? undefined));
    } catch (error) {
      console.warn(error);
    }
  }

  useEffect(() => {
    fetchAdministrator();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isSubmitEnabled: boolean = useMemo(
    () =>
      form.LegalForm.value !== null &&
      form.OGRN.value.length > 0 &&
      form.FoundationAgreementDetails.value.length > 0 &&
      form.Phone.value.length > 0,
    [form]
  );

  return (
    <div>
      <CreateOccItemPageSubheader>
        <h2 className="headline-h2 sf-bold">Администратор</h2>
      </CreateOccItemPageSubheader>

      <div className={styles["b-set-administrator__header"]}>
        <h4 className="headline-h4 sf-text-medium color--text-primary">
          Администратор собрания - лицо, ответственное за организацию собрания.
        </h4>

        <SegmentedСontrols
          list={[
            {
              text: "Юридическое лицо",
              disabled: true,
            },
            {
              text: "Физическое лицо",
              disabled: true,
            },
            {
              text: "Управляющая организация",
              isActive: true,
            },
          ]}
        />
      </div>

      <form className={styles["b-set-administrator__form"]} onSubmit={handleOnSubmit}>
        <div>
          <BaseLabel>Компания</BaseLabel>
          <h4 className="headline-h4 sf-text-bold color--text-primary">{form.Name.value}</h4>
        </div>

        <div>
          <BaseDropdown
            value={form.LegalForm.value ?? null}
            display={form.LegalForm.value ?? ""}
            isSelectable={true}
            label="Организационно - правовая форма"
            placeholder="Организационно - правовая форма"
            variant="formfield"
            required={true}
            onSelect={(value) => updateFormFieldValue("LegalForm", value)}
          >
            {OCCV2AuthorForms.LEGAL_FORMS.map((it, index) => (
              <BaseDropdownMenuItem key={index} value={it}>
                <span className="sf-text-medium">{it}</span>
              </BaseDropdownMenuItem>
            ))}
          </BaseDropdown>
        </div>

        <div>
          <BaseInput
            value={form.OGRN.value}
            label="ОГРН"
            required={form.OGRN.isRequired}
            placeholder="Введите номер ОГРН"
            errorMessage={form.OGRN.error}
            maxlength={form.OGRN.maxlength}
            onChange={(value) => updateFormFieldValue("OGRN", value)}
          />
        </div>

        <div>
          <BaseTextarea
            minRows={1}
            value={form.FoundationAgreementDetails.value}
            label="Реквизиты договора основания на наличие права инициировать общее собрание собственников"
            required={form.FoundationAgreementDetails.isRequired}
            placeholder="Введите реквизиты договора основания"
            errorMessage={form.FoundationAgreementDetails.error}
            onChange={(value) => updateFormFieldValue("FoundationAgreementDetails", value)}
          />
        </div>

        <div>
          <PhoneInput
            value={form.Phone.value}
            label="Контактный телефон"
            required={form.Phone.isRequired}
            placeholder="Введите телефон"
            errorMessage={form.Phone.error}
            onChange={(value) => updateFormFieldValue("Phone", value)}
          />
        </div>
      </form>

      <BottomBar
        prevLink={paths
          .uk()
          .fullPath()
          .createOCC.item.author(occId ?? "new")}
      >
        <BaseButton
          variant="primary"
          disabled={!isSubmitEnabled}
          appearance="positive"
          endIcon={arrowRightWhiteIcon}
          isLoading={form.isLoading}
          onClick={handleOnSubmit}
        >
          Дальше
        </BaseButton>
      </BottomBar>
    </div>
  );
};

export default UKCreateOCCSetAdministratorStep;
