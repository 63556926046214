import { CSSProperties, useState } from "react";
import { CloseIcon, Info24Icon } from "lokolo-web-ui-library";
import styles from "./styles.module.scss";

interface IBaseHeadlineAlertProps {
  title?: string;
  name: string;
  children?: JSX.Element;
  icon?: JSX.Element;
  verticalMargin?: string;
  background?: CSSProperties["background"];
  onHide?: () => void;
}

const BaseHeadlineAlert: React.FC<IBaseHeadlineAlertProps> = ({
  title,
  name,
  children,
  icon,
  verticalMargin = "12px 0",
  background,
  onHide,
}) => {
  const storageName = "hidden--alerts";
  const [isHidden, setHidden] = useState<boolean>(false);

  function hideAlert() {
    const stored = localStorage.getItem(storageName);
    const list = stored ? (JSON.parse(stored) as Array<string>) : [];
    list.push(name);
    localStorage.setItem(storageName, JSON.stringify(list));

    setHidden(true);

    if (onHide) {
      onHide();
    }
  }

  const storedAlerts = localStorage.getItem(storageName);
  if (isHidden || (storedAlerts && JSON.parse(storedAlerts).includes(name))) {
    return null;
  }

  return (
    <div style={{ padding: verticalMargin }}>
      <section className={styles.headline_alert} style={{ background }}>
        <div className={styles.content}>
          <span className={styles.icon}>{icon ? icon : <Info24Icon color="var(--lkl-c--icons-white)" />}</span>

          <div className={styles.text}>
            {title && <h4 className="headline-h4 sf-text-bold color--text-white">{title}</h4>}
            {children && (
              <div className="sf-text-regular text--primary color--text-white" style={{ opacity: 0.5 }}>
                {children}
              </div>
            )}
          </div>
        </div>

        <span className={styles.close} role="button" onClick={hideAlert}>
          <CloseIcon color="var(--lkl-c--icons-white)" />
        </span>
      </section>
    </div>
  );
};

export default BaseHeadlineAlert;
