import { NavLink } from "react-router-dom";
import styles from "./styles.module.scss";

interface Tab {
  text: string;
  link: string;
}

interface ITabsProps {
  links: Tab[];
}

const Tabs: React.FC<ITabsProps> = ({ links }) => {
  return (
    <div className={styles["b-tabs"]}>
      <div className="flex">
        <div className={styles["b-tabs__wrapper"]}>
          {links.map((link, i) => (
            <NavLink
              key={i}
              className={({ isActive }) => (isActive ? styles["b-tabs--active"] : undefined)}
              to={link.link}
            >
              <span className="text--primary sf-text-medium">{link.text}</span>
            </NavLink>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Tabs;
