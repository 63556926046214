import { useContext } from "react";
import classNames from "classnames/bind";
import styles from "./styles.module.scss";
import DropdownContext, { SelectMenuItem } from "./context";

const cx = classNames.bind(styles);

interface IBaseDropdownMenuItemProps {
  children?: JSX.Element | JSX.Element[];
  value: SelectMenuItem["value"];
  className?: string;
  defaultHover?: boolean;
  disabled?: boolean;
}

const BaseDropdownMenuItem = ({ children, value, className, defaultHover, disabled }: IBaseDropdownMenuItemProps) => {
  const context = useContext(DropdownContext);

  function onClick(event: React.SyntheticEvent) {
    event.stopPropagation();
    context?.onSelect(value);
  }

  return (
    <div
      className={cx(className, {
        menu_item: true,
        selected: value === context?.value,
        default_hover: defaultHover,
        disabled,
      })}
      role="button"
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default BaseDropdownMenuItem;
