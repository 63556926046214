import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { ExternalServices } from "@/types";
import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import useEmptyBlockHeight from "@/hooks/useEmptyBlockHeight";
import { ChevronDownSmall16Icon, DeleteItem16Icon, EditItem16Icon, OpenItem16Icon } from "@/consts/icons-v2";
import { addIcon24, dropdownSecondaryChevron, moreBtnIcon } from "@/consts/icons";
import { RootState, useAppDispatch } from "@/app/store";
import { ExternalServicesActions, toggleCreateServiceModal } from "@/app/store/slices/externalServices";
import { showSnackbar, SnackbarType } from "@/app/store/slices/snackbar";
import {
  BaseTable,
  BaseTableHead,
  BaseTableBody,
  BaseTableRow,
  BaseTableHeadCell,
  BaseTableCell,
  BaseTableToolbar,
  BaseContainer,
} from "@/components/base-table";
import { BaseModal, BaseModalHeader, BaseModalContent, BaseModalActions } from "@/components/BaseModal";
import { BaseDropdown, BaseDropdownMenuItem } from "@/components/base-dropdown";
import Spinner from "@/components/spinner";
import PaidServiceItemModal, { PaidServiceItemModalMode } from "./ServiceModal";
import BaseTooltip from "@/components/base-tooltip";
import { BaseButton } from "lokolo-web-ui-library";
import styles from "./styles.module.scss";

const PaidServicesList: React.FC = () => {
  const dispatch = useAppDispatch();

  const { isLoading, filters, list, pagination, isCreateModalShown } = useSelector(
    (state: RootState) => state.externalServices.services
  );

  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [isServiceModalOpen, setServiceModalOpen] = useState<boolean>(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
  const [mode, setModalMode] = useState<PaidServiceItemModalMode>();
  const [selectedService, setSelectedService] = useState<ExternalServices.Service.Item["Id"] | null>(null);

  const { measureRef, isIntersecting, observer, hasMore } = useInfiniteScroll({ pagination: pagination ?? undefined });
  const { emptyBlockHeight, emptyBlockRef } = useEmptyBlockHeight();

  const isFiltered: boolean = useMemo(() => {
    return filters.Enable != null;
  }, [filters]);

  function handleRowAction(
    action: PaidServiceItemModalMode | "delete",
    serviceId: ExternalServices.Service.Item["Id"]
  ) {
    if (serviceId) {
      setSelectedService(serviceId);
    }

    switch (action) {
      case "delete": {
        setDeleteModalOpen(true);
        return;
      }
      default: {
        setServiceModalOpen(true);
        setModalMode(action);
      }
    }
  }

  async function deleteService() {
    if (!selectedService) {
      return;
    }

    try {
      setIsDeleteLoading(true);
      await dispatch(ExternalServicesActions.Service.deleteService(selectedService)).unwrap();
      closeModals();
    } catch (error: any) {
      dispatch(
        showSnackbar({
          key: "external-services-delete-error",
          title: "Что-то пошло не так",
          body: error?.Data?.Message ?? "Попробуйте позже",
          type: SnackbarType.ERROR,
        })
      );
    } finally {
      setIsDeleteLoading(false);
    }
  }

  function onFilter(value: boolean | null) {
    dispatch(ExternalServicesActions.Service.setServicesFilter({ Enable: value }));
  }

  function createService() {
    dispatch(toggleCreateServiceModal(true));
  }

  function closeModals() {
    setServiceModalOpen(false);
    setDeleteModalOpen(false);
    setSelectedService(null);
    dispatch(toggleCreateServiceModal(false));
  }

  async function fetch() {
    try {
      Promise.all([
        dispatch(ExternalServicesActions.Service.getCategories()).unwrap(),
        dispatch(ExternalServicesActions.Service.fetchServices()).unwrap(),
      ]);
    } catch (error: any) {
      dispatch(
        showSnackbar({
          key: "external-services-list-error",
          title: "Что-то пошло не так",
          body: error?.Data?.Message ?? "Попробуйте позже",
          type: SnackbarType.ERROR,
        })
      );
    }
  }

  async function fetchMoreData() {
    try {
      const meta = await dispatch(ExternalServicesActions.Service.loadMoreServices()).unwrap();

      if (observer && isIntersecting && meta.Index === meta.Count) {
        observer.disconnect();
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (isIntersecting) {
      fetchMoreData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIntersecting]);

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isCreateModalShown) {
      setServiceModalOpen(true);
      setModalMode(PaidServiceItemModalMode.Add);
    }
  }, [isCreateModalShown]);

  return (
    <>
      <BaseContainer defaultHorizontalOffset defaultTopOffset>
        <BaseTableToolbar>
          <BaseDropdown
            placeholder="Фильтр"
            value={filters.Enable}
            isSelectable={true}
            customIcon={dropdownSecondaryChevron}
            onSelect={onFilter}
            customToggler={
              <div style={{ gap: "4px" }}>
                <span className="color--primary sf-text-medium text--primary">
                  {filters.Enable ? "Активные" : filters.Enable === false ? "Выключенные" : "Фильтр"}
                </span>
                {ChevronDownSmall16Icon("var(--icons-accent)")}
              </div>
            }
          >
            <div>
              <BaseDropdownMenuItem value={null} defaultHover>
                <span className="sf-text-regular">Все услуги</span>
              </BaseDropdownMenuItem>
              <BaseDropdownMenuItem value={true} defaultHover>
                <span className="sf-text-regular">Активные</span>
              </BaseDropdownMenuItem>
              <BaseDropdownMenuItem value={false} defaultHover>
                <span className="sf-text-regular">Выключенные</span>
              </BaseDropdownMenuItem>
            </div>
          </BaseDropdown>
        </BaseTableToolbar>

        <div>
          <BaseTable>
            <BaseTableHead nowrap>
              <BaseTableRow>
                <BaseTableHeadCell>Категория</BaseTableHeadCell>
                <BaseTableHeadCell>Название</BaseTableHeadCell>
                <BaseTableHeadCell>Цена</BaseTableHeadCell>
                <BaseTableHeadCell>Статус инициативы</BaseTableHeadCell>
                <BaseTableHeadCell></BaseTableHeadCell>
              </BaseTableRow>
            </BaseTableHead>

            {isLoading ? (
              <BaseTableBody empty refProp={emptyBlockRef}>
                <BaseTableRow>
                  <BaseTableCell colspan={5} style={{ height: `${emptyBlockHeight}px` }}>
                    <div className="flex center">
                      <Spinner size={36} />
                    </div>
                  </BaseTableCell>
                </BaseTableRow>
              </BaseTableBody>
            ) : list.length ? (
              <>
                <BaseTableBody defaultDivider>
                  {list.map((service) => (
                    <BaseTableRow key={service.Id}>
                      <BaseTableHeadCell>
                        <span className="sf-text-semibold">{service.Category.Title}</span>
                      </BaseTableHeadCell>
                      <BaseTableCell>
                        <span className="sf-text-regular">{service.Title}</span>
                      </BaseTableCell>
                      <BaseTableCell>
                        <span className="sf-text-semibold">{service.Price.formatCurrency()}</span>
                      </BaseTableCell>
                      <BaseTableCell>
                        {service.Enable ? (
                          <span className="color--text-success">Активная</span>
                        ) : (
                          <span className="color--text-warning">Выключенная</span>
                        )}
                      </BaseTableCell>
                      <BaseTableCell style={{ width: "24px" }}>
                        <BaseDropdown
                          customToggler={moreBtnIcon}
                          onSelect={(value) => handleRowAction(value, service.Id)}
                        >
                          <BaseDropdownMenuItem value={PaidServiceItemModalMode.View} defaultHover>
                            {OpenItem16Icon()}
                            <span className="sf-text-regular">Подробнее</span>
                          </BaseDropdownMenuItem>
                          <BaseDropdownMenuItem value={PaidServiceItemModalMode.Edit} defaultHover={true}>
                            {EditItem16Icon()}
                            <span className="sf-text-regular">Редактировать</span>
                          </BaseDropdownMenuItem>
                          <BaseTooltip
                            title={
                              service.HasOrders ? (
                                <div>
                                  Нельзя удалить услугу, по которой уже были заявки. <br /> Вы можете выключить услугу в
                                  режиме редактирования
                                </div>
                              ) : (
                                ""
                              )
                            }
                            placement="left"
                          >
                            <BaseDropdownMenuItem value="delete" defaultHover disabled={service.HasOrders}>
                              {DeleteItem16Icon()}
                              <span className="sf-text-regular">Удалить</span>
                            </BaseDropdownMenuItem>
                          </BaseTooltip>
                        </BaseDropdown>
                      </BaseTableCell>
                    </BaseTableRow>
                  ))}
                </BaseTableBody>
                {hasMore && (
                  <tbody ref={measureRef}>
                    <tr>
                      <td colSpan={7}>
                        <div className="flex center">
                          <Spinner size={36} />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )}
              </>
            ) : (
              <BaseTableBody empty refProp={emptyBlockRef}>
                <BaseTableRow>
                  <BaseTableCell colspan={5} style={{ height: `${emptyBlockHeight}px` }}>
                    <div className={styles.empty}>
                      {isFiltered ? (
                        <div className="center">
                          <h4 className="headline-h4 sf-text-bold color--text-primary">Ничего не нашлось</h4>
                          <p className="text--primary sf-pro-regular color--text-primary">
                            Возможно, стоит изменить параметры поиска или сбросить фильтр
                          </p>
                        </div>
                      ) : (
                        <>
                          {OnEmptyImage}
                          <div className="center">
                            <h4 className="headline-h4 sf-text-bold color--text-primary">Услуг пока нет</h4>
                            <p className="text--primary sf-pro-regular color--text-primary">
                              Не теряйте времени — добавляйте платные услуги, рассказывайте о них и зарабатывайте на их
                              предоставлении
                            </p>
                          </div>
                          <BaseButton startIcon={addIcon24()} onClick={createService}>
                            Добавить услугу
                          </BaseButton>
                        </>
                      )}
                    </div>
                  </BaseTableCell>
                </BaseTableRow>
              </BaseTableBody>
            )}
          </BaseTable>
        </div>
      </BaseContainer>

      <BaseModal isOpen={isDeleteModalOpen} maxWidth="426px" minWidth="426px">
        <BaseModalHeader title="Удалить услугу?" onClose={() => setDeleteModalOpen(false)} />

        <BaseModalContent>
          <p className="text--primary sf-text-regular">Вы уверены что хотите удалить услугу?</p>
        </BaseModalContent>

        <BaseModalActions>
          <BaseButton variant="secondary" onClick={() => setDeleteModalOpen(false)}>
            Отменить
          </BaseButton>

          <BaseButton isLoading={isDeleteLoading} onClick={deleteService}>
            Удалить
          </BaseButton>
        </BaseModalActions>
      </BaseModal>

      {mode && (
        <PaidServiceItemModal
          isOpen={isServiceModalOpen}
          serviceId={selectedService}
          mode={mode}
          onClose={closeModals}
        />
      )}
    </>
  );
};

export default PaidServicesList;

const OnEmptyImage = (
  <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="200" height="200" fill="white" />
    <rect x="44" y="68" width="126" height="80" rx="12" fill="#D9D9D9" />
    <rect x="44" y="68" width="126" height="80" rx="12" fill="url(#paint0_linear_9340_29126)" />
    <path
      d="M29.4082 10.5724C29.5434 6.69992 34.5551 5.26284 36.7219 8.47527L40.4011 13.9298C41.3701 15.3665 43.154 16.0157 44.8198 15.5381L51.1443 13.7246C54.869 12.6565 57.7845 16.9788 55.3989 20.0323L51.3482 25.2169C50.2813 26.5824 50.2151 28.4797 51.1841 29.9163L54.8632 35.3708C57.03 38.5833 53.8202 42.6917 50.179 41.3664L43.9964 39.1161C42.368 38.5234 40.5432 39.0467 39.4763 40.4122L35.4256 45.5968C33.04 48.6503 28.1408 46.8671 28.276 42.9946L28.5056 36.4192C28.5661 34.6874 27.5045 33.1136 25.8761 32.5209L19.6936 30.2706C16.0523 28.9453 16.2343 23.7348 19.9591 22.6668L26.2836 20.8533C27.9494 20.3756 29.1181 18.8797 29.1786 17.1478L29.4082 10.5724Z"
      fill="url(#paint1_linear_9340_29126)"
    />
    <path
      d="M145.666 75.0015C136.186 64.9654 136.638 49.1451 146.674 39.6658C156.71 30.1865 172.53 30.6378 182.009 40.6739C191.489 50.7099 191.037 66.5302 181.001 76.0095C170.965 85.4888 155.145 85.0375 145.666 75.0015Z"
      fill="url(#paint2_linear_9340_29126)"
      stroke="url(#paint3_linear_9340_29126)"
      strokeWidth="2"
    />
    <g filter="url(#filter0_b_9340_29126)">
      <path
        d="M147.846 72.942C139.504 64.1102 139.901 50.1883 148.733 41.8464C157.565 33.5046 171.487 33.9018 179.829 42.7335C188.171 51.5653 187.773 65.4873 178.942 73.8291C170.11 82.1709 156.188 81.7738 147.846 72.942Z"
        fill="url(#paint4_linear_9340_29126)"
        fillOpacity="0.5"
      />
    </g>
    <g filter="url(#filter1_i_9340_29126)">
      <path
        d="M155.584 66.4212C155.508 66.4375 155.459 66.513 155.475 66.5897L155.771 67.9849C155.788 68.0617 155.863 68.1107 155.94 68.0944L158.721 67.5033C158.798 67.4869 158.873 67.5359 158.89 67.6127L159.581 70.8652C159.597 70.942 159.673 70.991 159.75 70.9747L164.205 70.0276C164.282 70.0113 164.331 69.9358 164.315 69.859L163.623 66.6065C163.607 66.5298 163.656 66.4543 163.733 66.438L169.493 65.2137C169.57 65.1973 169.619 65.1219 169.602 65.0451L169.306 63.6499C169.289 63.5731 169.214 63.5241 169.137 63.5404L163.377 64.7647C163.301 64.781 163.225 64.732 163.209 64.6553L162.592 61.7541C162.576 61.6774 162.625 61.6019 162.702 61.5856L166.395 60.8006C171.112 59.7978 173.664 55.9676 172.732 51.5838C171.793 47.1664 168.049 44.6744 163.428 45.6566L154.76 47.4991C154.683 47.5155 154.634 47.5909 154.65 47.6677L157.433 60.7589C157.449 60.8356 157.4 60.9111 157.323 60.9274L154.542 61.5186C154.465 61.5349 154.416 61.6104 154.433 61.6871L154.74 63.1325C154.756 63.2093 154.832 63.2583 154.909 63.242L157.69 62.6509C157.767 62.6345 157.842 62.6835 157.858 62.7603L158.475 65.6614C158.491 65.7382 158.442 65.8137 158.366 65.83L155.584 66.4212ZM160.213 50.5602C160.196 50.4834 160.245 50.4079 160.322 50.3916L162.84 49.8565C165.674 49.2541 167.457 50.3615 167.945 52.6538C168.439 54.9796 167.24 56.7034 164.406 57.3058L161.905 57.8375C161.828 57.8538 161.753 57.8048 161.736 57.728L160.213 50.5602Z"
        fill="#869AD5"
      />
    </g>
    <path
      d="M26.8574 144.716C26.4109 142.26 28.2972 140 30.7929 140H86.4143C91.4055 140 95.1781 144.52 94.2853 149.431L89.1944 177.431C88.5027 181.235 85.1897 184 81.3234 184H40.6766C36.8103 184 33.4973 181.235 32.8057 177.431L26.8574 144.716Z"
      fill="#40D454"
    />
    <path
      d="M12 125H15.4862C20.5856 125 25.128 128.223 26.8125 133.036L31 145"
      stroke="#40D454"
      strokeWidth="8"
      strokeLinecap="round"
    />
    <circle cx="48" cy="194" r="6" fill="#40D454" />
    <circle cx="74" cy="194" r="6" fill="#40D454" />
    <rect x="47" y="156" width="4" height="16" rx="2" fill="white" />
    <rect x="59" y="156" width="4" height="16" rx="2" fill="white" />
    <rect x="71" y="156" width="4" height="16" rx="2" fill="white" />
    <g clipPath="url(#clip0_9340_29126)">
      <rect x="117" y="110" width="72" height="72" rx="36" fill="#3B3A4A" />
      <path
        d="M135.946 159.223C145.942 152.446 159.058 152.446 169.054 159.223C177.151 164.712 182 173.858 182 183.641V188H123V183.641C123 173.858 127.849 164.712 135.946 159.223Z"
        fill="white"
        fillOpacity="0.4"
      />
      <path
        d="M166 137C166 144.18 160.18 150 153 150C145.82 150 140 144.18 140 137C140 129.82 145.82 124 153 124C160.18 124 166 129.82 166 137Z"
        fill="white"
        fillOpacity="0.4"
      />
    </g>
    <rect x="64" y="38" width="16" height="16" rx="4" fill="url(#paint5_linear_9340_29126)" />
    <rect x="64" y="38" width="16" height="16" rx="4" fill="url(#paint6_linear_9340_29126)" />
    <defs>
      <filter
        id="filter0_b_9340_29126"
        x="129.841"
        y="23.8408"
        width="67.9932"
        height="67.9939"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="6" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_9340_29126" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_9340_29126" result="shape" />
      </filter>
      <filter
        id="filter1_i_9340_29126"
        x="154.43"
        y="45.4438"
        width="18.4922"
        height="26.8252"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.29123" />
        <feGaussianBlur stdDeviation="0.860818" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.517361 0 0 0 0 0.6 0 0 0 0 0.833333 0 0 0 1 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_9340_29126" />
      </filter>
      <linearGradient
        id="paint0_linear_9340_29126"
        x1="107.006"
        y1="67.9727"
        x2="107.006"
        y2="147.99"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#509FF4" />
        <stop offset="0.3778" stopColor="#417BEE" />
        <stop offset="1" stopColor="#3A62F1" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_9340_29126"
        x1="29.8379"
        y1="-1.73079"
        x2="46.3761"
        y2="55.9449"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFEA7C" />
        <stop offset="1" stopColor="#FFD46F" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_9340_29126"
        x1="170.849"
        y1="72.8333"
        x2="143.017"
        y2="25.8351"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B7C5EA" />
        <stop offset="0.944547" stopColor="#D5DEF4" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_9340_29126"
        x1="158.432"
        y1="32.4058"
        x2="172.369"
        y2="93.6234"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E6EBF8" />
        <stop offset="1" stopColor="#E6EBF8" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_9340_29126"
        x1="157.06"
        y1="45.6359"
        x2="174.075"
        y2="69.7941"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B8C5EA" />
        <stop offset="0.958333" stopColor="#CFD9F3" />
      </linearGradient>
      <linearGradient id="paint5_linear_9340_29126" x1="72" y1="38" x2="72" y2="54" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6DFFB8" />
        <stop offset="1" stopColor="#6EF0B1" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_9340_29126"
        x1="70.5052"
        y1="52.514"
        x2="72.0844"
        y2="40.3522"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF6774" />
        <stop offset="1" stopColor="#FA8FCB" />
      </linearGradient>
      <clipPath id="clip0_9340_29126">
        <rect x="117" y="110" width="72" height="72" rx="36" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
