import { useEffect, useMemo, useState } from "react";
import classNames from "classnames/bind";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Institution, Profile } from "@/types";
import { InstitutionService } from "@/services/v2";
import { RootState, useAppDispatch } from "@/app/store";
import { showSnackbar } from "@/app/store/slices/snackbar";
import { paths } from "@/consts/routes";
import { PageHeader } from "lokolo-web-ui-library";
import UkHousesCountImage from "@/assets/images/uk-houses-count.jpeg";
import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

const InstitutionMunicipalityCompany: React.FC = () => {
  const urlParams = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const companies = useSelector((state: RootState) => state.institution.companies.list);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [company, setCompany] = useState<Institution.CompanyFullDetails>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const companyId: Institution.Company["Id"] | null = useMemo(() => {
    return urlParams.companyId ? parseInt(urlParams.companyId) : null;
  }, [urlParams.companyId]);

  const shortedCompany = useMemo(() => {
    return companies.find((it) => it.Id === companyId);
  }, [companies, companyId]);

  const type = useMemo(() => {
    return shortedCompany?.Type ?? company?.Type;
  }, [shortedCompany, company]);

  function onBackClick() {
    navigate(paths.municipality().fullPath().companies.list);
  }

  async function fetch() {
    if (!companyId) {
      throw new Error("");
    }

    setLoading(true);
    try {
      setErrorMessage(null);
      const { data } = await InstitutionService.getCompanyDetails(companyId);
      setCompany(data.Data);
    } catch (error: any) {
      const message = error?.Data?.Message ?? "Попробуйте войти позднее, мы уже работаем над проблемой";
      dispatch(
        showSnackbar({
          key: "get-company-statistics",
          title: "Данные не загрузились",
          body: message,
        })
      );
      setErrorMessage(message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  return (
    <div className={styles["p-company"]}>
      <PageHeader title={company?.Name ?? shortedCompany?.Name ?? " "} onBackClick={onBackClick} />

      <div className="horizontal-offset">
        <div className={styles["p-company__content"]}>
          <div className={cx("p-company__card", "p-company__user-exist")}>
            <div>
              <h3 className="lkl-tpgrhy--headline--h4 lkl-color--text-white lkl-font--sf-text--bold">
                Есть ли учетная запись
                <br />в Локоло?
              </h3>
              <p>
                {!isLoading && (
                  <>
                    {!company && errorMessage ? (
                      <span className={cx("p-company__user-exist_neutral", "lkl-font--sf--bold")}>–</span>
                    ) : company?.LokoloUserExists ? (
                      <span className={cx("p-company__user-exist_positive", "lkl-font--sf--bold")}>Да</span>
                    ) : (
                      <span className={cx("p-company__user-exist_negative", "lkl-font--sf--bold")}>Нет</span>
                    )}
                  </>
                )}
              </p>
            </div>
          </div>

          <div className={cx("p-company__card", "p-company__users-count")}>
            <div>
              <h3 className="lkl-tpgrhy--headline--h4 lkl-color--text-white lkl-font--sf-text--bold">
                Общее количество
                <br />
                пользователей
              </h3>
              <p>
                <span className="lkl-font--sf--bold">
                  {!isLoading && <>{!company && errorMessage ? "–" : (company?.UsersCount ?? 0).formatRU()}</>}
                </span>
              </p>
            </div>
          </div>

          {type === Profile.CompanyType.UK && (
            <div
              className={cx("p-company__splitted-card", "p-company__splitted-card--imaged", "p-company__houses")}
              style={{ backgroundImage: `url(${UkHousesCountImage})` }}
            >
              <div>
                <h3 className="lkl-tpgrhy--headline--h4 lkl-color--text-white lkl-font--sf-text--bold">
                  Количество
                  <br />
                  домов
                </h3>
                <p className="lkl-font--sf--bold">
                  {!isLoading && <>{!company && errorMessage ? "–" : (company?.HousesCount ?? 0).formatRU()}</>}
                </p>
              </div>

              <div className={styles["p-company__splitted-card__small"]}>
                <h3 className="lkl-tpgrhy--headline--h4 lkl-color--text-white lkl-font--sf-text--bold">
                  *С одним и более
                  <br />
                  пользователем
                </h3>
                <p className="lkl-font--sf--bold">
                  {!isLoading && (
                    <>{!company && errorMessage ? "–" : (company?.HousesWithUsersCount ?? 0).formatRU()}</>
                  )}
                </p>
              </div>
            </div>
          )}

          <div className={cx("p-company__splitted-card", "p-company__occ")}>
            <div>
              <h3 className="lkl-tpgrhy--headline--h4 lkl-color--text-white lkl-font--sf-text--bold">
                Проведенных
                <br />
                собраний собственников
              </h3>
              <p className="lkl-font--sf--bold">
                {!isLoading && <>{!company && errorMessage ? "–" : (company?.OccCount ?? 0).formatRU()}</>}
              </p>
            </div>

            <div className={styles["p-company__splitted-card__small"]}>
              <h3 className="lkl-tpgrhy--headline--h4 lkl-color--text-white lkl-font--sf-text--bold">
                *С набранным кворумом
              </h3>
              <p className="lkl-font--sf--bold">
                {!isLoading && <>{!company && errorMessage ? "–" : (company?.ReachedQuorumOccCount ?? 0).formatRU()}</>}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstitutionMunicipalityCompany;
