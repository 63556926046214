import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dictionary, OccInitiatives, Pagination } from "@/types";

export interface IOccInitiativesState {
  isLoading: boolean;
  list: OccInitiatives.ListItem[];
  pagination: Pagination | null;
  filters: {
    Status: OccInitiatives.Status | null;
  };
  statuses: Dictionary.AlternativeDictionaryItem<OccInitiatives.Status>[];
  count: number;

  selected: {
    details: OccInitiatives.FullDetails | null;
    questions: {
      isLoading: boolean;
      list: OccInitiatives.Question[];
      pagination: Pagination | null;
    };
    votes: {
      isLoading: boolean;
      list: OccInitiatives.Vote[];
      pagination: Pagination | null;
    };
  };
}

const occInitiativesSlice = createSlice({
  name: "occ-initiatives",

  initialState: {
    isLoading: false,
    list: [],
    pagination: null,
    filters: {
      Status: null,
    },
    statuses: [
      {
        Value: "Новые",
        Key: OccInitiatives.Status.New,
      },
      {
        Value: "Ожидают проведения собрания",
        Key: OccInitiatives.Status.WaitOccCreate,
      },
      {
        Value: "Собрание создано",
        Key: OccInitiatives.Status.OccCreated,
      },
      {
        Value: "Просроченные",
        Key: OccInitiatives.Status.Expired,
      },
      {
        Value: "Отклоненные",
        Key: OccInitiatives.Status.Rejected,
      },
    ],
    count: 0,

    selected: {
      details: null,
      questions: { isLoading: false, list: [], pagination: null },
      votes: { isLoading: false, list: [], pagination: null },
    },
  } as IOccInitiativesState,

  reducers: {
    toggleLoading: (state, action: PayloadAction<IOccInitiativesState["isLoading"]>) => {
      state.isLoading = action.payload;
    },

    setInitiativeList: (state, action: PayloadAction<IOccInitiativesState["list"]>) => {
      state.list = action.payload;
    },

    pushInitiative: (state, action: PayloadAction<IOccInitiativesState["list"]>) => {
      state.list.push(...action.payload);
    },

    setFilters: (state, action: PayloadAction<IOccInitiativesState["filters"]>) => {
      state.filters = action.payload;
    },

    setPagination: (state, action: PayloadAction<IOccInitiativesState["pagination"]>) => {
      state.pagination = action.payload;
    },

    setCount: (state, action: PayloadAction<IOccInitiativesState["count"]>) => {
      state.count = action.payload;
    },

    setSelectedDetails: (state, action: PayloadAction<IOccInitiativesState["selected"]["details"]>) => {
      state.selected.details = action.payload;
    },

    setSelectedQuestions: (state, action: PayloadAction<IOccInitiativesState["selected"]["questions"]["list"]>) => {
      state.selected.questions.list = action.payload;
    },
    pushSelectedQuestions: (state, action: PayloadAction<IOccInitiativesState["selected"]["questions"]["list"]>) => {
      state.selected.questions.list.push(...action.payload);
    },
    setSelectedQuestionsPagination: (
      state,
      action: PayloadAction<IOccInitiativesState["selected"]["questions"]["pagination"]>
    ) => {
      state.selected.questions.pagination = action.payload;
    },
    toggleSelectedQuestionsLoading: (
      state,
      action: PayloadAction<IOccInitiativesState["selected"]["questions"]["isLoading"]>
    ) => {
      state.selected.questions.isLoading = action.payload;
    },

    setSelectedVotes: (state, action: PayloadAction<IOccInitiativesState["selected"]["votes"]["list"]>) => {
      state.selected.votes.list = action.payload;
    },
    pushSelectedVotes: (state, action: PayloadAction<IOccInitiativesState["selected"]["votes"]["list"]>) => {
      state.selected.votes.list.push(...action.payload);
    },
    setSelectedVotesPagination: (
      state,
      action: PayloadAction<IOccInitiativesState["selected"]["votes"]["pagination"]>
    ) => {
      state.selected.votes.pagination = action.payload;
    },
    toggleSelectedVotesLoading: (
      state,
      action: PayloadAction<IOccInitiativesState["selected"]["votes"]["isLoading"]>
    ) => {
      state.selected.votes.isLoading = action.payload;
    },

    clearSelected: (state) => {
      state.selected = {
        details: null,
        questions: { isLoading: false, list: [], pagination: null },
        votes: { isLoading: false, list: [], pagination: null },
      };
    },
  },
});

export default occInitiativesSlice.reducer;
export * from "./actions";
export const {
  toggleLoading,
  setInitiativeList,
  pushInitiative,
  setFilters,
  setPagination,
  setCount,
  setSelectedDetails,
  setSelectedQuestions,
  pushSelectedQuestions,
  setSelectedQuestionsPagination,
  toggleSelectedQuestionsLoading,
  setSelectedVotes,
  pushSelectedVotes,
  setSelectedVotesPagination,
  toggleSelectedVotesLoading,
  clearSelected,
} = occInitiativesSlice.actions;
