import { Profile } from "./profile";

export namespace Institution {
  export interface Statistics {
    UsersCount: number;
    MkdUsersCount: number;
    SntUsersCount: number;
    PrivateHouseUsersCount: number;
    ActiveUkCount: number;
    ActiveSntCount: number;
    BusinessUsersCount: number;
    OccCount: number;
    ReachedQuorumOccCount: number;
  }

  export interface Company {
    Id: number;
    Name: string;
    FullName: string;
    Type: Profile.CompanyType;
    LokoloUserExists: boolean;
  }

  export interface CompanyFullDetails extends Company {
    UsersCount: number;
    OccCount: number;
    ReachedQuorumOccCount: number;
    HousesCount: number;
    HousesWithUsersCount: number;
  }

  export const companyTypes = [
    { value: Profile.CompanyType.UK, text: "Управляющая организация" },
    { value: Profile.CompanyType.SNT, text: "Садоводство" },
  ];
}
