import { useSelector } from "react-redux";
import { RootState } from "@/app/store";
import { paths } from "@/consts/routes";
import { CalendarCheckIcon, CheckListIcon, ChevronRight16Icon } from "@/consts/icons-v2";
import CompanyCommonMenu from "../Company";
import MenuItem from "../../MenuItem";
import NestedMenuItem from "../../NestedMenuItem";

const CompanySntMenu: React.FC = () => {
  const sntDetails = useSelector((state: RootState) => state.sntRegistry.details);

  return (
    <>
      <MenuItem path="/snt/registry" text="Реестр участков" icon={CheckListIcon("var(--lkl-c--icons-primary)")}>
        <span className="lkl-font--sf-text--medium lkl-tpgrhy--text--primary lkl-color--text-secondary">
          {sntDetails?.SectionsCount}
        </span>
      </MenuItem>

      <NestedMenuItem text="Cобрания" icon={CalendarCheckIcon("var(--lkl-c--icons-primary)")}>
        <MenuItem path="/snt/create-occ" text="Создать собрание">
          {ChevronRight16Icon("var(--lkl-c--icons-primary)")}
        </MenuItem>
        <MenuItem path={paths.myOcc} text="Мои собрания">
          {ChevronRight16Icon("var(--lkl-c--icons-primary)")}
        </MenuItem>
      </NestedMenuItem>

      <CompanyCommonMenu />
    </>
  );
};

export default CompanySntMenu;
