import { OCCV2Order } from "../order";

export namespace OCCV2Author {
  export type Person = Individual | LegalEntity | ManagementCompany;

  export enum PersonType {
    Individual = 0,
    LegalEntity = 1,
    ManagementCompany = 2,
  }

  export type Base = {
    Type: PersonType;
    Name: string;
    Phone: number | null;
  };

  export type Individual = Base & {
    Type: PersonType.Individual;
    Surname: string;
    Patronymic: string | null;
    RoomAddress: string | null;
    OwnershipDocumentDetails: string | null;
    PermanentResidencePlace: string | null;
    Email: string | null;
    Series: string | null;
    Number: string | null;
    IssuedBy: string | null;
    IssueDate: string | null;
    DepartmentCode: string | null;
    ObjectNumber: string | null;
    Phone: string | number | null;
  };

  export type LegalEntity = Base & {
    Type: PersonType.LegalEntity;
    LegalForm: string;
    EntityLegalForm: string;
    OGRN: string | null;
    EntityOGRN: string | null;
    FoundationAgreementDetails: string | null;
    Location: string | null;
    MailingAddress: string | null;
    Site: string | null;
  };

  export type ManagementCompany = Base & {
    Type: PersonType.ManagementCompany;
    UkId: number;
    LegalForm: string;
    UkLegalForm: string;
    OGRN: string | null;
    UkOGRN: string | null;
    FoundationAgreementDetails: string | null;
  };

  export type SetAuthorBodyBase = {
    TypeId: PersonType;
    OccId: OCCV2Order.Base["Id"];
    Object: {
      Name: string;
      Phone: string;
    };
  };

  // SetAdministrator
  export type SetAdministratorBodyIndividual = SetAuthorBodyBase & {
    TypeId: PersonType.Individual;
    Object: {
      Name: SetAuthorBodyBase["Object"]["Name"];
      Phone: SetAuthorBodyBase["Object"]["Phone"];
      Surname: string;
      Patronymic: string;
      PermanentResidencePlace: string;
      Email: string;
      Series: string;
      Number: string;
      IssuedBy: string;
      IssueDate: string;
      DepartmentCode: string;
    };
  };

  export type SetAdministratorBodyLegalEntity = SetAuthorBodyBase & {
    TypeId: PersonType.LegalEntity;
    Object: {
      Name: SetAuthorBodyBase["Object"]["Name"];
      Phone: SetAuthorBodyBase["Object"]["Name"];
      Location: string;
      MailingAddress: string;
      Site: string;
      LegalForm: string;
    };
  };

  export type SetAdministratorBodyManagementCompany = SetAuthorBodyBase & {
    TypeId: PersonType.ManagementCompany;
    Object: {
      Name: SetAuthorBodyBase["Object"]["Name"];
      Phone: SetAuthorBodyBase["Object"]["Phone"];
      FoundationAgreementDetails: string;
      LegalForm: string;
      OGRN: string;
    };
  };

  export type SetAdministratorBody =
    | SetAdministratorBodyIndividual
    | SetAdministratorBodyLegalEntity
    | SetAdministratorBodyManagementCompany;

  // SetAuthor
  export type SetAuthorBodyIndividual = SetAuthorBodyBase & {
    TypeId: PersonType.Individual;
    Object: {
      Name: SetAuthorBodyBase["Object"]["Name"];
      Phone: SetAuthorBodyBase["Object"]["Name"];
      Surname: string;
      Patronymic: string;
      RoomAddress: string;
    };
  };

  export type SetAuthorBodyLegalEntity = SetAuthorBodyBase & {
    TypeId: PersonType.LegalEntity;
    Object: {
      Name: SetAuthorBodyBase["Object"]["Name"];
      Phone: SetAuthorBodyBase["Object"]["Name"];
      Location?: string;
      EntityLegalForm: string;
      EntityOGRN: string;
    };
  };

  export type SetAuthorBodyManagementCompany = SetAuthorBodyBase & {
    TypeId: PersonType.ManagementCompany;
    Object: {
      Name: SetAuthorBodyBase["Object"]["Name"];
      Phone: SetAuthorBodyBase["Object"]["Name"];
      FoundationAgreementDetails: string;
      UkLegalForm: string;
      UkOGRN: string;
    };
  };

  export type SetAuthorBody = SetAuthorBodyIndividual | SetAuthorBodyLegalEntity | SetAuthorBodyManagementCompany;
}

export * from "./forms";
export * as OCCV2AuthorUsecase from "./usecase";
