import { useMemo } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames/bind";
import { Dictionary, OCCV2Order } from "@/types";
import { selectGisZhkhSentStatusByKey } from "@/app/store/slices/dictionary";
import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

interface IOccGisZhkhStatusProps {
  statusKey: OCCV2Order.GisZhkhStatus;
  statusName?: Dictionary.DictionaryItem<OCCV2Order.GisZhkhStatus>["name"] | null;
  className?: string;
}

const OccGisZhkhStatus: React.FC<IOccGisZhkhStatusProps> = ({ statusKey, statusName, className }) => {
  const status = useSelector(selectGisZhkhSentStatusByKey(statusKey));

  const statusColor: string = useMemo(() => {
    switch (status?.key) {
      case OCCV2Order.GisZhkhStatus.Draft:
        return "#7E858E";

      case OCCV2Order.GisZhkhStatus.Error:
        return "#F72C32";

      case OCCV2Order.GisZhkhStatus.InProcess:
        return "#FC9F1C";

      case OCCV2Order.GisZhkhStatus.NotSent:
        return "#0041E5";

      case OCCV2Order.GisZhkhStatus.Sent:
        return "#0AC153";

      case OCCV2Order.GisZhkhStatus.WaitEndOcc:
        return "#583CF1";

      case OCCV2Order.GisZhkhStatus.WaitSent:
        return "#1E93FF";

      default:
        return "#7E858E";
    }
  }, [status]);

  return (
    <div className={cx(className, "b-gis-zhkh-status")} style={{ color: statusColor }}>
      <span></span>
      <div className="caption--middle sf-text-medium">{statusName ? statusName : status?.name}</div>
    </div>
  );
};

export default OccGisZhkhStatus;
