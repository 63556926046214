import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ExternalServices } from "@/types";
import { ExternalServicesService } from "@/services/v2";
import { QuestionTooltip16Icon } from "@/consts/icons-v2";
import { RootState, useAppDispatch } from "@/app/store";
import { isCompanyTypeUK } from "@/app/store/slices/user";
import { ExternalServicesActions } from "@/app/store/slices/externalServices";
import { showSnackbar, SnackbarType } from "@/app/store/slices/snackbar";
import { BaseModal, BaseModalHeader, BaseModalContent, BaseModalActions } from "@/components/BaseModal";
import { BaseDropdown, BaseDropdownMenuItem } from "@/components/base-dropdown";
import BaseButton from "@/components/base-button";
import BaseInput from "@/components/base-input";
import NumberInput from "@/components/base-input/NumberInput";
import SelectAddressSubmodal, { SelectAddressDefaultButton } from "@/components/SelectAddressSubmodal";
import BaseSwitcher from "@/components/BaseSwitcher";
import BaseTooltip from "@/components/base-tooltip";
import BaseTextarea from "@/components/base-textarea";
import Spinner from "@/components/spinner";
import styles from "./styles.module.scss";

export enum PaidServiceItemModalMode {
  Add = "add",
  Edit = "edit",
  View = "view",
}

interface IPaidServiceItemModalProps {
  isOpen: boolean;
  mode: PaidServiceItemModalMode;
  onClose: () => void;
  serviceId?: ExternalServices.Service.Item["Id"] | null;
}

const PaidServiceItemModal: React.FC<IPaidServiceItemModalProps> = ({ isOpen, mode, onClose, serviceId }) => {
  const dispatch = useAppDispatch();

  const isUK = useSelector(isCompanyTypeUK);
  const addresses = useSelector((state: RootState) => state.user.company?.Addresses);
  const categories = useSelector((state: RootState) => state.externalServices.services.categories);

  const containerRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form, setForm] = useState<ExternalServices.Service.CreateForm>(new ExternalServices.Service.CreateForm());
  const [submodalPosition, setSubmodalPosition] = useState<{ left: number | string }>({ left: 0 });
  const [isSelectAddressSubmodalShown, setSelectAddressSubmodalShown] = useState<boolean>(false);

  const isEditing: boolean = useMemo(() => {
    return mode === PaidServiceItemModalMode.Edit;
  }, [mode]);

  const isViewing: boolean = useMemo(() => {
    return mode === PaidServiceItemModalMode.View;
  }, [mode]);

  const title: { title: string; subtitle?: string } | null = useMemo(() => {
    switch (mode) {
      case PaidServiceItemModalMode.View:
        return {
          title: "Подробнее",
        };

      case PaidServiceItemModalMode.Add:
        return {
          title: "Добавить услугу",
        };

      case PaidServiceItemModalMode.Edit:
        return {
          title: "Редактировать услугу",
          subtitle: "Изменения не будут применены к активным заявкам",
        };

      default:
        return null;
    }
  }, [mode]);

  function isFormValid() {
    const validator = ExternalServices.Service.CreateFormValidator.isInvalid(form, isUK);

    if (validator) {
      setForm((prevState) => ({
        ...prevState,
        Title: { ...prevState.Title, error: validator.Title },
        Category: { ...prevState.Category, error: validator.Category },
        Description: { ...prevState.Description, error: validator.Description },
        Price: { ...prevState.Price, error: validator.Price },
        HouseIdsError: validator.HouseIds,
      }));
    }
    return !validator;
  }

  async function onSubmit() {
    setForm((prevState) => ({
      ...prevState,
      Title: { ...prevState.Title, error: "" },
      Category: { ...prevState.Category, error: "" },
      Description: { ...prevState.Description, error: "" },
      Price: { ...prevState.Price, error: "" },
      HouseIdsError: "",
      error: "",
    }));

    if (!isFormValid()) {
      return;
    }

    const body = ExternalServices.Service.CreateFormValidator.serialize(
      form,
      isUK,
      !isUK && addresses?.length ? addresses[0].Id : undefined
    );
    if (!body) {
      return;
    }

    setForm((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      if (isEditing && serviceId) {
        await dispatch(
          ExternalServicesActions.Service.updateService({
            serviceId,
            body,
          })
        ).unwrap();
      } else {
        await dispatch(ExternalServicesActions.Service.createService(body)).unwrap();
      }
      onClose();
    } catch (error: any) {
      dispatch(
        showSnackbar({
          key: "external-services-modal-error",
          title: "Что-то пошло не так",
          body: error?.Data?.Message ?? "Попробуйте позже",
          type: SnackbarType.ERROR,
        })
      );
    } finally {
      setForm((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  }

  function showSelectAddressSubmodal() {
    const rect = containerRef.current?.getBoundingClientRect();

    setSubmodalPosition({
      left: rect ? `${rect.left + rect.width * (1 / 2)}px` : 0,
    });

    setTimeout(() => {
      setSelectAddressSubmodalShown(true);
    }, 100);
  }

  async function fetch() {
    setIsLoading(true);
    try {
      if (serviceId) {
        const { data } = await ExternalServicesService.Service.getServiceById(serviceId);
        setForm(new ExternalServices.Service.CreateForm(data.Data, isEditing, isViewing));
        return;
      }

      setForm(new ExternalServices.Service.CreateForm(null, isEditing, isViewing));
    } catch (error: any) {
      dispatch(
        showSnackbar({
          key: "external-services-modal-error",
          title: "Что-то пошло не так",
          body: error?.Data?.Message ?? "Попробуйте позже",
          type: SnackbarType.ERROR,
        })
      );
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (isOpen) {
      fetch();
    } else {
      setForm(new ExternalServices.Service.CreateForm());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <BaseModal isOpen={isOpen} maxWidth="700px" minWidth="700px">
      <div className={styles["b-service-modal"]} ref={containerRef}>
        <BaseModalHeader title={title?.title ?? ""} subtitle={title?.subtitle} onClose={onClose} />

        <BaseModalContent>
          {isLoading ? (
            <div className={styles["b-service-modal__loading"]}>
              <Spinner size={36} />
            </div>
          ) : (
            <div className={styles["b-service-modal__content"]}>
              <div>
                <BaseDropdown
                  value={form.Category.value?.Id}
                  display={form.Category.value?.Title}
                  label="Категория"
                  required={form.Category.isRequired}
                  disabled={form.Category.isDisabled}
                  errorMessage={form.Category.error}
                  placeholder="Выберите"
                  variant="formfield"
                  locked={isEditing}
                  isSelectable={true}
                  onSelect={(value) => {
                    const category = categories.find((it) => it.Id === value);
                    setForm((prevState) => ({
                      ...prevState,
                      Category: { ...prevState.Category, value: category ?? null },
                    }));
                  }}
                >
                  {categories.map((category) => (
                    <BaseDropdownMenuItem key={category.Id} value={category.Id}>
                      <span className="sf-text-regular">{category.Title}</span>
                    </BaseDropdownMenuItem>
                  ))}
                </BaseDropdown>
              </div>

              <div>
                <BaseInput
                  value={form.Title.value}
                  label="Название"
                  required={form.Title.isRequired}
                  disabled={form.Title.isDisabled}
                  maxlength={form.Title.maxlength}
                  locked={isEditing}
                  errorMessage={form.Title.error}
                  placeholder="Например: Доработка электроснабжения"
                  onChange={(value) =>
                    setForm((prevState) => ({
                      ...prevState,
                      Title: { ...prevState.Title, value },
                    }))
                  }
                />
              </div>

              <div>
                <BaseTextarea
                  minRows={1}
                  value={form.Description.value}
                  label="Описание"
                  required={form.Description.isRequired}
                  disabled={form.Description.isDisabled}
                  placeholder="Например: В состав услуги входит установка накладной розетки, прокладка кабеля открытым способом до 10 м на скобах"
                  errorMessage={form.Description.error}
                  maxlength={form.Description.maxlength}
                  onChange={(value) =>
                    setForm((prevState) => ({
                      ...prevState,
                      Description: { ...prevState.Description, value },
                    }))
                  }
                />
              </div>

              <div>
                <NumberInput
                  value={form.Price.value}
                  label="Цена"
                  required={form.Price.isRequired}
                  disabled={form.Price.isDisabled}
                  placeholder="Укажите цену услуги"
                  errorMessage={form.Price.error}
                  max={999999999999}
                  digitsAfterPoint={2}
                  radix=","
                  thousandsSeparator=" "
                  onChange={(value) =>
                    setForm((prevState) => ({
                      ...prevState,
                      Price: { ...prevState.Price, value },
                    }))
                  }
                />
              </div>

              {isUK && (
                <>
                  <SelectAddressDefaultButton errorMessage={form.HouseIdsError} onClick={showSelectAddressSubmodal}>
                    Показывать
                    {form.HouseIds?.length === addresses?.length
                      ? " по всем адресам"
                      : form.HouseIds?.length === 1
                      ? ` по ${form.HouseIds?.length} адресу`
                      : ` по ${form.HouseIds?.length ?? 0} адресам`}
                  </SelectAddressDefaultButton>

                  {isSelectAddressSubmodalShown && (
                    <SelectAddressSubmodal
                      position={submodalPosition}
                      isOpen={isSelectAddressSubmodalShown}
                      selected={form.HouseIds ?? []}
                      disabled={isViewing}
                      onClose={() => setSelectAddressSubmodalShown(false)}
                      setSelected={(HouseIds) => setForm((prevState) => ({ ...prevState, HouseIds }))}
                    />
                  )}
                </>
              )}
            </div>
          )}
        </BaseModalContent>

        <BaseModalActions className="flex middle between">
          <div className="flex middle" style={{ gap: "4px" }}>
            <BaseSwitcher
              value={!!form.Enable.value}
              disabled={isViewing}
              onChange={(value) =>
                setForm((prevState) => ({
                  ...prevState,
                  Enable: { ...prevState.Enable, value },
                }))
              }
            >
              <span className="sf-text-regular text--primary color--text-primary">
                {form.Enable.value ? "Активная" : "Выключенная"}
              </span>
            </BaseSwitcher>
            <BaseTooltip
              title="Выключенные услуги не отображаются в мобильном приложении “Локоло”"
              placement="bottom-end"
            >
              {QuestionTooltip16Icon()}
            </BaseTooltip>
          </div>

          <div className="flex middle" style={{ gap: "8px" }}>
            {isViewing ? (
              <BaseButton onClick={onClose}>Закрыть</BaseButton>
            ) : (
              <>
                <BaseButton variant="secondary" onClick={onClose}>
                  Отменить
                </BaseButton>

                <BaseButton isLoading={form.isLoading} onClick={onSubmit}>
                  {isEditing ? "Сохранить" : "Добавить"}
                </BaseButton>
              </>
            )}
          </div>
        </BaseModalActions>
      </div>
    </BaseModal>
  );
};

export default PaidServiceItemModal;
