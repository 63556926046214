import { Dictionary } from "./dictionary";
import { HouseUsers } from "./house-users";
import { OCCV2Order } from "./occ-v2";

export namespace OccInitiatives {
  export interface ListItem {
    Id: string;
    SentAt: string;
    Author: string;
    Address: string;
    Status: StatusModel;
  }

  export enum Status {
    New = "New",
    Rejected = "Rejected",
    Expired = "Expired",
    WaitOccCreate = "WaitOccCreate",
    OccCreated = "OccCreated",
  }

  export interface StatusModel {
    Key: Status;
    Value: string;
    DaysText?: string;
    Occ?: {
      Id?: Dictionary.DictionaryItem<Dictionary.OCCStatus>["id"];
      Name?: Dictionary.DictionaryItem<Dictionary.OCCStatus>["name"];
    };
  }

  export type FullDetails = ListItem & {
    TotalArea: number;
    VotedArea: number;
    VotedPercentage: number;
    QuorumCompleted: boolean;
    AnswerFile?: Dictionary.File;
    ReplyToInitiativeEnable: boolean;
    GoToOccEnable: boolean;
    OccId: OCCV2Order.Base["Id"];
    CreateOccEnable: boolean;
  };

  export interface AcceptInitiativeBody {
    Accepted: boolean;
    AnswerFileId: number;
  }

  export interface Question {
    Id: string;
    Title: string;
    Content: string;
    Order: number;
  }

  export interface Vote {
    UserId: HouseUsers.User["UserId"];
    ObjectNumber: HouseUsers.User["ObjectNumber"];
    Fio: HouseUsers.User["Fio"];
    CanOccVote: HouseUsers.User["CanOccVote"];
    Ownership: HouseUsers.User["Ownership"];
    InRegistry: HouseUsers.User["InRegistry"];
    IsAgree: boolean;
    IsInitiator: boolean;
  }
}
