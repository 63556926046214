import { Dictionary } from "@/types/dictionary";
import { OccInitiatives } from "@/types/occ-initiatives";

export namespace OCCV2Order {
  export type Base = {
    Id: number;
    Uuid: string;
    InitiativeId: OccInitiatives.ListItem["Id"];
    StartTime: number;
    EndTime: number;
    StatusId: Dictionary.DictionaryItem<Dictionary.OCCStatus>["id"];
    TypeId: Dictionary.DictionaryItem<Dictionary.OCCType>["id"];
    StartDate: string;
    EndDate: string;
    FiasId: string;
    Number: string;
    UpdatedAt: number;
    UpdatedAtTimestamp: number;
    Address: string;
    IntramuralLocation: string;
    GisZhkhSent: GisZhkhSent;
  };

  export type Item = Base & {
    Status: Dictionary.DictionaryItem<Dictionary.OCCStatus>["name"];
    Type: Dictionary.DictionaryItem<Dictionary.OCCType>["name"];
  };

  export type ItemFull = Base & {
    Area: number | null;
    FormId: Dictionary.DictionaryItem<Dictionary.OCCForm>["id"];
    FormName: Dictionary.DictionaryItem<Dictionary.OCCForm>["name"];
    LocationId: Dictionary.DictionaryItem<Dictionary.OCCLocation>["id"];
    LocationName: Dictionary.DictionaryItem<Dictionary.OCCLocation>["name"];
    ProcedureForFamiliarizationWithMaterialsOfOCC: string;
    ProcedureForMakingWrittenDecisions: string;
    PublicationDate: string;
    PublicationTime: number;
    StatusName: Dictionary.DictionaryItem<Dictionary.OCCStatus>["name"];
    TypeName: Dictionary.DictionaryItem<Dictionary.OCCType>["name"];
    CreatedAtTimestamp: number;
    CreatedAt: string;
  };

  export type CreateBody = {
    OccId?: number;
    TypeId: Dictionary.DictionaryItem["id"];
    FormId: Dictionary.DictionaryItem["id"];
    LocationId?: Dictionary.DictionaryItem["id"];
    FiasId?: string;
    Number: string;
    ProcedureForMakingWrittenDecisions: string;
    ProcedureForFamiliarizationWithMaterialsOfOCC: string;
    StartTimestamp: number;
    EndTimestamp: number;
    PublicationTimestamp: number;
    IntramuralLocation?: string;
  };

  export enum GisZhkhStatus {
    Draft = "DRAFT",
    Error = "ERROR",
    InProcess = "IN_PROCESS",
    NotSent = "NOT_SENT",
    WaitSent = "WAIT_SENT",
    Sent = "SENT",
    WaitEndOcc = "WAIT_END_OCC",
  }

  export type GisZhkhSent = {
    Status: GisZhkhStatus;
    StatusValue?: string | null;
    Date?: string | null;
  };
}

export * from "./forms";
