import { Pagination } from "@/types";
import { useCallback, useEffect, useMemo, useState } from "react";

const useInfiniteScroll = ({
  pagination,
  loadMore,
}: { pagination?: Pagination; loadMore?: () => Promise<Pagination | null> } = {}) => {
  const [observer, setOserver] = useState<IntersectionObserver | null>(null);
  const [isIntersecting, setIntersecting] = useState<boolean>(false);

  const hasMore = useMemo(() => pagination && pagination.Index < pagination.Count, [pagination]);

  const measureRef = useCallback((node: any) => {
    if (node) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIntersecting(entry.isIntersecting);
        },
        { root: null, rootMargin: "0px", threshold: 0 }
      );

      observer.observe(node);
      setOserver(observer);
    }
  }, []);

  const fetchMore = useCallback(async () => {
    if (!loadMore) {
      return;
    }

    try {
      const meta = await loadMore();
      if (!meta) {
        throw Error();
      }

      if (observer && isIntersecting && meta.Index === meta.Count) {
        observer.disconnect();
      }
    } catch (error) {
      console.log(error);
      observer?.disconnect();
    }
  }, [isIntersecting, loadMore, observer]);

  useEffect(() => {
    if (isIntersecting && loadMore) {
      fetchMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadMore, isIntersecting]);

  return { measureRef, isIntersecting, observer, hasMore };
};

export default useInfiniteScroll;
