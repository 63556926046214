import React from "react";
import classNames from "classnames/bind";
import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

interface IBaseTableCellProps {
  children?: React.ReactNode;
  colspan?: number;
  rowspan?: number;
  className?: string;
  style?: React.CSSProperties;
  nowrap?: boolean;
}

const BaseTableCell: React.FC<IBaseTableCellProps> = ({ children, colspan, rowspan, className, style, nowrap }) => {
  return (
    <td className={cx(className, { cell: true, nowrap })} colSpan={colspan} rowSpan={rowspan} style={style}>
      {children}
    </td>
  );
};

export default BaseTableCell;
