import client from "./config";
import { OccInitiatives, OCCV2Order, ResponseAbstract, ResponseAbstractWithPagination } from "@/types";

export namespace OccInitiativesService {
  export function getList({ Status, Page }: { Status?: OccInitiatives.Status; Page?: number }) {
    return client.get<ResponseAbstractWithPagination<OccInitiatives.ListItem[]>>("/api/occ-initiatives", {
      params: { Status, page: Page },
    });
  }

  export function getById(id: OccInitiatives.ListItem["Id"]) {
    return client.get<ResponseAbstract<OccInitiatives.FullDetails>>(`/api/occ-initiatives/${id}`);
  }

  export function getCount() {
    return client.get<ResponseAbstract<number>>("/api/occ-initiatives/count");
  }

  export function accept(id: OccInitiatives.ListItem["Id"], body: OccInitiatives.AcceptInitiativeBody) {
    return client.post<ResponseAbstract<unknown>>(`/api/occ-initiatives/${id}/answer`, body);
  }

  export function getQuestions(id: OccInitiatives.ListItem["Id"], Page?: number) {
    return client.get<ResponseAbstractWithPagination<OccInitiatives.Question[]>>(
      `/api/occ-initiatives/${id}/questions`,
      {
        params: { Page },
      }
    );
  }

  export function getVotes(id: OccInitiatives.ListItem["Id"], Page?: number) {
    return client.get<ResponseAbstractWithPagination<OccInitiatives.Vote[]>>(`/api/occ-initiatives/${id}/votes`, {
      params: { Page },
    });
  }

  export function createOcc(id: OccInitiatives.ListItem["Id"]) {
    return client.get<ResponseAbstract<OCCV2Order.Base["Id"]>>(`/api/occ-initiatives/${id}/create-occ`);
  }
}
