import { useEffect, useState } from "react";
import classNames from "classnames/bind";
import { useSelector } from "react-redux";
import { PageHeader } from "lokolo-web-ui-library";
import { RootState, useAppDispatch } from "@/app/store";
import { InstitutionActions } from "@/app/store/slices/institution";
import { showSnackbar } from "@/app/store/slices/snackbar";
import MkdUsersCountImage from "@/assets/images/mkd-users-count.png";
import SntUsersCountImage from "@/assets/images/snt-users-count.jpeg";
import PrivateHouseUsersCountImage from "@/assets/images/private-house-users-count.jpeg";
import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

const InstitutionMunicipalityStatistic: React.FC = () => {
  const dispatch = useAppDispatch();
  const { statistic, isStatisticLoading } = useSelector((state: RootState) => state.institution);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  async function fetchStatistics() {
    try {
      setErrorMessage(null);
      await dispatch(InstitutionActions.getStatistics()).unwrap();
    } catch (error: any) {
      const message = error?.Data?.Message ?? "Попробуйте войти позднее, мы уже работаем над проблемой";
      console.error(error);
      if (!statistic) {
        dispatch(
          showSnackbar({
            key: "institution-statistics-error",
            title: "Данные не загрузились",
            body: message,
          })
        );
        setErrorMessage(message);
      }
    }
  }

  useEffect(() => {
    fetchStatistics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles["p-statistics"]}>
      <PageHeader title="Статистические данные" />

      <div className="horizontal-offset">
        <div className={styles["p-statistics__subtitle"]}>
          <p className="lkl-tpgrhy--headline--h3 lkl-color--text-black lkl-font--sf--bold">
            Ставьте цели,
            <br />
            <span className={styles["p-statistics__subtitle_gradient"]}>следите за прогрессом</span>
          </p>
        </div>
        <div className={cx("p-statistics__group", "p-statistics__users")}>
          <div className={styles["p-statistics__group_header"]}>
            <h2 className="lkl-tpgrhy--headline--h3 lkl-color--text-black lkl-font--sf--bold">По пользователям</h2>
          </div>
          <div className={styles["p-statistics__group_content"]}>
            <div className={cx("p-statistics__card", "p-statistics__users_count")}>
              <div>
                <h3 className="lkl-tpgrhy--headline--h4 lkl-color--text-white lkl-font--sf-text--bold">
                  Общее количество
                  <br /> пользователей
                </h3>
                <p>
                  {!isStatisticLoading && (
                    <span className="lkl-font--sf--bold">
                      {!statistic && errorMessage ? "–" : (statistic?.UsersCount ?? 0).formatRU()}
                    </span>
                  )}
                </p>
              </div>
            </div>

            <div
              className={cx("p-statistics__card", "p-statistics__card--imaged", "p-statistics__users_mkd-count")}
              style={{ backgroundImage: `url(${MkdUsersCountImage})` }}
            >
              <div>
                <h3 className="lkl-tpgrhy--headline--h4 lkl-color--text-white lkl-font--sf-text--bold">
                  С недвижимостью <br /> в многоквартирном доме
                </h3>
                <p>
                  {!isStatisticLoading && (
                    <span className="lkl-font--sf--bold">
                      {!statistic && errorMessage ? "–" : (statistic?.MkdUsersCount ?? 0).formatRU()}
                    </span>
                  )}
                </p>
              </div>
            </div>

            <div
              className={cx("p-statistics__card", "p-statistics__card--imaged", "p-statistics__users_snt-count")}
              style={{ backgroundImage: `url(${SntUsersCountImage})` }}
            >
              <div>
                <h3 className="lkl-tpgrhy--headline--h4 lkl-color--text-white lkl-font--sf-text--bold">
                  С садом и огородом
                </h3>
                <p>
                  {!isStatisticLoading && (
                    <span className="lkl-font--sf--bold">
                      {!statistic && errorMessage ? "–" : (statistic?.SntUsersCount ?? 0).formatRU()}
                    </span>
                  )}
                </p>
              </div>
            </div>

            <div
              className={cx(
                "p-statistics__card",
                "p-statistics__card--imaged",
                "p-statistics__users_private-house-count"
              )}
              style={{ backgroundImage: `url(${PrivateHouseUsersCountImage})` }}
            >
              <div>
                <h3 className="lkl-tpgrhy--headline--h4 lkl-color--text-white lkl-font--sf-text--bold">
                  С частным домом
                </h3>
                <p>
                  {!isStatisticLoading && (
                    <span className="lkl-font--sf--bold">
                      {!statistic && errorMessage ? "–" : (statistic?.PrivateHouseUsersCount ?? 0).formatRU()}
                    </span>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className={cx("p-statistics__group", "p-statistics__orgs")}>
          <div className={styles["p-statistics__group_header"]}>
            <h2 className="lkl-tpgrhy--headline--h3 lkl-color--text-black lkl-font--sf--bold">По организациям</h2>
          </div>
          <div className={styles["p-statistics__group_content"]}>
            <div className={cx("p-statistics__card", "p-statistics__orgs_uk")}>
              <div>
                <h3 className="lkl-tpgrhy--headline--h4 lkl-color--text-primary lkl-font--sf-text--bold">
                  Управляющих организаций
                  <br />с учетной записью
                </h3>
                <p>
                  {!isStatisticLoading && (
                    <span className="lkl-font--sf--bold">
                      {!statistic && errorMessage ? "–" : (statistic?.ActiveUkCount ?? 0).formatRU()}
                    </span>
                  )}
                </p>
              </div>
            </div>

            <div className={cx("p-statistics__card", "p-statistics__orgs_snt")}>
              <div>
                <h3 className="lkl-tpgrhy--headline--h4 lkl-color--text-primary lkl-font--sf-text--bold">
                  Садоводств
                  <br />с учетной записью
                </h3>
                <p>
                  {!isStatisticLoading && (
                    <span className="lkl-font--sf--bold">
                      {!statistic && errorMessage ? "–" : (statistic?.ActiveSntCount ?? 0).formatRU()}
                    </span>
                  )}
                </p>
              </div>
            </div>

            <div className={cx("p-statistics__card", "p-statistics__orgs_business")}>
              <div>
                <h3 className="lkl-tpgrhy--headline--h4 lkl-color--text-primary lkl-font--sf-text--bold">
                  Бизнес-аккаунтов
                </h3>
                <p>
                  {!isStatisticLoading && (
                    <span className="lkl-font--sf--bold">
                      {!statistic && errorMessage ? "–" : (statistic?.BusinessUsersCount ?? 0).formatRU()}
                    </span>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className={cx("p-statistics__group")}>
          <div className={styles["p-statistics__group_header"]}>
            <h2 className="lkl-tpgrhy--headline--h3 lkl-color--text-black lkl-font--sf--bold">
              Собрания собственников
            </h2>
          </div>
          <div className={styles["p-statistics__group_content"]}>
            <div className={cx("p-statistics__occ-card")}>
              <div>
                <h3 className="lkl-tpgrhy--headline--h4 lkl-color--text-white lkl-font--sf-text--bold">
                  Проведенных
                  <br />
                  собраний собственников
                </h3>
                {!isStatisticLoading && (
                  <p className="lkl-font--sf--bold">
                    {!statistic && errorMessage ? "–" : (statistic?.OccCount ?? 0).formatRU()}
                  </p>
                )}
              </div>

              <div className={styles["p-statistics__occ-card_quorum"]}>
                <h3 className="lkl-tpgrhy--headline--h4 lkl-color--text-white lkl-font--sf-text--bold">
                  *С набранным кворумом
                </h3>
                {!isStatisticLoading && (
                  <p className="lkl-font--sf--bold">
                    {!statistic && errorMessage ? "–" : (statistic?.ReachedQuorumOccCount ?? 0).formatRU()}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstitutionMunicipalityStatistic;
