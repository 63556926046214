import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { paths } from "@/consts/routes";
import { parse } from "date-fns";
import { ExternalServices } from "@/types";
import { RootState, useAppDispatch } from "@/app/store";
import { ExternalServicesActions } from "@/app/store/slices/externalServices";
import { isCompanyTypeUK } from "@/app/store/slices/user";
import { showSnackbar, SnackbarType } from "@/app/store/slices/snackbar";
import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import useEmptyBlockHeight from "@/hooks/useEmptyBlockHeight";
import { ChevronRight16Icon } from "@/consts/icons-v2";
import {
  BaseTable,
  BaseTableHead,
  BaseTableBody,
  BaseTableRow,
  BaseTableHeadCell,
  BaseTableCell,
  BaseContainer,
} from "@/components/base-table";
import { BaseDropdown, BaseDropdownMenuItem } from "@/components/base-dropdown";
import AccordionFilters, { AccordionFiltersRowItem } from "@/components/AccordionFilters";
import DatepickerInput from "@/components/base-input/datepicker";
import Spinner from "@/components/spinner";
import styles from "./styles.module.scss";

const PaidServicesOrders: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { isLoading, statuses, filters, search, list, pagination, allServices } = useSelector(
    (state: RootState) => state.externalServices.orders
  );
  const isUK = useSelector(isCompanyTypeUK);
  const addresses = useSelector((state: RootState) => state.user.company?.Addresses);

  const [filtersLocal, setFiltersLocal] = useState<typeof filters>(filters);
  const { measureRef, isIntersecting, observer, hasMore } = useInfiniteScroll({ pagination: pagination ?? undefined });
  const { emptyBlockHeight, emptyBlockRef } = useEmptyBlockHeight();

  const isFiltered: boolean = useMemo(() => {
    return (
      filters.CreatedAt != null ||
      filters.HouseId != null ||
      filters.ServiceId != null ||
      filters.Status != null ||
      search.query.length > 0
    );
  }, [filters, search]);

  function handleOrderSelect(id: ExternalServices.Order.Item["Id"]) {
    navigate(paths.externalServices.fullPath().item(id.toString()));
  }

  async function syncFilters() {
    return await setFiltersLocal(filters);
  }

  async function applyFilters() {
    await dispatch(ExternalServicesActions.Order.applyOrdersFilters(filtersLocal)).unwrap();
  }

  async function resetFilters() {
    setFiltersLocal({
      Status: null,
      ServiceId: null,
      HouseId: null,
      CreatedAt: null,
    });
    await dispatch(ExternalServicesActions.Order.resetSearchFilters()).unwrap();
  }

  async function fetch() {
    try {
      await Promise.all([
        dispatch(ExternalServicesActions.Order.getStatuses()).unwrap(),
        dispatch(ExternalServicesActions.Order.fetchOrders()).unwrap(),
        dispatch(ExternalServicesActions.Order.getAllServices()).unwrap(),
      ]);
    } catch (error: any) {
      dispatch(
        showSnackbar({
          key: "external-services-error",
          title: "Ошибка проверки поступивших заявок",
          body: error?.Data?.Message ?? "Попробуйте войти позднее",
          type: SnackbarType.ERROR,
        })
      );
    }
  }

  async function fetchMoreData() {
    try {
      const meta = await dispatch(ExternalServicesActions.Order.loadMoreOrders()).unwrap();

      if (observer && isIntersecting && meta.Index === meta.Count) {
        observer.disconnect();
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (isIntersecting) {
      fetchMoreData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIntersecting]);

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <AccordionFilters onSync={syncFilters} onReset={resetFilters} onApply={applyFilters}>
        <AccordionFiltersRowItem title="Статус">
          <BaseDropdown
            value={filtersLocal.Status}
            display={
              filtersLocal.Status === null ? "Все" : statuses.find((it) => it.Key === filtersLocal.Status)?.Value
            }
            variant="formfield"
            isSelectable={true}
            onSelect={(value) => {
              setFiltersLocal((prevState) => ({ ...prevState, Status: value }));
            }}
          >
            <BaseDropdownMenuItem value={null}>
              <span className="sf-text-regular">Все</span>
            </BaseDropdownMenuItem>
            {statuses.map((status) => (
              <BaseDropdownMenuItem key={status.Key} value={status.Key}>
                <span className="sf-text-regular">{status.Value}</span>
              </BaseDropdownMenuItem>
            ))}
          </BaseDropdown>
        </AccordionFiltersRowItem>

        <AccordionFiltersRowItem title="Услуга">
          <BaseDropdown
            value={filtersLocal.ServiceId}
            display={
              filtersLocal.ServiceId === null
                ? "Все"
                : allServices.find((it) => it.Id === filtersLocal.ServiceId)?.Title
            }
            variant="formfield"
            isSelectable={true}
            onSelect={(value) => {
              setFiltersLocal((prevState) => ({ ...prevState, ServiceId: value }));
            }}
          >
            <BaseDropdownMenuItem value={null}>
              <span className="sf-text-regular">Все</span>
            </BaseDropdownMenuItem>
            {allServices.map((service) => (
              <BaseDropdownMenuItem key={service.Id} value={service.Id}>
                <span className="sf-text-regular">{service.Title}</span>
              </BaseDropdownMenuItem>
            ))}
          </BaseDropdown>
        </AccordionFiltersRowItem>

        {isUK && (
          <AccordionFiltersRowItem title="Адрес">
            <BaseDropdown
              value={filtersLocal.HouseId}
              display={
                filtersLocal.HouseId === null ? "Все" : addresses?.find((it) => it.Id === filtersLocal.HouseId)?.Address
              }
              variant="formfield"
              isSelectable={true}
              onSelect={(value) => {
                setFiltersLocal((prevState) => ({ ...prevState, HouseId: value }));
              }}
            >
              <BaseDropdownMenuItem value={null}>
                <span className="sf-text-regular">Все</span>
              </BaseDropdownMenuItem>
              {addresses?.map((address) => (
                <BaseDropdownMenuItem key={address.Id} value={address.Id}>
                  <span className="sf-text-regular">{address.Address}</span>
                </BaseDropdownMenuItem>
              ))}
            </BaseDropdown>
          </AccordionFiltersRowItem>
        )}

        <AccordionFiltersRowItem title="Дата создания">
          <DatepickerInput
            value={filtersLocal.CreatedAt ? parse(filtersLocal.CreatedAt, "dd.MM.yyyy", new Date()) : null}
            placeholder="--.--.----"
            onChange={(value) => {
              setFiltersLocal((prevState) => ({ ...prevState, CreatedAt: value?.formatDate() }));
            }}
          />
        </AccordionFiltersRowItem>
      </AccordionFilters>

      <div>
        <BaseContainer defaultHorizontalOffset defaultTopOffset>
          <BaseTable>
            <BaseTableHead nowrap>
              <BaseTableRow>
                <BaseTableHeadCell>№ заявки</BaseTableHeadCell>
                <BaseTableHeadCell>Услуга</BaseTableHeadCell>
                <BaseTableHeadCell>Дата создания</BaseTableHeadCell>
                <BaseTableHeadCell>Адрес</BaseTableHeadCell>
                <BaseTableHeadCell>Заявитель</BaseTableHeadCell>
                <BaseTableHeadCell>Статус</BaseTableHeadCell>
                <BaseTableHeadCell></BaseTableHeadCell>
              </BaseTableRow>
            </BaseTableHead>

            {isLoading ? (
              <BaseTableBody empty refProp={emptyBlockRef}>
                <BaseTableRow>
                  <BaseTableCell colspan={7} style={{ height: `${emptyBlockHeight}px` }}>
                    <div className="flex center">
                      <Spinner size={36} />
                    </div>
                  </BaseTableCell>
                </BaseTableRow>
              </BaseTableBody>
            ) : list.length ? (
              <>
                <BaseTableBody defaultDivider>
                  {list.map((order) => (
                    <BaseTableRow key={order.Id} onClick={() => handleOrderSelect(order.Id)} className={styles.order}>
                      <BaseTableHeadCell>
                        <span className="sf-text-semibold">{order.Id}</span>
                      </BaseTableHeadCell>
                      <BaseTableHeadCell>
                        <span className="sf-text-regular">{order.Service.Title}</span>
                      </BaseTableHeadCell>
                      <BaseTableCell>
                        <span className="sf-text-regular">
                          {order.CreatedAt?.parseFromEpochSeconds()?.formatDateTime()}
                        </span>
                      </BaseTableCell>
                      <BaseTableCell>
                        <span className="sf-text-regular">{order.Address}</span>
                      </BaseTableCell>
                      <BaseTableCell>
                        <span className="sf-text-regular">{order.User.FullName}</span>
                        <br />
                        <span className="sf-text-regular color--text-secondary">
                          {order.User.Phone?.formatPhone("-")}
                        </span>
                      </BaseTableCell>
                      <BaseTableCell>
                        <span className="sf-text-regular color--text-success">{order.Status.Value}</span>
                      </BaseTableCell>
                      <BaseTableCell>
                        <span>{ChevronRight16Icon("var(--icons-primary)")}</span>
                      </BaseTableCell>
                    </BaseTableRow>
                  ))}
                </BaseTableBody>
                {hasMore && (
                  <tbody ref={measureRef}>
                    <tr>
                      <td colSpan={7}>
                        <div className="flex center">
                          <Spinner size={36} />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )}
              </>
            ) : (
              <BaseTableBody empty refProp={emptyBlockRef}>
                <BaseTableRow>
                  <BaseTableCell colspan={7} style={{ height: `${emptyBlockHeight}px` }}>
                    <div className="center" style={{ display: "flex", flexDirection: "column" }}>
                      {isFiltered ? (
                        <>
                          <h4 className="headline-h4 sf-text-bold color--text-primary">Ничего не нашлось</h4>
                          <p className="text--primary sf-pro-regular color--text-primary">
                            Возможно, стоит изменить параметры поиска или сбросить фильтр
                          </p>
                        </>
                      ) : (
                        <>
                          <h4 className="headline-h4 sf-text-bold color--text-primary">Заявок пока нет</h4>
                          <p className="text--primary sf-pro-regular color--text-primary">
                            Добавляйте более востребованные услуги, рассказывайте о них и уже в ближайшее время у вас
                            появится первая заявка :)
                          </p>
                        </>
                      )}
                    </div>
                  </BaseTableCell>
                </BaseTableRow>
              </BaseTableBody>
            )}
          </BaseTable>
        </BaseContainer>
      </div>
    </>
  );
};

export default PaidServicesOrders;
