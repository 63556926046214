import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { OCC, Profile } from "@/types";
import { paths } from "@/consts/routes";
import classNames from "classnames/bind";
import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import useEmptyBlockHeight from "@/hooks/useEmptyBlockHeight";
import { RootState, useAppDispatch } from "@/app/store";
import {
  setQuery,
  toggleIsActual,
  SntMyOccState,
  fetchMoreOccList,
  fetchOccList,
  setOccListFilter,
  setOccListSearchQuery,
} from "@/app/store/slices/snt/myOcc";
import { isCompanyTypeUK } from "@/app/store/slices/user";
import { addIcon24, chevronRight24Icon, dropdownSecondaryChevron } from "@/consts/icons";
import {
  BaseTable,
  BaseTableHead,
  BaseTableBody,
  BaseTableRow,
  BaseTableHeadCell,
  BaseTableCell,
  BaseTableToolbar,
  BaseContainer,
} from "@/components/base-table";
import PageHeader from "@/components/PageHeader";
import { BaseDropdown, BaseDropdownMenuItem } from "@/components/base-dropdown";
import BaseButton from "@/components/base-button";
import BaseQuerySearch from "@/components/base-query-search";
import OccStatus from "@/components/OCC/Status";
import OccGisZhkhStatus from "@/components/OCC/GisZhkhStatus";
import Spinner from "@/components/spinner";
import CreateOCCCheckHouseModal from "@/features/CreateOccNew/CheckHouseModal";
import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

const SntMyOCC: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { company, isGisZhkhEnabled } = useSelector((state: RootState) => state.user);
  const isUK = useSelector(isCompanyTypeUK);
  const { list, query, IsActual, isLoading, page } = useSelector((state: RootState) => state.sntMyOcc);

  const { measureRef, isIntersecting, observer, hasMore } = useInfiniteScroll({ pagination: page ?? undefined });
  const { emptyBlockHeight, emptyBlockRef } = useEmptyBlockHeight();

  const [isSelectAddressModalShown, showSelectAddressModal] = useState<boolean>(false);
  const [hoveredRow, setHoveredRow] = useState<OCC.OccOrderBase["Id"] | null>(null);
  const [clickedRow, setClickedRow] = useState<OCC.OccOrderBase["Id"] | null>(null);
  const [scrollLeft, setScrollLeft] = useState<number>(0);

  function createOcc() {
    if (company?.Type === Profile.CompanyType.SNT) {
      navigate("/snt/create-occ/new/create");
    } else if (isUK) {
      showSelectAddressModal(true);
    }
  }

  function handleOCCSelect(occId: OCC.OccOrderBase["Id"]) {
    navigate(paths.myOccById(occId).details);
  }
  function onRowHoverIn(occId: OCC.OccOrderBase["Id"]) {
    setHoveredRow(occId);
  }
  function onRowHoverOut() {
    setHoveredRow(null);
  }
  function onRowMouseDown(occId: OCC.OccOrderBase["Id"]) {
    setClickedRow(occId);
  }
  function onRowMouseUp() {
    setClickedRow(null);
  }

  function updateFilter(isActual: SntMyOccState["IsActual"]) {
    dispatch(setOccListFilter(isActual));
  }

  function handleQueryChange(value: string) {
    if (value !== query) {
      dispatch(setOccListSearchQuery(value));
    }
  }

  function onScroll(e: React.SyntheticEvent) {
    setScrollLeft((e.target as HTMLElement)?.scrollLeft);
  }

  useEffect(() => {
    dispatch(setQuery(""));
    dispatch(toggleIsActual(null));
    dispatch(fetchOccList()).unwrap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchMoreData = async () => {
    try {
      const { Index, Count } = await dispatch(fetchMoreOccList()).unwrap();
      if (observer && isIntersecting && Index === Count) {
        observer.disconnect();
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (isIntersecting) {
      fetchMoreData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIntersecting]);

  return (
    <div>
      <PageHeader title="Мои собрания">
        <div className="flex middle" style={{ gap: "16px" }}>
          <BaseButton startIcon={addIcon24()} onClick={createOcc}>
            Создать собрание
          </BaseButton>

          <div>
            <BaseQuerySearch
              style={{ width: "340px" }}
              value={query}
              onChange={handleQueryChange}
              placeholder="Поиск по номеру собрания"
            />
          </div>
        </div>
      </PageHeader>

      <BaseContainer className={styles.table}>
        <BaseTableToolbar>
          <BaseDropdown
            className={styles.filters}
            placeholder="Фильтр"
            value={IsActual}
            isSelectable={true}
            onSelect={(value) => updateFilter(value)}
            customIcon={dropdownSecondaryChevron}
          >
            <div className={styles.filters_options}>
              <BaseDropdownMenuItem value={null} defaultHover>
                <span className="sf-text-medium">Все собрания</span>
              </BaseDropdownMenuItem>
              <BaseDropdownMenuItem value={true} defaultHover>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path
                    opacity="0.3"
                    d="M8 0C9.58225 1.88681e-08 11.129 0.469191 12.4446 1.34824C13.7602 2.22729 14.7855 3.47672 15.391 4.93853C15.9965 6.40034 16.155 8.00887 15.8463 9.56072C15.5376 11.1126 14.7757 12.538 13.6569 13.6568L8 8V0Z"
                    fill="#12B212"
                  />
                  <circle cx="8" cy="8" r="7.25" stroke="#12B212" strokeWidth="1.5" />
                  <path d="M8 4V8L11 11" stroke="#12B212" strokeWidth="1.5" strokeLinecap="round" />
                </svg>
                <span className="sf-text-regular">Только актуальные</span>
              </BaseDropdownMenuItem>
              <BaseDropdownMenuItem value={false} defaultHover>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path
                    opacity="0.3"
                    d="M2.34315 13.6569C1.22433 12.538 0.4624 11.1126 0.153718 9.56072C-0.154964 8.00887 0.00346269 6.40034 0.608964 4.93853C1.21446 3.47672 2.23985 2.22729 3.55544 1.34824C4.87103 0.469192 6.41775 -1.88681e-08 8 0V8L2.34315 13.6569Z"
                    fill="#F43535"
                  />
                  <circle cx="8" cy="8" r="7.25" stroke="#F43535" strokeWidth="1.5" />
                  <path d="M8 4V8L5 11" stroke="#F43535" strokeWidth="1.5" strokeLinecap="round" />
                </svg>
                <span className="sf-text-regular">Неактуальные</span>
              </BaseDropdownMenuItem>
            </div>
          </BaseDropdown>
        </BaseTableToolbar>

        <div
          className={cx("custom-vertical-scrollbar", { table_container: true, shadowed: scrollLeft > 10 })}
          onScroll={onScroll}
        >
          <BaseTable className={styles.sticky}>
            <BaseTableHead>
              <BaseTableRow className={styles.table_row}>
                <BaseTableHeadCell>Номер собрания</BaseTableHeadCell>
                {isUK && <BaseTableHeadCell>Адрес</BaseTableHeadCell>}
                <BaseTableHeadCell>Дата начала</BaseTableHeadCell>
                <BaseTableHeadCell>Дата окончания</BaseTableHeadCell>
                <BaseTableHeadCell>Тип собрания</BaseTableHeadCell>
              </BaseTableRow>
            </BaseTableHead>

            {!isLoading && list.length > 0 && (
              <BaseTableBody defaultDivider>
                {list.map((it) => (
                  <BaseTableRow
                    className={cx({
                      table_row: true,
                      table_row_base: true,
                      hovered: hoveredRow === it.Id,
                      active: clickedRow === it.Id,
                    })}
                    key={`1-${it.Id}`}
                    onClick={() => handleOCCSelect(it.Id)}
                    onMouseEnter={() => onRowHoverIn(it.Id)}
                    onMouseLeave={() => onRowHoverOut()}
                    onMouseDown={() => onRowMouseDown(it.Id)}
                    onMouseUp={() => onRowMouseUp()}
                  >
                    <BaseTableHeadCell>
                      <span className="sf-text-semibold">{it?.Number}</span>
                    </BaseTableHeadCell>
                    {isUK && (
                      <BaseTableCell>
                        <span className="sf-text-semibold">{it?.Address}</span>
                      </BaseTableCell>
                    )}
                    <BaseTableCell>
                      <span className="sf-text-semibold">{it?.StartDate}</span>
                    </BaseTableCell>
                    <BaseTableCell>
                      <span className="sf-text-semibold">{it?.EndDate}</span>
                    </BaseTableCell>
                    <BaseTableCell>{it?.Type}</BaseTableCell>
                  </BaseTableRow>
                ))}
              </BaseTableBody>
            )}
          </BaseTable>

          <BaseTable>
            <BaseTableHead>
              <BaseTableRow className={styles.table_row}>
                <BaseTableHeadCell className={styles.table_row_status}>Статус</BaseTableHeadCell>
                {isUK && isGisZhkhEnabled && <BaseTableHeadCell>Статус отправки в ГИС ЖКХ</BaseTableHeadCell>}
                <BaseTableHeadCell></BaseTableHeadCell>
              </BaseTableRow>
            </BaseTableHead>

            {!isLoading && list.length > 0 && (
              <>
                <BaseTableBody defaultDivider>
                  {list.map((it, index) => (
                    <BaseTableRow
                      className={cx({
                        table_row: true,
                        hovered: hoveredRow === it.Id,
                        active: clickedRow === it.Id,
                      })}
                      key={`2-${it.Id}`}
                      onClick={() => handleOCCSelect(it.Id)}
                      onMouseEnter={() => onRowHoverIn(it.Id)}
                      onMouseLeave={() => onRowHoverOut()}
                      onMouseDown={() => onRowMouseDown(it.Id)}
                      onMouseUp={() => onRowMouseUp()}
                    >
                      <BaseTableCell className={styles.table_row_status}>
                        <OccStatus statusId={it?.StatusId} statusName={it.Status} />
                      </BaseTableCell>
                      {isUK && isGisZhkhEnabled && (
                        <BaseTableCell className={styles.table_row_status}>
                          <OccGisZhkhStatus statusKey={it.GisZhkhSent.Status} statusName={it.GisZhkhSent.StatusValue} />
                        </BaseTableCell>
                      )}
                      <BaseTableCell className={styles.table_row_chevron}>
                        <span className={styles.table_row_icon}>{chevronRight24Icon("var(--text-secondary)")}</span>
                      </BaseTableCell>
                    </BaseTableRow>
                  ))}
                </BaseTableBody>
              </>
            )}
          </BaseTable>
        </div>
        {isLoading ? (
          <div
            className="flex center middle"
            ref={emptyBlockRef}
            style={{ height: `${emptyBlockHeight}px`, minHeight: "350px" }}
          >
            <Spinner size={36} />
          </div>
        ) : list.length === 0 ? (
          <div
            className="flex center middle"
            ref={emptyBlockRef}
            style={{ height: `${emptyBlockHeight}px`, minHeight: "350px" }}
          >
            <div className="center">
              {query.length > 0 || IsActual !== null ? (
                <p className="text--primary sf-pro-regular color--text-primary">Поиск не дал результатов</p>
              ) : (
                <p className="text--primary sf-pro-regular color--text-primary">Собраний пока нет</p>
              )}
            </div>
          </div>
        ) : hasMore ? (
          <div className="flex center" ref={measureRef}>
            <div style={{ padding: "8px" }}>
              <Spinner size={36} />
            </div>
          </div>
        ) : null}
      </BaseContainer>

      <CreateOCCCheckHouseModal isOpen={isSelectAddressModalShown} onClose={() => showSelectAddressModal(false)} />
    </div>
  );
};

export default SntMyOCC;
