import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseThunkAPI } from "@reduxjs/toolkit/dist/createAsyncThunk";
import debounce from "lodash/debounce";
import { Institution, Pagination, Profile } from "@/types";
import { InstitutionService } from "@/services/v2";
import { RootState } from "../..";
import {
  IInstitutionState,
  setStatistic,
  setStatisticLoading,
  setCompaniesCount,
  setCompanies,
  pushCompanies,
  setCompaniesSearch,
  setCompaniesLoading,
  setCompaniesFilters,
  setCompaniesPagination,
  resetCompanies,
} from "./";
import { setInstitution } from "../user";

export namespace InstitutionActions {
  export namespace Companies {
    const debouncedHandler = debounce(setSearchebounced, 300);

    async function setSearchebounced({ dispatch }: BaseThunkAPI<unknown, unknown>) {
      await dispatch(fetchList() as any).unwrap();
    }

    export const setSearchQuery = createAsyncThunk(
      "institution/companies/set-search-query",
      async (query: string, { getState, dispatch }) => {
        const rootState = getState() as RootState;
        if (rootState.institution.companies.search === query) {
          return;
        }

        dispatch(setCompaniesSearch(query));
        debouncedHandler({ dispatch, getState } as BaseThunkAPI<unknown, unknown>);
      }
    );

    export const getCount = createAsyncThunk<number, undefined>(
      "institution/companies/get-count",
      async (payload, { dispatch, getState, rejectWithValue }) => {
        const rootState = getState() as RootState;
        if (rootState.institution.companies.count > 0) {
          return rootState.institution.companies.count;
        }

        try {
          const { data } = await InstitutionService.getCompaniesCount();
          await dispatch(setCompaniesCount(data.Data.Count));
          return data.Data.Count;
        } catch (error: any) {
          return rejectWithValue(error?.response?.data);
        }
      }
    );

    export const fetchList = createAsyncThunk<Institution.Company[], undefined>(
      "institution/companies/fetch-list",
      async (payload, { dispatch, getState, rejectWithValue }) => {
        const rootState = getState() as RootState;

        dispatch(setCompaniesLoading(true));
        dispatch(setCompanies([]));
        dispatch(setCompaniesPagination(null));

        try {
          const { data } = await InstitutionService.getCompaniesList({
            LokoloUserExists: rootState.institution.companies.filters.LokoloUserExists ?? undefined,
            Type: rootState.institution.companies.filters.Type ?? undefined,
            Value: rootState.institution.companies.search ? rootState.institution.companies.search : undefined,
          });
          await dispatch(setCompanies(data.Data));
          await dispatch(setCompaniesPagination(data.Page));
          return data.Data;
        } catch (error: any) {
          return rejectWithValue(error?.response?.data);
        } finally {
          dispatch(setCompaniesLoading(false));
        }
      }
    );

    export const loadMore = createAsyncThunk<Pagination, undefined>(
      "institution/companies/load-more",
      async (payload, { dispatch, getState, rejectWithValue }) => {
        const rootState = getState() as RootState;

        try {
          const { data } = await InstitutionService.getCompaniesList({
            LokoloUserExists: rootState.institution.companies.filters.LokoloUserExists ?? undefined,
            Type: rootState.institution.companies.filters.Type ?? undefined,
            Value: rootState.institution.companies.search ? rootState.institution.companies.search : undefined,
            Page: (rootState.institution.companies.pagination?.Index ?? 0) + 1,
          });
          await dispatch(pushCompanies(data.Data));
          await dispatch(setCompaniesPagination(data.Page));
          return data.Page;
        } catch (error: any) {
          return rejectWithValue(error?.response?.data);
        }
      }
    );

    export const applyFilters = createAsyncThunk<any, IInstitutionState["companies"]["filters"]>(
      "institution/companies/apply-filters",
      async (payload, { dispatch }) => {
        await dispatch(setCompaniesFilters(payload));

        await dispatch(fetchList()).unwrap();
      }
    );

    export const resetFilters = createAsyncThunk(
      "institution/companies/reset-filters",
      async (payload, { dispatch, getState, rejectWithValue }) => {
        const rootState = getState() as RootState;
        if (
          rootState.institution.companies.filters.LokoloUserExists === null &&
          rootState.institution.companies.filters.Type === null
        ) {
          return;
        }

        dispatch(
          applyFilters({
            LokoloUserExists: null,
            Type: null,
          })
        );
      }
    );

    export const reset = createAsyncThunk(
      "institution/companies/reset",
      async (payload, { dispatch, getState, rejectWithValue }) => {
        dispatch(resetCompanies());
      }
    );
  }

  export const fetchData = createAsyncThunk(
    "institution/fetch-data",
    async (payload, { dispatch, rejectWithValue }) => {
      try {
        const { data } = await InstitutionService.getData();
        dispatch(setInstitution(data.Data));
        if (data.Data.Type === Profile.InstitutionType.Municipality) {
          await dispatch(Companies.getCount());
        }
        return data.Data;
      } catch (error: any) {
        return rejectWithValue(error?.response?.data);
      }
    }
  );

  export const getStatistics = createAsyncThunk<Institution.Statistics, undefined>(
    "institution/get-statistics",
    async (payload, { getState, dispatch, rejectWithValue }) => {
      const rootState = getState() as RootState;
      try {
        if (!rootState.institution.statistic) {
          dispatch(setStatisticLoading(true));
        }
        const { data } = await InstitutionService.getStatistic();
        await dispatch(setStatistic(data.Data));
        return data.Data;
      } catch (error: any) {
        return rejectWithValue(error?.response?.data);
      } finally {
        dispatch(setStatisticLoading(false));
      }
    }
  );
}
