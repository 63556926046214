import client from "./config";
import { ExternalServices, House, ResponseAbstract, ResponseAbstractWithPagination } from "@/types";

export namespace ExternalServicesService {
  export namespace Service {
    export function getServicesList({ Enable, Page }: { Enable?: boolean; Page?: number }) {
      return client.get<ResponseAbstractWithPagination<ExternalServices.Service.Item[]>>("/api/external-services", {
        params: { Enable, Page },
      });
    }

    export function getCategories() {
      return client.get<ResponseAbstract<ExternalServices.Service.Category[]>>("/api/external-services/categories");
    }

    export function getServiceById(id: ExternalServices.Service.Item["Id"]) {
      return client.get<ResponseAbstract<ExternalServices.Service.FullDetails>>(`/api/external-services/${id}`);
    }

    export function createService(body: ExternalServices.Service.CreateBody) {
      return client.post<ResponseAbstract<ExternalServices.Service.FullDetails>>("/api/external-services/", body);
    }

    export function updateService(id: ExternalServices.Service.Item["Id"], body: ExternalServices.Service.CreateBody) {
      return client.post<ResponseAbstract<ExternalServices.Service.FullDetails>>(`/api/external-services/${id}`, body);
    }

    export function deleteService(id: ExternalServices.Service.Item["Id"]) {
      return client.delete<unknown>(`/api/external-services/${id}`);
    }
  }

  export namespace Order {
    export function getOrdersList({
      SearchKey,
      SearchQuery,
      Status,
      ServiceId,
      HouseId,
      CreatedAt,
      Page,
    }: {
      SearchKey?: ExternalServices.Order.SearchKey;
      SearchQuery?: string;
      Status?: ExternalServices.Order.Status;
      ServiceId?: ExternalServices.Service.Item["Id"];
      HouseId?: House.Base["Id"];
      CreatedAt?: string;
      Page?: number;
    }) {
      return client.get<ResponseAbstractWithPagination<ExternalServices.Order.Item[]>>(
        "/api/external-services/orders",
        {
          params: { SearchKey, SearchQuery, Status, ServiceId, HouseId, CreatedAt, Page },
        }
      );
    }

    export function getOrderById(id: ExternalServices.Order.Item["Id"]) {
      return client.get<ResponseAbstract<ExternalServices.Order.FullDetails>>(`/api/external-services/orders/${id}`);
    }

    export function getStatuses() {
      return client.get<ResponseAbstract<ExternalServices.Order.StatusModel[]>>(
        "/api/external-services/orders/statuses"
      );
    }

    export function changeOrderStatus(
      id: ExternalServices.Order.Item["Id"],
      body: ExternalServices.Order.SetStatusBody
    ) {
      return client.post<ResponseAbstract<unknown>>(`/api/external-services/orders/${id}/change-status`, body);
    }
  }
}
