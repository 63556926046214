import client from "./config";
import { Institution, Profile, ResponseAbstract, ResponseAbstractWithPagination } from "@/types";

export namespace InstitutionService {
  export function getData() {
    return client.get<ResponseAbstract<Profile.InstitutionData>>("/api/user/institution/get-data");
  }

  export function getStatistic() {
    return client.get<ResponseAbstract<Institution.Statistics>>("/api/user/institution/statistic");
  }

  export function getCompaniesList(params: {
    Page?: number;
    Limit?: number;
    Value?: string;
    LokoloUserExists?: boolean;
    Type?: Profile.CompanyType;
  }) {
    return client.get<ResponseAbstractWithPagination<Institution.Company[]>>("/api/user/institution/company", {
      params: { ...params, Limit: 50, Column: params.Value ? "name" : undefined },
    });
  }

  export function getCompanyDetails(companyId: Institution.Company["Id"]) {
    return client.get<ResponseAbstract<Institution.CompanyFullDetails>>(`/api/user/institution/company/${companyId}`);
  }

  export function getCompaniesCount() {
    return client.get<ResponseAbstract<{ Count: number }>>("/api/user/institution/company/count");
  }
}
