import { AnyAction, configureStore, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { injectStore } from "@/services/v2/config";

import createOcc from "./slices/createOcc";
import contacts from "./slices/contacts";
import snackbar from "./slices/snackbar";
import user from "./slices/user";
import sntRegistry from "./slices/snt/registry";
import sntMyOcc from "./slices/snt/myOcc";
import sntPassport from "./slices/snt/passport";
import sntsMap from "./slices/snt/sntsMap";
import dictionary from "./slices/dictionary";
import templates from "./slices/templates";
import documents from "./slices/documents";
import access from "./slices/access";
import houseUsers from "./slices/houseUsers";
import chats from "./slices/chats";
import externalServices from "./slices/externalServices";
import occInitiatives from "./slices/occInitiatives";
import institution from "./slices/institution";

import ukRegistry from "./slices/uk/registry";
import ukRosreestr from "./slices/uk/rosreestr";

const store = configureStore({
  reducer: {
    createOcc,
    contacts,
    snackbar,
    user,
    sntRegistry,
    sntMyOcc,
    sntPassport,
    sntsMap,
    dictionary,
    templates,
    documents,
    access,
    houseUsers,
    chats,
    externalServices,
    occInitiatives,
    institution,
    ukRegistry,
    ukRosreestr,
  },
});

injectStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;

export const useAppDispatch = () => useDispatch<AppThunkDispatch>();

export default store;
