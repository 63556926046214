import { Outlet } from "react-router-dom";
import LayoutV2 from "@/components/layout/V2";

const LayoutPage: React.FC = () => {
  return (
    <LayoutV2>
      <Outlet />
    </LayoutV2>
  );
};

export default LayoutPage;
