import { useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import classNames from "classnames/bind";
import { paths } from "@/consts/routes";
import { ExternalServices } from "@/types";
import { ExternalServicesService } from "@/services/v2";
import { RootState, useAppDispatch } from "@/app/store";
import { showSnackbar, SnackbarType } from "@/app/store/slices/snackbar";
import { ExternalServicesActions } from "@/app/store/slices/externalServices";
import { Check24Icon, Dropdown16Icon, Lockon16Icon, Subtract24Icon } from "@/consts/icons-v2";
import PageHeader from "@/components/PageHeader";
import { BaseDropdown } from "@/components/base-dropdown";
import { BaseModal, BaseModalHeader, BaseModalContent, BaseModalActions } from "@/components/BaseModal";
import BaseButton from "@/components/base-button";
import BaseTextarea from "@/components/base-textarea";
import DropdownContext from "@/components/base-dropdown/context";
import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

const PaidServicesOrderItem: React.FC = () => {
  const urlParams = useParams();
  const dispatch = useAppDispatch();

  const [order, setOrder] = useState<ExternalServices.Order.FullDetails>();
  const [form, setForm] = useState<ExternalServices.Order.SetStatusBody>();
  const [isHistoryOpen, setHistoryOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const orderId: ExternalServices.Order.Item["Id"] | null = useMemo(() => {
    return urlParams.orderId ? parseInt(urlParams.orderId) : null;
  }, [urlParams.orderId]);

  const isDisabled: boolean = useMemo(() => {
    return (
      (!!order?.Status &&
        [ExternalServices.Order.Status.Canceled, ExternalServices.Order.Status.Closed].includes(order.Status.Key)) ||
      isLoading
    );
  }, [order?.Status, isLoading]);

  const isStatusChanged: boolean = useMemo(() => {
    return order?.Status.Key !== form?.Key;
  }, [order?.Status, form]);

  async function setOrderStatus(Key: ExternalServices.Order.Status) {
    setForm((prevState) => ({ ...prevState, Key }));
  }

  async function changeOrderStatus() {
    if (!order || !form) {
      return;
    }

    try {
      setIsLoading(true);
      await dispatch(ExternalServicesActions.Order.changeOrderStatus({ orderId: order.Id, body: form })).unwrap();
      await fetch();
    } catch (error: any) {
      dispatch(
        showSnackbar({
          key: "change-external-service-order-status",
          body: error?.Data?.Message ?? "Не удалось сохранить изменения. Попробуйте позже",
          type: SnackbarType.ERROR,
        })
      );
    } finally {
      setIsLoading(false);
    }
  }

  async function fetch() {
    if (!orderId) {
      throw new Error("");
    }

    try {
      await dispatch(ExternalServicesActions.Order.getStatuses()).unwrap();
      const { data } = await ExternalServicesService.Order.getOrderById(orderId);
      setOrder(data.Data);
    } catch (error: any) {
      dispatch(
        showSnackbar({
          key: "get-external-service-order",
          title: "Что-то пошло не так",
          body: error?.Data?.Message ?? "Попробуйте войти позднее",
          type: SnackbarType.ERROR,
        })
      );
    }
  }

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  useEffect(() => {
    if (order?.Status.Key) {
      setOrderStatus(order.Status.Key);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  return (
    <div className={styles["p-order-item"]}>
      <PageHeader
        title={
          <>
            <span className="color--text-white">Заявка №{orderId ?? ""}</span>{" "}
            {order && (
              <span className="color--text-secondary">
                от {order?.CreatedAt?.parseFromEpochSeconds()?.formatDateTime()}
              </span>
            )}
          </>
        }
        defaultLink={paths.externalServices.fullPath().orders}
      />

      {order && (
        <div className={styles["p-order-item__content"]}>
          <div className={styles["p-order-item__card"]}>
            <div>
              <h3 className="headline-h3 sf-bold color--text-primary">Подробности</h3>
            </div>
            <div className={styles["p-order-item__card_content"]}>
              <div>
                <h4 className="headline-h4 sf-text-bold color--text-primary">Заявитель:</h4>
                <p className="text--primary sf-text-regular color--text-primary">{order.User.FullName}</p>
              </div>
              <div>
                <h4 className="headline-h4 sf-text-bold color--text-primary">Услуга:</h4>
                <p className="text--primary sf-text-regular color--text-primary">{order.Service.Title}</p>
              </div>
              <div>
                <h4 className="headline-h4 sf-text-bold color--text-primary">Описание проблемы:</h4>
                <p className="text--primary sf-text-regular color--text-primary">{order.Description}</p>
              </div>
              <div>
                <h4 className="headline-h4 sf-text-bold color--text-primary">Адрес:</h4>
                <p className="text--primary sf-text-regular color--text-primary">{order.Address}</p>
              </div>
              <div>
                <h4 className="headline-h4 sf-text-bold color--text-primary">Телефон:</h4>
                <p className="text--primary sf-text-regular color--text-primary">
                  {order.User.Phone?.formatPhone("-")}
                </p>
              </div>
            </div>
          </div>

          <div className={styles["p-order-item__card"]}>
            <div>
              <h3 className="headline-h3 sf-bold color--text-primary">Здесь можно менять статус заявки</h3>
            </div>
            <div className={styles["p-order-item__card_status"]}>
              <BaseDropdown
                value={form?.Key}
                label="Статус"
                onSelect={setOrderStatus}
                disabled={isDisabled}
                customToggler={<OrderStatusDropdownToggler status={form?.Key} isDisabled={isDisabled} />}
              >
                <OrderStatusDropdownMenu status={form?.Key} />
              </BaseDropdown>
            </div>
            <div>
              <BaseTextarea
                minRows={1}
                disabled={!isStatusChanged}
                value={form?.Comment ?? ""}
                label="Комментарий"
                placeholder="Виден только вам"
                onChange={(value) =>
                  setForm((prevState) => ({ ...prevState, Comment: value } as ExternalServices.Order.SetStatusBody))
                }
              />
            </div>
            <div>
              <div className={styles["p-order-item__form-submit"]}>
                <BaseButton disabled={!isStatusChanged} isLoading={isLoading} onClick={changeOrderStatus}>
                  Сохранить
                </BaseButton>

                <BaseButton variant="secondary" onClick={() => setHistoryOpen(true)}>
                  История изменений
                </BaseButton>
              </div>
            </div>
          </div>
        </div>
      )}

      <BaseModal isOpen={isHistoryOpen} minWidth="700px" maxWidth="700px">
        <BaseModalHeader title={`История изменений статуса заявки №${orderId}`} onClose={() => setHistoryOpen(false)} />

        <BaseModalContent>
          <div className={styles["p-order-item__status-history"]}>
            {order?.StatusHistory.map((change, i) => (
              <div key={change.Id}>
                <OrderStatusChange statusChange={change} step={i + 1} isLast={i === order?.StatusHistory.length - 1} />
              </div>
            ))}
          </div>
        </BaseModalContent>

        <BaseModalActions>
          <BaseButton onClick={() => setHistoryOpen(false)}>Закрыть окно</BaseButton>
        </BaseModalActions>
      </BaseModal>
    </div>
  );
};

export default PaidServicesOrderItem;

const OrderStatusChange: React.FC<{
  statusChange: ExternalServices.Order.StatusChange;
  step: number;
  isLast?: boolean;
}> = ({ statusChange, step, isLast = false }) => {
  return (
    <div className={styles["b-order-status-change"]}>
      <div className={cx("b-order-status-change__step", { "b-order-status-change__step--last": isLast })}>
        {statusChange.Key === ExternalServices.Order.Status.Closed ? (
          <div className={styles["b-order-status-change__step_closed"]}>{Check24Icon()}</div>
        ) : statusChange.Key === ExternalServices.Order.Status.Canceled ? (
          <div className={styles["b-order-status-change__step_canceled"]}>{Subtract24Icon()}</div>
        ) : (
          <div className={styles["b-order-status-change__step_number"]}>
            <span className="color--primary headline-h4 sf-text-bold">{step}</span>
          </div>
        )}
      </div>

      <div className={styles["b-order-status-change__content"]}>
        <div className={styles["b-order-status-change__content_header"]}>
          <h4 className="headline-h4 sf-text-bold color--text-primary">{statusChange.Value}</h4>
          <span className="sf-text-regular text--primary color--text-primary">
            {statusChange.CreatedAt.parseFromEpochSeconds().formatDateTime()}
          </span>
        </div>
        <div className={styles["b-order-status-change__content_adds"]}>
          <div className="sf-text-regular text--primary">
            <span className="color--text-secondary">Комментарий:</span>
            <p className="color--text-primary">{statusChange.Comment ?? "–"}</p>
          </div>

          {statusChange.Author && (
            <div className="sf-text-regular text--primary">
              <span className="color--text-secondary">Автор:</span>
              <p className="color--text-primary">{statusChange.Author.FullName}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const OrderStatusDropdownToggler: React.FC<{
  status?: ExternalServices.Order.Status;
  isDisabled: boolean;
}> = ({ status, isDisabled }) => {
  const statuses = useSelector((state: RootState) => state.externalServices.orders.statuses);
  const formatted: { color: string; value: string } = useMemo(() => {
    const value = statuses.find((it) => it.Key === status)?.Value ?? status ?? "";
    switch (status) {
      case ExternalServices.Order.Status.New:
        return { color: "#000000", value };

      case ExternalServices.Order.Status.InWork:
        return { color: "#0041E5", value };

      case ExternalServices.Order.Status.OnMyWay:
        return { color: "#7E858E", value };

      case ExternalServices.Order.Status.Canceled:
        return { color: "#F20000", value };

      case ExternalServices.Order.Status.Closed:
        return { color: "#10C44C", value };

      case ExternalServices.Order.Status.Delayed:
        return { color: "#FC9F1C", value };

      case ExternalServices.Order.Status.Restored:
        return { color: "#583CF1", value };

      case ExternalServices.Order.Status.Scheduled:
        return { color: "#18A2F2", value };

      default:
        return { color: "#000000", value };
    }
  }, [status, statuses]);

  return (
    <div className={cx("b-order-status-select__toggler")} style={{ backgroundColor: formatted.color }}>
      <span className="text--primary sf-text-medium color--text-white">{formatted.value}</span>
      {isDisabled ? Lockon16Icon("var(--icons-white)") : Dropdown16Icon("var(--icons-white)")}
    </div>
  );
};

const OrderStatusDropdownMenu: React.FC<{
  status?: ExternalServices.Order.Status;
}> = ({ status }) => {
  const context = useContext(DropdownContext);
  const statuses = useSelector((state: RootState) => state.externalServices.orders.statuses);

  const [selected, setSelected] = useState<ExternalServices.Order.Status>();

  function handleStatusChange() {
    context?.onSelect(selected);
  }

  useEffect(() => {
    setSelected(status ?? undefined);
  }, [status, context?.isOpen]);

  return (
    <div className={styles["b-order-status-select__menu"]}>
      {statuses.map((it) => (
        <div
          className={cx("b-order-status-select__menu_item", {
            "b-order-status-select__menu_item--selected": selected === it.Key,
          })}
          key={it.Key}
          onClick={() => setSelected(it.Key)}
        >
          <span className="text--primary sf-text-medium color--text-primary" role="button">
            {it.Value}
          </span>
        </div>
      ))}
      <BaseButton size="medium" onClick={handleStatusChange}>
        Применить
      </BaseButton>
    </div>
  );
};
