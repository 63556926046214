import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ExternalServices, Pagination } from "@/types";

export interface IExternalServicesState {
  services: {
    isLoading: boolean;
    list: ExternalServices.Service.Item[];
    pagination: Pagination | null;
    filters: {
      Enable: boolean | null;
    };
    categories: ExternalServices.Service.Category[];
    isCreateModalShown: boolean;
  };

  orders: {
    isLoading: boolean;
    list: ExternalServices.Order.Item[];
    pagination: Pagination | null;
    filters: {
      Status: ExternalServices.Order.Status | null;
      ServiceId: number | null;
      HouseId: number | null;
      CreatedAt: string | null;
    };
    search: {
      key: ExternalServices.Order.SearchKey;
      query: string;
    };
    statuses: ExternalServices.Order.StatusModel[];
    allServices: ExternalServices.Service.Item[];
  };
}

const externalServicesSlice = createSlice({
  name: "external-services",

  initialState: {
    services: {
      isLoading: false,
      list: [],
      pagination: null,
      filters: {
        Enable: null,
      },
      categories: [],
      isCreateModalShown: false,
    },
    orders: {
      isLoading: false,
      list: [],
      pagination: null,
      filters: {
        Status: null,
        ServiceId: null,
        HouseId: null,
        CreatedAt: null,
      },
      search: {
        key: ExternalServices.Order.SearchKey.Id,
        query: "",
      },
      statuses: [],
      allServices: [],
    },
  } as IExternalServicesState,

  reducers: {
    toggleServicesLoading: (state, action: PayloadAction<IExternalServicesState["services"]["isLoading"]>) => {
      state.services.isLoading = action.payload;
    },
    toggleOrdersLoading: (state, action: PayloadAction<IExternalServicesState["orders"]["isLoading"]>) => {
      state.orders.isLoading = action.payload;
    },

    setServices: (state, action: PayloadAction<IExternalServicesState["services"]["list"]>) => {
      state.services.list = action.payload;
    },
    pushServices: (state, action: PayloadAction<IExternalServicesState["services"]["list"]>) => {
      state.services.list.push(...action.payload);
    },

    setServicesOrders: (state, action: PayloadAction<IExternalServicesState["orders"]["list"]>) => {
      state.orders.list = action.payload;
    },
    pushServicesOrders: (state, action: PayloadAction<IExternalServicesState["orders"]["list"]>) => {
      state.orders.list.push(...action.payload);
    },

    onAddService: (state, action: PayloadAction<ExternalServices.Service.Item>) => {
      state.services.list.unshift(action.payload);
    },
    onEditService: (state, action: PayloadAction<ExternalServices.Service.Item>) => {
      const updated = state.services.list.map((it) => (it.Id === action.payload.Id ? action.payload : it));
      state.services.list = updated;
    },
    onDeleteService: (state, action: PayloadAction<ExternalServices.Service.Item["Id"]>) => {
      const index = state.services.list.findIndex((it) => it.Id === action.payload);
      if (index >= 0) {
        state.services.list.splice(index, 1);
      }
    },

    onOrderStatusChange: (state, action: PayloadAction<ExternalServices.Order.Item>) => {
      const updated = state.orders.list.map((it) => (it.Id === action.payload.Id ? action.payload : it));
      state.orders.list = updated;
    },

    setServicesFilters: (state, action: PayloadAction<IExternalServicesState["services"]["filters"]>) => {
      state.services.filters = action.payload;
    },
    setOrdersFilters: (state, action: PayloadAction<IExternalServicesState["orders"]["filters"]>) => {
      state.orders.filters = action.payload;
    },

    setServicesPagination: (state, action: PayloadAction<IExternalServicesState["services"]["pagination"]>) => {
      state.services.pagination = action.payload;
    },
    setOrdersPagination: (state, action: PayloadAction<IExternalServicesState["orders"]["pagination"]>) => {
      state.orders.pagination = action.payload;
    },

    setOrdersSearchQuery: (state, action: PayloadAction<IExternalServicesState["orders"]["search"]["query"]>) => {
      state.orders.search.query = action.payload;
    },
    setOrdersSearchKey: (state, action: PayloadAction<IExternalServicesState["orders"]["search"]["key"]>) => {
      state.orders.search.key = action.payload;
    },

    setServicesCategories: (state, action: PayloadAction<IExternalServicesState["services"]["categories"]>) => {
      state.services.categories = action.payload;
    },
    setOrdersStatuses: (state, action: PayloadAction<IExternalServicesState["orders"]["statuses"]>) => {
      state.orders.statuses = action.payload;
    },
    setAllServices: (state, action: PayloadAction<IExternalServicesState["orders"]["allServices"]>) => {
      state.orders.allServices = action.payload;
    },

    toggleCreateServiceModal: (
      state,
      action: PayloadAction<IExternalServicesState["services"]["isCreateModalShown"]>
    ) => {
      state.services.isCreateModalShown = action.payload;
    },
  },
});

export default externalServicesSlice.reducer;
export * from "./actions";
export const {
  toggleServicesLoading,
  toggleOrdersLoading,
  setServices,
  pushServices,
  setServicesOrders,
  pushServicesOrders,
  onAddService,
  onEditService,
  onDeleteService,
  onOrderStatusChange,
  setServicesFilters,
  setOrdersFilters,
  setServicesPagination,
  setOrdersPagination,
  setOrdersSearchQuery,
  setOrdersSearchKey,
  setServicesCategories,
  setOrdersStatuses,
  setAllServices,
  toggleCreateServiceModal,
} = externalServicesSlice.actions;
