import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/app/store";
import { OccInitiatives } from "@/types";

interface IOccInitiativeStatusProps {
  status: OccInitiatives.Status;
  model?: OccInitiatives.StatusModel;
  className?: string;
}

const OccInitiativeStatus: React.FC<IOccInitiativeStatusProps> = ({ status, model, className }) => {
  const statuses = useSelector((state: RootState) => state.occInitiatives.statuses);

  const statusColor: string = useMemo(() => {
    switch (status) {
      case OccInitiatives.Status.New:
        return "var(--text-link)";

      case OccInitiatives.Status.Rejected:
        return "var(--text-error)";

      case OccInitiatives.Status.Expired:
        return "var(--text-warning)";

      case OccInitiatives.Status.WaitOccCreate:
        return "var(--text-success)";

      case OccInitiatives.Status.OccCreated:
        return "var(--text-success)";

      default:
        return "var(--text-primary)";
    }
  }, [status]);

  const statusText: string = useMemo(() => {
    if (model?.Value) {
      return model?.Value;
    }

    return statuses.find((it) => it.Key === status)?.Value ?? status;
  }, [status, statuses, model]);

  return (
    <span className={className} style={{ color: statusColor }}>
      {statusText}
    </span>
  );
};

export default OccInitiativeStatus;
