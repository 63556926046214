import React from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

interface IMenuItemProps {
  text: string;
  path: string;
  icon?: JSX.Element;
  children?: JSX.Element | JSX.Element[] | React.ReactNode;
}

const MenuItem: React.FC<IMenuItemProps> = ({ text, path, icon, children }) => {
  return (
    <div className={styles["b-menu-item"]}>
      <NavLink className={({ isActive }) => cx("b-menu-item__btn", { "b-menu-item__btn--active": isActive })} to={path}>
        <div>
          <div className={styles["b-menu-item__btn_icon"]}>{icon}</div>
          <span className="lkl-font--sf-text--medium lkl-tpgrhy--text--primary lkl-color--text-primary">{text}</span>
        </div>

        {children}
      </NavLink>
    </div>
  );
};

export default MenuItem;
