import { useCallback, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import useEmptyBlockHeight from "@/hooks/useEmptyBlockHeight";
import { RootState, useAppDispatch } from "@/app/store";
import { showSnackbar, SnackbarType } from "@/app/store/slices/snackbar";
import { OccInitiativeActions } from "@/app/store/slices/occInitiatives";
import {
  BaseTable,
  BaseTableHead,
  BaseTableBody,
  BaseTableRow,
  BaseTableHeadCell,
  BaseTableCell,
  BaseContainer,
} from "@/components/base-table";
import { ShowMore, type ShowMoreRef, type ShowMoreToggleLinesFn } from "@re-dev/react-truncate";
import Spinner from "@/components/spinner";
import styles from "./styles.module.scss";

const UKOccInitiativeItemQuestions: React.FC = () => {
  const dispatch = useAppDispatch();

  const initiative = useSelector((state: RootState) => state.occInitiatives.selected.details);
  const { isLoading, list, pagination } = useSelector((state: RootState) => state.occInitiatives.selected.questions);

  const { measureRef, hasMore } = useInfiniteScroll({
    pagination: pagination ?? undefined,
    loadMore,
  });
  const { emptyBlockHeight, emptyBlockRef } = useEmptyBlockHeight();

  async function loadMore() {
    try {
      if (!initiative?.Id) {
        throw new Error("");
      }

      const page = await dispatch(OccInitiativeActions.loadMoreInitiativeQuestions(initiative.Id)).unwrap();
      return page;
    } catch (error: any) {
      console.log(error);
      return null;
    }
  }

  async function fetch() {
    if (!initiative?.Id) {
      throw new Error("");
    }

    try {
      await dispatch(OccInitiativeActions.getInitiativeQuestions(initiative.Id)).unwrap();
    } catch (error: any) {
      dispatch(
        showSnackbar({
          key: "occ-initiative-questions-error",
          title: "Что-то пошло не так.",
          body: error?.Data?.Message ?? "Попробуйте войти позднее",

          type: SnackbarType.ERROR,
        })
      );
    }
  }

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <BaseContainer defaultHorizontalOffset defaultTopOffset className={styles.table}>
        <div className={`custom-vertical-scrollbar ${styles.table_container}`}>
          <BaseTable>
            <BaseTableHead nowrap>
              <BaseTableRow className={styles.table_head_row}>
                <BaseTableHeadCell>№ вопроса</BaseTableHeadCell>
                <BaseTableHeadCell>Заголовок вопроса</BaseTableHeadCell>
                <BaseTableHeadCell>Текст вопроса</BaseTableHeadCell>
              </BaseTableRow>
            </BaseTableHead>

            {isLoading ? (
              <BaseTableBody empty refProp={emptyBlockRef}>
                <BaseTableRow>
                  <BaseTableCell colspan={3} style={{ height: `${emptyBlockHeight}px` }}>
                    <div className="flex center">
                      <Spinner size={36} />
                    </div>
                  </BaseTableCell>
                </BaseTableRow>
              </BaseTableBody>
            ) : list.length ? (
              <>
                <BaseTableBody defaultDivider>
                  {list.map((initiative) => (
                    <BaseTableRow className={styles["b-occ-nitiative-question__row"]} key={initiative.Id}>
                      <BaseTableHeadCell>
                        <span className="sf-text-semibold">{initiative.Order + 1}</span>
                      </BaseTableHeadCell>
                      <BaseTableCell>
                        <ExpandText>
                          <span className="sf-text-regular">{initiative.Title}</span>
                        </ExpandText>
                      </BaseTableCell>
                      <BaseTableCell>
                        <ExpandText>
                          <span className="sf-text-regular">{initiative.Content}</span>
                        </ExpandText>
                      </BaseTableCell>
                    </BaseTableRow>
                  ))}
                </BaseTableBody>
                {hasMore && (
                  <tbody ref={measureRef}>
                    <tr>
                      <td colSpan={3}>
                        <div className="flex center">
                          <Spinner size={36} />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )}
              </>
            ) : (
              <BaseTableBody empty refProp={emptyBlockRef}>
                <BaseTableRow>
                  <BaseTableCell colspan={3} style={{ height: `${emptyBlockHeight}px` }}>
                    <div>
                      <div className="center">
                        <p className="text--primary sf-pro-regular color--text-primary">Ничего не нашлось</p>
                      </div>
                    </div>
                  </BaseTableCell>
                </BaseTableRow>
              </BaseTableBody>
            )}
          </BaseTable>
        </div>
      </BaseContainer>
    </>
  );
};

export default UKOccInitiativeItemQuestions;

interface IExpandTextProps {
  children: JSX.Element | JSX.Element[] | React.ReactNode;
}
const ExpandText: React.FC<IExpandTextProps> = ({ children }) => {
  const ref = useRef<ShowMoreRef>(null);

  const toggleLines = useCallback<ShowMoreToggleLinesFn>((e) => {
    ref.current?.toggleLines(e);
  }, []);

  return (
    <ShowMore
      ref={ref}
      lines={4}
      more={
        <span className={styles["b-occ-initiative-question-collapse"]} onClick={toggleLines} role="button">
          {" "}
          еще...
        </span>
      }
      less={
        <span className={styles["b-occ-initiative-question-collapse"]} onClick={toggleLines} role="button">
          {" "}
          свернуть...
        </span>
      }
    >
      {children}
    </ShowMore>
  );
};
