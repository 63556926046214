import { useSelector } from "react-redux";
import classNames from "classnames/bind";
import { paths } from "@/consts/routes";
import { RootState } from "@/app/store";
import { Check16Icon, Stop16Icon, Lightbulb24Icon } from "@/consts/icons-v2";
import BaseHeadlineAlert from "@/components/BaseHeadlineAlert";
import PageHeader from "@/components/PageHeader";
import BaseButton from "@/components/base-button";
import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

const UKGisZhkh: React.FC = () => {
  const { company } = useSelector((state: RootState) => state.user);

  return (
    <>
      <PageHeader title="Интеграция с ГИС ЖКХ" defaultLink={paths.settings}>
        <div>
          <a href="/documents/Предоставление_прав_доступа_в_ГИС_ЖКХ.pdf" download>
            <BaseButton variant="secondary" appearance="neutral" endIcon={instructionsIcon}>
              Инструкция по предоставлению прав доступа
            </BaseButton>
          </a>
        </div>
      </PageHeader>

      <div>
        <BaseHeadlineAlert
          name="giszhkh"
          title="Что это такое?"
          verticalMargin="0"
          icon={Lightbulb24Icon()}
          background="linear-gradient(90deg, #DFE8FF 0%, #E3FFE0 100%)"
        >
          <p className="text--primary">
            При предоставлении прав доступа мы сможем автоматически отправлять итоги собраний в ГИС ЖКХ. Права доступа
            можно предоставить на время. При получении прав доступа мы не вмешиваемся в вашу работу, не меняем и не
            удаляем данные
          </p>
        </BaseHeadlineAlert>
      </div>

      <div
        className={cx("b-giszhkh-status", {
          "b-giszhkh-status--positive": company?.GisZhkh?.HasAccess,
          "b-giszhkh-status--negative": !company?.GisZhkh?.HasAccess,
        })}
      >
        <div className={styles["b-giszhkh-status__text"]}>
          <p className="caption--middle color--text-primary sf-text-regular">Актуальный статус:</p>
          <div className="text--primary sf-text-medium">
            {company?.GisZhkh?.HasAccess ? (
              <>
                {Check16Icon("currentColor")}
                <span>Есть доступ к ГИС ЖКХ</span>
              </>
            ) : (
              <>
                {Stop16Icon("currentColor")}
                <span>Нет доступа к ГИС ЖКХ</span>
              </>
            )}
          </div>
        </div>
        {!company?.GisZhkh?.HasAccess && (
          <p className="color--text-primary sf-text-regular caption--small center">
            Если вы предоставили доступ по инструкции, но статус не поменялся в течение одного рабочего дня, обратитесь
            в техническую поддержку
          </p>
        )}
      </div>
    </>
  );
};

export default UKGisZhkh;

const instructionsIcon = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 7C8.44771 7 8 7.44772 8 8C8 8.55229 8.44771 9 9 9H15C15.5523 9 16 8.55229 16 8C16 7.44772 15.5523 7 15 7H9Z"
      fill="var(--icons-primary)"
    />
    <path
      d="M8 12C8 11.4477 8.44771 11 9 11H13C13.5523 11 14 11.4477 14 12C14 12.5523 13.5523 13 13 13H9C8.44771 13 8 12.5523 8 12Z"
      fill="var(--icons-primary)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 17V5.16144C20 4.6343 20 4.17954 19.9694 3.80497C19.9371 3.40963 19.8658 3.01641 19.673 2.63803C19.3854 2.07354 18.9265 1.6146 18.362 1.32698C17.9836 1.13419 17.5904 1.06287 17.195 1.03057C16.8205 0.999969 16.3657 0.999984 15.8386 1H3C1.34315 1 0 2.34315 0 4C0 5.65686 1.34315 7 3 7H4V20C4 21.6569 5.34315 23 7 23L19.8385 23C20.3657 23 20.8205 23 21.195 22.9694C21.5904 22.9371 21.9836 22.8658 22.362 22.673C22.9265 22.3854 23.3854 21.9265 23.673 21.362C23.8658 20.9836 23.9371 20.5904 23.9694 20.195C24 19.8205 24 19.3657 24 18.8386V18C24 17.4477 23.5523 17 23 17H20ZM17.0322 3.02393C16.7488 3.00078 16.3766 3 15.8 3H5.82929C5.93294 3.29323 5.99228 3.60739 5.9993 3.93443C5.99977 3.95623 6 3.97809 6 4V20C6 20.5523 6.44772 21 7 21C7.55228 21 8 20.5523 8 20V18C8 17.4477 8.44772 17 9 17H18V5.2C18 4.62345 17.9992 4.25118 17.9761 3.96784C17.9539 3.69618 17.9162 3.59546 17.891 3.54601C17.7951 3.35785 17.6422 3.20487 17.454 3.109C17.4045 3.0838 17.3038 3.04613 17.0322 3.02393ZM9.82929 21H19.8C20.3766 21 20.7488 20.9992 21.0322 20.9761C21.3038 20.9539 21.4045 20.9162 21.454 20.891C21.6422 20.7951 21.7951 20.6422 21.891 20.454C21.9162 20.4045 21.9539 20.3038 21.9761 20.0322C21.9964 19.783 21.9995 19.4651 21.9999 19H10V20C10 20.3365 9.94458 20.6602 9.84237 20.9622C9.8381 20.9748 9.83374 20.9874 9.82929 21ZM4 4V5H3C2.44772 5 2 4.55229 2 4C2 3.44772 2.44772 3 3 3C3.55228 3 4 3.44772 4 4Z"
      fill="var(--icons-primary)"
    />
  </svg>
);
