import { createAsyncThunk } from "@reduxjs/toolkit";
import { DictionariesService } from "@/services/v2";
import { Dictionary, OCCV2Order, Profile } from "@/types";
import { RootState } from "../..";
import {
  setStatusesList,
  setStatusesLoading,
  setTypesList,
  setTypesLoading,
  setFormsList,
  setFormsLoading,
  setLocationsList,
  setLocationsLoading,
  setQuorumsList,
  setQuorumsLoading,
  setApartmentTypeLoading,
  setApartmentTypeList,
  setOwnershipLoading,
  setOwnershipList,
  setGisZhkhSentStatusesLoading,
  setGisZhkhSentStatusesList,
} from "./index";
import { getStatuses } from "../houseUsers";
import { fetchAccessRoles } from "../access/actions";

enum StorageKeys {
  Statuses = "occ-statuses",
  Types = "occ-types",
  Forms = "occ-forms",
  Locations = "occ-locations",
  Quorums = "quorums",
  ApartmentType = "apartment-type",
  Ownership = "apartment-ownership",
}

export const fetchOCCStatuses = createAsyncThunk(
  "dictionary/fetch-statuses",
  async (payload: undefined, { dispatch, rejectWithValue, getState }) => {
    const rootState = getState() as RootState;
    if (rootState.dictionary.statuses.list.length > 0) {
      return rootState.dictionary.statuses.list;
    }

    dispatch(setStatusesLoading(true));
    try {
      const { data } = await DictionariesService.getOCCStatus();
      dispatch(setStatusesList(data.Data));
      return data.Data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.Data?.Message);
    } finally {
      dispatch(setStatusesLoading(false));
    }
  }
);

export const fetchOCCTypes = createAsyncThunk(
  "dictionary/fetch-types",
  async (payload: undefined, { dispatch, rejectWithValue, getState }) => {
    const rootState = getState() as RootState;
    if (rootState.dictionary.types.list.length > 0) {
      return rootState.dictionary.types.list;
    }

    dispatch(setTypesLoading(true));
    try {
      const { data } = await DictionariesService.getOCCType();
      dispatch(setTypesList(data.Data));
      return data.Data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.Data?.Message);
    } finally {
      dispatch(setTypesLoading(false));
    }
  }
);

export const fetchOCCForms = createAsyncThunk(
  "dictionary/fetch-forms",
  async (payload: undefined, { dispatch, rejectWithValue, getState }) => {
    const rootState = getState() as RootState;
    if (rootState.dictionary.forms.list.length > 0) {
      return rootState.dictionary.forms.list;
    }

    dispatch(setFormsLoading(true));
    try {
      const { data } = await DictionariesService.getOCCForm();
      dispatch(setFormsList(data.Data));
      return data.Data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.Data?.Message);
    } finally {
      dispatch(setFormsLoading(false));
    }
  }
);

export const fetchOCCLocation = createAsyncThunk(
  "dictionary/fetch-location",
  async (payload: undefined, { dispatch, rejectWithValue, getState }) => {
    const rootState = getState() as RootState;
    if (rootState.dictionary.locations.list.length > 0) {
      return rootState.dictionary.locations.list;
    }

    dispatch(setLocationsLoading(true));
    try {
      const { data } = await DictionariesService.getOCCLocation();
      dispatch(setLocationsList(data.Data));
      return data.Data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.Data?.Message);
    } finally {
      dispatch(setLocationsLoading(false));
    }
  }
);

export const fetchQuorums = createAsyncThunk(
  "dictionary/fetch-quorums",
  async (payload: undefined, { dispatch, rejectWithValue, getState }) => {
    const rootState = getState() as RootState;
    if (rootState.dictionary.quorums.list.length > 0) {
      return rootState.dictionary.quorums.list;
    }

    dispatch(setQuorumsLoading(true));
    try {
      const { data } = await DictionariesService.getQuorum();
      dispatch(setQuorumsList(data.Data));
      return data.Data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.Data?.Message);
    } finally {
      dispatch(setQuorumsLoading(false));
    }
  }
);

export const fetchApartmentTypes = createAsyncThunk(
  "dictionary/fetch-apartment-type",
  async (payload: undefined, { dispatch, rejectWithValue, getState }) => {
    const rootState = getState() as RootState;

    if (
      rootState.user?.profile?.role !== Profile.UserRole.Company ||
      rootState.user?.profile?.company_type === Profile.CompanyType.SNT
    ) {
      return [];
    }

    if (rootState.dictionary.apartmentType.list.length > 0) {
      return rootState.dictionary.apartmentType.list;
    }

    dispatch(setApartmentTypeLoading(true));
    try {
      const { data } = await DictionariesService.getApartmentTypes();
      dispatch(setApartmentTypeList(data.Data));
      return data.Data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.Data?.Message);
    } finally {
      dispatch(setApartmentTypeLoading(false));
    }
  }
);

export const fetchApartmentOwnerships = createAsyncThunk<
  Dictionary.ApartmentDictionaryItem<Dictionary.ApartmentOwnership>[],
  undefined
>("dictionary/fetch-apartment-ownerships", async (payload: undefined, { dispatch, rejectWithValue, getState }) => {
  const rootState = getState() as RootState;

  if (
    rootState.user?.profile?.role !== Profile.UserRole.Company ||
    rootState.user?.profile?.company_type === Profile.CompanyType.SNT
  ) {
    return [];
  }

  if (rootState.dictionary.ownership.list.length > 0) {
    return rootState.dictionary.ownership.list;
  }

  dispatch(setOwnershipLoading(true));
  try {
    const { data } = await DictionariesService.getOwnershipTypes();
    dispatch(setOwnershipList(data.Data));
    return data.Data;
  } catch (error: any) {
    return rejectWithValue(error?.response?.data?.Data?.Message);
  } finally {
    dispatch(setOwnershipLoading(false));
  }
});

export const fetchGisZhkhSentStatuses = createAsyncThunk<
  Dictionary.DictionaryItem<OCCV2Order.GisZhkhStatus>[],
  undefined
>("dictionary/fetch-gis-zhkh-sent-statuses", async (payload: undefined, { dispatch, rejectWithValue, getState }) => {
  const rootState = getState() as RootState;

  if (
    !rootState.user.isGisZhkhEnabled ||
    rootState.user?.profile?.role !== Profile.UserRole.Company ||
    rootState.user?.profile?.company_type === Profile.CompanyType.SNT
  ) {
    return [];
  }

  if (rootState.dictionary.gisZhkhSentStatuses.list.length > 0) {
    return rootState.dictionary.gisZhkhSentStatuses.list;
  }

  dispatch(setGisZhkhSentStatusesLoading(true));
  try {
    const { data } = await DictionariesService.getGisZhkhSentStatus();
    dispatch(setGisZhkhSentStatusesList(data.Data));
    return data.Data;
  } catch (error: any) {
    return rejectWithValue(error?.response?.data?.Data?.Message);
  } finally {
    dispatch(setGisZhkhSentStatusesLoading(false));
  }
});

export const fetchDictionaries = createAsyncThunk(
  "dictionary/fetch-dictionaries",
  async (payload: undefined, { dispatch, rejectWithValue }) => {
    return await Promise.all([
      dispatch(fetchOCCStatuses()).unwrap(),
      dispatch(fetchOCCForms()).unwrap(),
      dispatch(fetchOCCLocation()).unwrap(),
      dispatch(fetchOCCTypes()).unwrap(),
      dispatch(fetchQuorums()).unwrap(),
      dispatch(fetchApartmentTypes()).unwrap(),
      dispatch(fetchApartmentOwnerships()).unwrap(),
      dispatch(getStatuses()).unwrap(),
      dispatch(fetchAccessRoles()).unwrap(),
      dispatch(fetchGisZhkhSentStatuses()).unwrap(),
    ]);
  }
);

export const clearStoredDictionaries = createAsyncThunk(
  "dictionary/fetch-dictionaries",
  async (payload: undefined, { dispatch, rejectWithValue }) => {
    localStorage.removeItem(StorageKeys.Statuses);
    localStorage.removeItem(StorageKeys.Types);
    localStorage.removeItem(StorageKeys.Forms);
    localStorage.removeItem(StorageKeys.Locations);
    localStorage.removeItem(StorageKeys.Quorums);
    localStorage.removeItem(StorageKeys.ApartmentType);
    localStorage.removeItem(StorageKeys.Ownership);
  }
);
