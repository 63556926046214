import { useEffect } from "react";
import { Outlet } from "react-router";
import { useAppDispatch } from "@/app/store";
import { InstitutionActions } from "@/app/store/slices/institution";

const InstitutionMunicipalityCompanies: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(InstitutionActions.Companies.reset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Outlet />;
};

export default InstitutionMunicipalityCompanies;
