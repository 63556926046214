import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { paths } from "@/consts/routes";
import { OccInitiatives } from "@/types";
import { ChevronDownSmall16Icon, ChevronRight16Icon, QuestionTooltip16Icon } from "@/consts/icons-v2";
import { RootState, useAppDispatch } from "@/app/store";
import { OccInitiativeActions } from "@/app/store/slices/occInitiatives";
import { showSnackbar, SnackbarType } from "@/app/store/slices/snackbar";
import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import useEmptyBlockHeight from "@/hooks/useEmptyBlockHeight";
import HeadlineAlertV2 from "@/components/V2/HeadlineAlertV2";
import PageHeader from "@/components/PageHeader";
import { BaseDropdown, BaseDropdownMenuItem } from "@/components/base-dropdown";
import {
  BaseTable,
  BaseTableHead,
  BaseTableBody,
  BaseTableRow,
  BaseTableHeadCell,
  BaseTableCell,
  BaseTableToolbar,
  BaseContainer,
} from "@/components/base-table";
import Spinner from "@/components/spinner";
import InteractiveEyes from "@/features/InteractiveEyes";
import InitiativeStatus from "@/components/OCC/InitiativeStatus";
import BaseTooltip from "@/components/base-tooltip";
import styles from "./styles.module.scss";

const UKOccInitiatives: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { isLoading, list, statuses, filters, pagination } = useSelector((state: RootState) => state.occInitiatives);

  const { measureRef, isIntersecting, observer, hasMore } = useInfiniteScroll({ pagination: pagination ?? undefined });
  const { emptyBlockHeight, emptyBlockRef } = useEmptyBlockHeight();

  const isFiltered: boolean = useMemo(() => {
    return filters.Status != null;
  }, [filters]);

  function onFilter(Status: OccInitiatives.Status | null) {
    dispatch(OccInitiativeActions.setFilter({ Status }));
  }

  function handleInitiativeSelect(id: OccInitiatives.ListItem["Id"]) {
    navigate(paths.uk().fullPath().occInitiatives.item.layout(id));
  }

  async function fetch() {
    try {
      await dispatch(OccInitiativeActions.fetchList()).unwrap();
    } catch (error: any) {
      dispatch(
        showSnackbar({
          key: "occ-initiatives-error",
          title: "Что-то пошло не так.",
          body: error?.Data?.Message ?? "Попробуйте войти позднее",

          type: SnackbarType.ERROR,
        })
      );
    }
  }

  async function fetchMoreData() {
    try {
      const meta = await dispatch(OccInitiativeActions.loadMore()).unwrap();

      if (observer && isIntersecting && meta.Index === meta.Count) {
        observer.disconnect();
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (isIntersecting) {
      fetchMoreData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIntersecting]);

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageHeader title="Инициативы жителей" />

      <div>
        <HeadlineAlertV2
          name="occ-initiatives"
          title="Обратите внимание"
          verticalMargin="0"
          background="linear-gradient(92.62deg, #1E1F24 0%, #4259AF 100%)"
        >
          <p className="text--primary">
            Согласно пункту 6 статьи 45 Жилищного кодекса Российской Федерации собственники, обладающие не менее чем 10%
            от площади всех помещений в многоквартирном доме, вправе обратиться в письменной форме в управляющую
            организацию для организации проведения общего собрания собственников помещений в многоквартирном доме.
            <br />
            Здесь вы можете отслеживать поступающие обращения из мобильного приложения «Локоло». Перед отправкой
            обращения мы проверяем требуемую площадь в собственности пользователей
          </p>
        </HeadlineAlertV2>
      </div>

      <div>
        <BaseContainer defaultHorizontalOffset defaultTopOffset>
          {list.length === 0 && !isFiltered && !isLoading ? (
            <div ref={emptyBlockRef}>
              <div className={styles["p-occ-initiatives__empty"]} style={{ height: `${emptyBlockHeight}px` }}>
                <InteractiveEyes />
                <div className="center">
                  <h4 className="headline-h4 sf-text-bold color--text-primary">Инициатив пока нет</h4>
                  <p className="text--primary sf-pro-regular color--text-primary">
                    Однако если они появятся, постарайтесь рассмотреть их как можно быстрее. Это имеет большое значение
                    для жителей ваших домов
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <>
              <BaseTableToolbar>
                <BaseDropdown
                  placeholder="Фильтр"
                  value={filters.Status}
                  isSelectable={true}
                  onSelect={onFilter}
                  customToggler={
                    <div style={{ gap: "4px" }}>
                      <span className="color--primary sf-text-medium text--primary">
                        {filters.Status
                          ? statuses.find((it) => it.Key === filters.Status)?.Value ?? "Фильтр"
                          : "Фильтр"}
                      </span>
                      {ChevronDownSmall16Icon("var(--icons-accent)")}
                    </div>
                  }
                >
                  <div>
                    <BaseDropdownMenuItem value={null} defaultHover>
                      <span className="sf-text-regular">Все инициативы</span>
                    </BaseDropdownMenuItem>
                    {statuses.map((status) => (
                      <BaseDropdownMenuItem value={status.Key} key={status.Key} defaultHover>
                        <span className="sf-text-regular">{status.Value}</span>
                      </BaseDropdownMenuItem>
                    ))}
                  </div>
                </BaseDropdown>
              </BaseTableToolbar>

              <div>
                <BaseTable>
                  <BaseTableHead nowrap>
                    <BaseTableRow>
                      <BaseTableHeadCell>Дата отправки</BaseTableHeadCell>
                      <BaseTableHeadCell>Адрес</BaseTableHeadCell>
                      <BaseTableHeadCell>Инициатор</BaseTableHeadCell>
                      <BaseTableHeadCell>Статус инициативы</BaseTableHeadCell>
                      <BaseTableHeadCell></BaseTableHeadCell>
                    </BaseTableRow>
                  </BaseTableHead>

                  {isLoading ? (
                    <BaseTableBody empty refProp={emptyBlockRef}>
                      <BaseTableRow>
                        <BaseTableCell colspan={5} style={{ height: `${emptyBlockHeight}px` }}>
                          <div className="flex center">
                            <Spinner size={36} />
                          </div>
                        </BaseTableCell>
                      </BaseTableRow>
                    </BaseTableBody>
                  ) : list.length ? (
                    <>
                      <BaseTableBody defaultDivider>
                        {list.map((initiative) => (
                          <BaseTableRow
                            className={styles["p-occ-initiatives__row"]}
                            key={initiative.Id}
                            onClick={() => handleInitiativeSelect(initiative.Id)}
                          >
                            <BaseTableHeadCell>
                              <span className="sf-text-semibold">{initiative.SentAt}</span>
                            </BaseTableHeadCell>
                            <BaseTableCell>
                              <span className="sf-text-regular">{initiative.Address}</span>
                            </BaseTableCell>
                            <BaseTableCell>
                              <span className="sf-text-semibold">{initiative.Author}</span>
                            </BaseTableCell>
                            <BaseTableCell>
                              <span className="flex middle" style={{ flexWrap: "wrap", gap: "8px" }}>
                                <InitiativeStatus
                                  className="table--middle sf-text-regular"
                                  status={initiative.Status.Key}
                                  model={initiative.Status}
                                />
                                {initiative?.Status?.Key &&
                                  initiative?.Status.DaysText &&
                                  [OccInitiatives.Status.New, OccInitiatives.Status.WaitOccCreate].includes(
                                    initiative.Status.Key
                                  ) && (
                                    <span
                                      className="middle table--middle sf-text-semibold color--text-primary"
                                      style={{ display: "inline-flex", gap: "4px" }}
                                    >
                                      {initiative?.Status.DaysText}
                                      <BaseTooltip title="По обращению собственников управляющая организация должна осуществить мероприятия, необходимые для проведения общего собрания собственников в течение 45 дней с момента поступления обращения">
                                        {QuestionTooltip16Icon()}
                                      </BaseTooltip>
                                    </span>
                                  )}
                              </span>
                            </BaseTableCell>
                            <BaseTableCell>
                              <span>{ChevronRight16Icon("var(--icons-primary)")}</span>
                            </BaseTableCell>
                          </BaseTableRow>
                        ))}
                      </BaseTableBody>
                      {hasMore && (
                        <tbody ref={measureRef}>
                          <tr>
                            <td colSpan={7}>
                              <div className="flex center">
                                <Spinner size={36} />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </>
                  ) : (
                    isFiltered && (
                      <BaseTableBody empty refProp={emptyBlockRef}>
                        <BaseTableRow>
                          <BaseTableCell colspan={5} style={{ height: `${emptyBlockHeight}px` }}>
                            <div className="flex middle center" style={{ height: "100%" }}>
                              <div className="center">
                                <p className="text--primary sf-pro-regular color--text-primary">Ничего не нашлось</p>
                              </div>
                            </div>
                          </BaseTableCell>
                        </BaseTableRow>
                      </BaseTableBody>
                    )
                  )}
                </BaseTable>
              </div>
            </>
          )}
        </BaseContainer>
      </div>
    </>
  );
};

export default UKOccInitiatives;
