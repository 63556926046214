import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "@/app/store";
import { paths } from "@/consts/routes";
import { Profile } from "@/types";

const RootRedirect: React.FC = () => {
  const { profile } = useSelector((state: RootState) => state.user);

  if (profile?.role === Profile.UserRole.Institution) {
    if (profile.institution_type === Profile.InstitutionType.AgricultureMinistry) {
      return <Navigate to={paths.institution().root} replace={true} />;
    }

    if (profile.institution_type === Profile.InstitutionType.Municipality) {
      return <Navigate to={paths.municipality().root} replace={true} />;
    }
  } else if (profile?.company_type === Profile.CompanyType.SNT) {
    return <Navigate to={"/snt/registry"} replace={true} />;
  }

  return <Navigate to={paths.uk().fullPath().registry.subroot} replace={true} />;
};

export default RootRedirect;
