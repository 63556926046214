import MenuByProfile from "./MenuByProfile";
import styles from "./styles.module.scss";

const RoutesManager: React.FC = () => {
  return (
    <div className={styles["b-navigation"]}>
      <MenuByProfile />
    </div>
  );
};

export default RoutesManager;
