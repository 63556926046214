import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { SntRegistry } from "@/types";
import { SntRegistryService } from "@/services/v2";
import { RootState, useAppDispatch } from "@/app/store";
import { toggleEditSectionModal } from "@/app/store/slices/snt/registry";
import { createSection, updateSection } from "@/app/store/slices/snt/registry/actions";
import { Spinner } from "@/components/spinner";
import BaseButton from "@/components/base-button";
import BaseInput from "@/components/base-input";
import NumberInput from "@/components/base-input/NumberInput";
import CadastralNumberInput from "@/components/base-input/CadastralNumberInput";
import { BaseModal, BaseModalHeader, BaseModalContent, BaseModalActions } from "@/components/BaseModal";
import EditOwnerModal from "./edit-owner";
import OwnerItem from "./owner-item";
import styles from "./styles.module.scss";

const EditSectionModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const containerRef = useRef<HTMLDivElement>(null);

  const { isOpen, sectionId, mode } = useSelector((state: RootState) => state.sntRegistry.editSectionModal);
  const sntDetails = useSelector((state: RootState) => state.sntRegistry.details);

  const [isOwnerModalShown, setOwnerModalShown] = useState<boolean>(false);
  const [selectedOwner, selectOwner] = useState<SntRegistry.CreatedOwner | SntRegistry.SectionOwner | null>(null);
  const [form, setForm] = useState<SntRegistry.SectionForm>(new SntRegistry.SectionForm());
  const [ownerChange, setOwnerChange] = useState<SntRegistry.SectionDetailsResponse["Included"]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  const title: string = useMemo(() => {
    switch (mode) {
      case "edit":
        return "Редактировать участок";
      case "view":
        return "Подробнее";

      default:
        return "Добавить участок";
    }
  }, [mode]);

  const isNumberEditable: boolean = useMemo(() => {
    return ownerChange.every((it) => it.Attributes.Updated);
  }, [ownerChange]);

  const subModalLeft: number = useMemo(() => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      return rect.left + 123;
    }
    return 0;
  }, [containerRef.current]);

  const isReadonly: boolean = useMemo(() => {
    return mode === "view";
  }, [mode]);

  const updateFormFieldValue = (key: "Number" | "Area" | "CadastralNumber" | "ArchivalNumber", value: string) => {
    setForm((prevState) => ({
      ...prevState,
      [key]: { ...form[key], value },
    }));
  };

  const showAddOwnerModal = () => {
    selectOwner(null);
    setOwnerModalShown(true);
  };

  const showEditOwnerModal = (owner: SntRegistry.CreatedOwner | SntRegistry.SectionOwner) => {
    selectOwner(owner);
    setOwnerModalShown(true);
  };

  const addOwner = (owner: Omit<SntRegistry.CreatedOwner, "Id">) => {
    const _owner: SntRegistry.CreatedOwner = { ...owner, Id: -form.Owners.length };
    const owners = new Set(form.Owners);
    owners.add(_owner);

    const created = new Set(form.CreatedOwners);
    created.add(_owner);

    setForm((prevState) => ({
      ...prevState,
      CreatedOwners: Array.from(created),
      Owners: Array.from(owners),
      OwnersError: "",
    }));
    setOwnerModalShown(false);
  };

  const updateOwner = (owner: SntRegistry.CreatedOwner | SntRegistry.SectionOwner) => {
    const owners = form.Owners.map((it) => {
      if (it.Id === owner.Id) {
        return {
          ...owner,
        };
      }
      return it;
    });
    const createdOwners = form.CreatedOwners.map((it) => {
      if (it.Id === owner.Id) {
        return {
          ...owner,
        };
      }
      return it;
    });

    setForm((prevState) => ({
      ...prevState,
      CreatedOwners: createdOwners,
      Owners: owners,
    }));

    if ("Id" in owner && owner.Id > 0 && "LokoloUserId" in owner) {
      let updated = [...form.UpdatedOwners];
      if (updated.find((it) => it.Id === owner.Id)) {
        updated = form.UpdatedOwners.map((it) => {
          if (it.Id === owner.Id) {
            return {
              ...owner,
            };
          }
          return it;
        });
      } else {
        updated.push(owner);
      }

      setForm((prevState) => ({
        ...prevState,
        UpdatedOwners: updated,
      }));
    }

    setOwnerModalShown(false);
  };

  const removeOwner = (owner: SntRegistry.CreatedOwner | SntRegistry.SectionOwner) => {
    const owners = form.Owners.filter((it) => it.Id !== owner.Id);
    const created = form.CreatedOwners.filter((it) => it.Id !== owner.Id);

    setForm((prevState) => ({
      ...prevState,
      Owners: owners,
      CreatedOwners: created,
    }));

    if ("Id" in owner && owner.Id > 0 && "LokoloUserId" in owner) {
      const deleted = new Set(form.DeletedOwners);
      deleted.add(owner.Id);

      const updated = form.UpdatedOwners.filter((it) => it.Id !== owner.Id);
      setForm((prevState) => ({
        ...prevState,
        DeletedOwners: Array.from(deleted),
        UpdatedOwners: updated,
      }));
    }
  };

  const onClose = () => {
    dispatch(toggleEditSectionModal({ isOpen: false }));
  };

  const handleError = (payload: any) => {
    if (payload?.Errors) {
      Object.keys(payload.Errors).forEach((key: any) => {
        const message = Array.isArray(payload.Errors[key]) ? payload.Errors[key][0] : payload.Errors[key];
        switch (key) {
          case "Number": {
            setForm((prevState) => ({
              ...prevState,
              Number: { ...form.Number, error: message },
            }));
            break;
          }
          case "Area": {
            setForm((prevState) => ({
              ...prevState,
              Area: { ...form.Area, error: message },
            }));
            break;
          }
          case "CadastralNumber": {
            setForm((prevState) => ({
              ...prevState,
              CadastralNumber: { ...form.CadastralNumber, error: message },
            }));
            break;
          }
          case "ArchivalNumber": {
            setForm((prevState) => ({
              ...prevState,
              ArchivalNumber: { ...form.ArchivalNumber, error: message },
            }));
            break;
          }
          case "Owners": {
            setForm((prevState) => ({
              ...prevState,
              OwnersError: message,
            }));
            break;
          }
          default: {
            setForm((prevState) => ({ ...prevState, error: message }));
            break;
          }
        }
      });
    } else {
      setForm((prevState) => ({ ...prevState, error: payload?.Message }));
    }
  };

  const isFormValid = () => {
    const validator = SntRegistry.SectionFormValidator.isInvalid(form);

    if (validator) {
      setForm((prevState) => ({
        ...prevState,
        Number: {
          ...form.Number,
          error: validator.Number,
        },
        Area: {
          ...form.Area,
          error: validator.Area,
        },
        CadastralNumber: {
          ...form.CadastralNumber,
          error: validator.CadastralNumber,
        },
        ArchivalNumber: {
          ...form.ArchivalNumber,
          error: validator.ArchivalNumber,
        },
        OwnersError: validator.Owners,
      }));
    }

    return !validator;
  };

  const handleSubmit = async () => {
    Object.keys(form).forEach((key: string) => {
      if (typeof (form as any)[key] === "object" && (form as any)[key]?.error) {
        setForm((prevState) => ({
          ...prevState,
          [key]: { ...(form as any)[key], error: "" },
        }));
      }
    });
    setForm((prevState) => ({ ...prevState, error: "", OwnersError: "" }));

    if (!isFormValid() || !sntDetails?.Id) {
      return;
    }

    setForm((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      if (sectionId !== undefined && sectionId !== null) {
        await dispatch(
          updateSection({
            SntId: sntDetails?.Id,
            Id: sectionId,
            Number: form.Number.value,
            Area: form.Area.value,
            CadastralNumber: form.CadastralNumber.value,
            ArchivalNumber: form.ArchivalNumber.value,
            CreatedOwners: form.CreatedOwners,
            UpdatedOwners: form.UpdatedOwners,
            DeletedOwners: form.DeletedOwners.map((it) => ({ Id: it })),
          })
        ).unwrap();
      } else {
        await dispatch(
          createSection({
            SntId: sntDetails?.Id,
            Number: form.Number.value,
            Area: form.Area.value,
            CadastralNumber: form.CadastralNumber.value,
            ArchivalNumber: form.ArchivalNumber.value,
            CreatedOwners: form.CreatedOwners,
          })
        ).unwrap();
      }
      onClose();
    } catch (error: any) {
      console.warn(error);
      handleError(error.Data);
    } finally {
      setForm((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  };

  const fetch = async () => {
    if (sntDetails?.Id && sectionId) {
      setLoading(true);
      try {
        const { data } = await SntRegistryService.getSectionDetailsById(sntDetails?.Id, sectionId);
        setForm(new SntRegistry.SectionForm(sntDetails.Id, data.Data));
        setOwnerChange(data.Included);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetch();
    } else {
      setTimeout(() => {
        setForm(new SntRegistry.SectionForm(sntDetails?.Id));
        setOwnerChange([]);
      }, 1000);
    }
  }, [isOpen]);

  return (
    <>
      <BaseModal isOpen={isOpen} maxWidth="863px" minWidth="863px">
        <div ref={containerRef}>
          <BaseModalHeader title={title} onClose={onClose} />

          <BaseModalContent sx={{ padding: "16px 24px" }}>
            {isLoading ? (
              <div className={styles.loading}>
                <Spinner size={36} color="#226dff" />
              </div>
            ) : (
              <div>
                <div className={styles.section}>
                  <BaseInput
                    value={form.Number.value}
                    label="№ Участка"
                    required={form.Number.isRequired}
                    disabled={isReadonly || !isNumberEditable}
                    placeholder="Введите номер"
                    errorMessage={form.Number.error}
                    maxlength={form.Number.maxlength}
                    onChange={(value) => updateFormFieldValue("Number", value)}
                  />

                  <NumberInput
                    value={form.Area.value}
                    label="Площадь участка (м²)"
                    required={form.Area.isRequired}
                    disabled={isReadonly}
                    placeholder="Введите площадь"
                    errorMessage={form.Area.error}
                    onChange={(value) => updateFormFieldValue("Area", value)}
                  />

                  <CadastralNumberInput
                    value={form.CadastralNumber.value}
                    label="Кадастровый номер земельного участка"
                    required={form.CadastralNumber.isRequired}
                    disabled={isReadonly}
                    placeholder="Введите номер"
                    errorMessage={form.CadastralNumber.error}
                    onChange={(value) => updateFormFieldValue("CadastralNumber", value)}
                  />

                  <BaseInput
                    value={form.ArchivalNumber.value}
                    label="Условный (архивный) номер земельного участка"
                    required={form.ArchivalNumber.isRequired}
                    disabled={isReadonly}
                    placeholder="Введите номер"
                    errorMessage={form.ArchivalNumber.error}
                    maxlength={form.ArchivalNumber.maxlength}
                    onChange={(value) => updateFormFieldValue("ArchivalNumber", value)}
                  />
                </div>

                <div className={styles.owners}>
                  <h4 className="headline-h4 sf-text-bold">Собственники:</h4>
                  {!isReadonly && (
                    <div className={styles.owners_add_button}>
                      <div>
                        <BaseButton
                          size="medium"
                          startIcon={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M11.0001 4.1665C11.0001 3.61422 10.5524 3.1665 10.0001 3.1665C9.4478 3.1665 9.00008 3.61422 9.00008 4.1665V8.99984H4.16675C3.61446 8.99984 3.16675 9.44755 3.16675 9.99984C3.16675 10.5521 3.61446 10.9998 4.16675 10.9998H9.00008V15.8332C9.00008 16.3855 9.4478 16.8332 10.0001 16.8332C10.5524 16.8332 11.0001 16.3855 11.0001 15.8332V10.9998H15.8334C16.3857 10.9998 16.8334 10.5521 16.8334 9.99984C16.8334 9.44755 16.3857 8.99984 15.8334 8.99984H11.0001V4.1665Z"
                                fill="#226DFF"
                              />
                            </svg>
                          }
                          variant="secondary"
                          onClick={showAddOwnerModal}
                        >
                          Добавить собственника
                        </BaseButton>
                      </div>
                      {form.OwnersError && (
                        <p className="sf-text-regular text--primary color--red">{form.OwnersError}</p>
                      )}
                    </div>
                  )}

                  {form.Owners.length > 0 &&
                    form.Owners.map((owner, index) => (
                      <OwnerItem
                        key={`${owner.FullName}_${index}`}
                        owner={owner}
                        ownerChange={ownerChange}
                        readonly={isReadonly}
                        onOpen={() => showEditOwnerModal(owner)}
                        onEdit={() => showEditOwnerModal(owner)}
                        onRemove={() => removeOwner(owner)}
                      />
                    ))}
                </div>
                {form.error && (
                  <p className={styles.common_error}>
                    <span className="sf-text-regular text--primary color--red">{form.error}</span>
                  </p>
                )}
              </div>
            )}
          </BaseModalContent>

          <BaseModalActions>
            {mode === "view" ? (
              <BaseButton onClick={onClose}>Закрыть</BaseButton>
            ) : mode === "edit" ? (
              <>
                <BaseButton variant="secondary" onClick={onClose}>
                  Отменить
                </BaseButton>
                <BaseButton isLoading={form.isLoading} onClick={handleSubmit}>
                  Сохранить
                </BaseButton>
              </>
            ) : (
              <>
                <BaseButton variant="secondary" onClick={onClose}>
                  Отменить
                </BaseButton>
                <BaseButton isLoading={form.isLoading} onClick={handleSubmit}>
                  Добавить
                </BaseButton>
              </>
            )}
          </BaseModalActions>

          {isOwnerModalShown && (
            <EditOwnerModal
              readonly={isReadonly}
              owner={selectedOwner}
              ownerChange={ownerChange}
              left={subModalLeft}
              isOpen={isOwnerModalShown}
              onClose={() => setOwnerModalShown(false)}
              onAdd={(owner) => addOwner(owner)}
              onEdit={(owner) => updateOwner(owner)}
            />
          )}
        </div>
      </BaseModal>
    </>
  );
};

export default EditSectionModal;
