import Sidebar from "../Sidebar";
import Header from "../Header";
import styles from "../styles.module.scss";

interface ILayoutProps {
  children: JSX.Element | JSX.Element[];
}

const LayoutV2: React.FC<ILayoutProps> = ({ children }) => {
  return (
    <div className={styles.layout}>
      <Header />
      <div className={styles.wrapper}>
        <aside>
          <Sidebar />
        </aside>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};
export default LayoutV2;
