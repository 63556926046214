import { Institution, Pagination, Profile } from "@/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IInstitutionState {
  statistic: Institution.Statistics | null;
  isStatisticLoading: boolean;
  //
  companies: {
    count: number;
    list: Institution.Company[];
    isLoading: boolean;
    pagination: Pagination | null;
    filters: {
      LokoloUserExists: boolean | null;
      Type: Profile.CompanyType | null;
    };
    search: string;
  };
}

const institutionSlice = createSlice({
  name: "institution",
  initialState: {
    statistic: null,
    isStatisticLoading: true,
    companies: {
      count: 0,
      list: [],
      isLoading: false,
      pagination: null,
      filters: {
        LokoloUserExists: null,
        Type: null,
      },
      search: "",
    },
  } as IInstitutionState,
  reducers: {
    setStatistic: (state, action: PayloadAction<IInstitutionState["statistic"]>) => {
      state.statistic = action.payload;
    },

    setStatisticLoading: (state, action: PayloadAction<IInstitutionState["isStatisticLoading"]>) => {
      state.isStatisticLoading = action.payload;
    },

    setCompaniesCount: (state, action: PayloadAction<IInstitutionState["companies"]["count"]>) => {
      state.companies.count = action.payload;
    },

    setCompanies: (state, action: PayloadAction<IInstitutionState["companies"]["list"]>) => {
      state.companies.list = action.payload;
    },

    pushCompanies: (state, action: PayloadAction<IInstitutionState["companies"]["list"]>) => {
      state.companies.list.push(...action.payload);
    },

    setCompaniesSearch: (state, action: PayloadAction<IInstitutionState["companies"]["search"]>) => {
      state.companies.search = action.payload;
    },

    setCompaniesLoading: (state, action: PayloadAction<IInstitutionState["companies"]["isLoading"]>) => {
      state.companies.isLoading = action.payload;
    },

    setCompaniesFilters: (state, action: PayloadAction<IInstitutionState["companies"]["filters"]>) => {
      state.companies.filters = action.payload;
    },

    setCompaniesPagination: (state, action: PayloadAction<IInstitutionState["companies"]["pagination"]>) => {
      state.companies.pagination = action.payload;
    },

    resetCompanies: (state) => {
      state.companies = {
        ...state.companies,
        list: [],
        isLoading: false,
        pagination: null,
        filters: {
          LokoloUserExists: null,
          Type: null,
        },
        search: "",
      };
    },
  },
});

export default institutionSlice.reducer;
export * from "./actions";
export const {
  setStatistic,
  setStatisticLoading,
  setCompaniesCount,
  setCompanies,
  pushCompanies,
  setCompaniesSearch,
  setCompaniesLoading,
  setCompaniesFilters,
  setCompaniesPagination,
  resetCompanies,
} = institutionSlice.actions;
