import { useSelector } from "react-redux";
import { RootState } from "@/app/store";
import { paths } from "@/consts/routes";
import { CalendarCheckIcon, CheckListIcon, ChevronRight16Icon, KeyIcon } from "@/consts/icons-v2";
import CompanyCommonMenu from "../Company";
import MenuItem from "../../MenuItem";
import NestedMenuItem from "../../NestedMenuItem";
import styles from "../../MenuItem/styles.module.scss";

const CompanyUkMenu: React.FC = () => {
  const company = useSelector((state: RootState) => state.user.company);
  const newInitiativesCount = useSelector((state: RootState) => state.occInitiatives.count);

  return (
    <>
      <MenuItem
        path={paths.uk().fullPath().registry.subroot}
        text="Реестр собственников"
        icon={CheckListIcon("var(--lkl-c--icons-primary)")}
      />

      <MenuItem path={paths.uk().fullPath().houses} text="Мои дома" icon={KeyIcon("var(--lkl-c--icons-primary)")}>
        <span className="lkl-font--sf-text--medium lkl-tpgrhy--text--primary lkl-color--text-secondary">
          {company?.Addresses.length}
        </span>
      </MenuItem>

      <NestedMenuItem text="Cобрания" icon={CalendarCheckIcon("var(--lkl-c--icons-primary)")}>
        <MenuItem path="/uk/create-occ" text="Создать собрание">
          {ChevronRight16Icon("var(--lkl-c--icons-primary)")}
        </MenuItem>
        <MenuItem path={paths.myOcc} text="Мои собрания">
          {ChevronRight16Icon("var(--lkl-c--icons-primary)")}
        </MenuItem>
        <MenuItem path={paths.uk().fullPath().occInitiatives.subroot} text="Инициативы жителей">
          <div className="flex middle" style={{ gap: "8px" }}>
            {newInitiativesCount > 0 && (
              <span className={styles["b-menu-item__counter"]}>
                <span className="lkl-font--sf-text--medium lkl-tpgrhy--text--primary lkl-color--text-white">
                  {newInitiativesCount}
                </span>
              </span>
            )}
            {ChevronRight16Icon("var(--lkl-c--icons-primary)")}
          </div>
        </MenuItem>
      </NestedMenuItem>

      <CompanyCommonMenu />
    </>
  );
};

export default CompanyUkMenu;
