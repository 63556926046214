import { useEffect } from "react";
import { useSelector } from "react-redux";
import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import useEmptyBlockHeight from "@/hooks/useEmptyBlockHeight";
import { QuestionTooltip16Icon } from "@/consts/icons-v2";
import { RootState, useAppDispatch } from "@/app/store";
import { OccInitiativeActions } from "@/app/store/slices/occInitiatives";
import { showSnackbar, SnackbarType } from "@/app/store/slices/snackbar";
import {
  BaseTable,
  BaseTableHead,
  BaseTableBody,
  BaseTableRow,
  BaseTableHeadCell,
  BaseTableCell,
  BaseContainer,
} from "@/components/base-table";
import Spinner from "@/components/spinner";
import BaseTooltip from "@/components/base-tooltip";
import UserOwnershipStatus from "@/components/UserDetails/OwnershipStatus";
import styles from "./styles.module.scss";

const UKOccInitiativeItemVotes: React.FC = () => {
  const dispatch = useAppDispatch();

  const initiative = useSelector((state: RootState) => state.occInitiatives.selected.details);
  const { isLoading, list, pagination } = useSelector((state: RootState) => state.occInitiatives.selected.votes);

  const { measureRef, hasMore } = useInfiniteScroll({
    pagination: pagination ?? undefined,
    loadMore,
  });
  const { emptyBlockHeight, emptyBlockRef } = useEmptyBlockHeight();

  async function loadMore() {
    try {
      if (!initiative?.Id) {
        throw new Error("");
      }

      const page = await dispatch(OccInitiativeActions.loadMoreInitiativeVotes(initiative.Id)).unwrap();
      return page;
    } catch (error: any) {
      console.log(error);
      return null;
    }
  }

  async function fetch() {
    if (!initiative?.Id) {
      throw new Error("");
    }

    try {
      await dispatch(OccInitiativeActions.getInitiativeVotes(initiative.Id)).unwrap();
    } catch (error: any) {
      dispatch(
        showSnackbar({
          key: "occ-initiative-votes-error",
          title: "Что-то пошло не так.",
          body: error?.Data?.Message ?? "Попробуйте войти позднее",

          type: SnackbarType.ERROR,
        })
      );
    }
  }

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <BaseContainer defaultHorizontalOffset defaultTopOffset className={styles.table}>
        <div className={`custom-vertical-scrollbar ${styles.table_container}`}>
          <BaseTable>
            <BaseTableHead nowrap>
              <BaseTableRow className={styles.table_head_row}>
                <BaseTableHeadCell>№ помещения</BaseTableHeadCell>
                <BaseTableHeadCell>Данные собственника</BaseTableHeadCell>
                <BaseTableHeadCell>Голос</BaseTableHeadCell>
                <BaseTableHeadCell>Есть в моем реестре</BaseTableHeadCell>
                <BaseTableHeadCell>Может участвовать в собраниях</BaseTableHeadCell>
                <BaseTableHeadCell>Статус в приложении</BaseTableHeadCell>
              </BaseTableRow>
            </BaseTableHead>

            {isLoading ? (
              <BaseTableBody empty refProp={emptyBlockRef}>
                <BaseTableRow>
                  <BaseTableCell colspan={6} style={{ height: `${emptyBlockHeight}px` }}>
                    <div className="flex center">
                      <Spinner size={36} />
                    </div>
                  </BaseTableCell>
                </BaseTableRow>
              </BaseTableBody>
            ) : list.length ? (
              <>
                <BaseTableBody defaultDivider>
                  {list.map((vote) => (
                    <BaseTableRow className={styles["b-occ-nitiative-votes__row"]} key={vote.UserId}>
                      <BaseTableHeadCell>
                        <span className="sf-text-semibold">{vote.ObjectNumber}</span>
                      </BaseTableHeadCell>
                      <BaseTableCell>
                        <span className="sf-text-semibold">
                          {vote.Fio}{" "}
                          {vote.IsInitiator && (
                            <BaseTooltip title="Инициатор">
                              <span style={{ display: "inline-flex", transform: "translateY(3px)" }}>
                                {InitiatorIcon}
                              </span>
                            </BaseTooltip>
                          )}
                        </span>
                      </BaseTableCell>
                      <BaseTableCell>{vote.IsAgree ? LikeIcon : DislikeIcon}</BaseTableCell>
                      <BaseTableCell>
                        <span className="sf-text-regular">
                          <span>{vote.InRegistry ? "Да" : "Нет"}</span>
                        </span>
                      </BaseTableCell>
                      <BaseTableCell>
                        <span className="sf-text-regular">
                          {vote.CanOccVote ? (
                            <span>Да</span>
                          ) : (
                            <span className="flex middle" style={{ gap: "4px" }}>
                              Нет
                              <BaseTooltip title="Для участия в общих собраниях пользователь должен авторизоваться в приложении через ЕСИА, а также он должен быть в вашем Реестре собственников">
                                {QuestionTooltip16Icon()}
                              </BaseTooltip>
                            </span>
                          )}
                        </span>
                      </BaseTableCell>
                      <BaseTableCell>
                        <span>
                          <UserOwnershipStatus status={vote.Ownership} />
                        </span>
                      </BaseTableCell>
                    </BaseTableRow>
                  ))}
                </BaseTableBody>
                {hasMore && (
                  <tbody ref={measureRef}>
                    <tr>
                      <td colSpan={3}>
                        <div className="flex center">
                          <Spinner size={36} />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )}
              </>
            ) : (
              <BaseTableBody empty refProp={emptyBlockRef}>
                <BaseTableRow>
                  <BaseTableCell colspan={3} style={{ height: `${emptyBlockHeight}px` }}>
                    <div>
                      <div className="center">
                        <p className="text--primary sf-pro-regular color--text-primary">Ничего не нашлось</p>
                      </div>
                    </div>
                  </BaseTableCell>
                </BaseTableRow>
              </BaseTableBody>
            )}
          </BaseTable>
        </div>
      </BaseContainer>
    </>
  );
};

export default UKOccInitiativeItemVotes;

const LikeIcon = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.125 14.998C7.122 14.998 5.609 15.024 4.845 14.363C4.02 13.649 4 12.373 4 12.373V8.961C4 8.961 3.997 7.076 4.754 6.205C5.511 5.333 7.185 4.319 7.714 3.591C8.243 2.862 8.548 1.869 9.386 1.869C10.223 1.869 10.625 2.39 10.625 3.18C10.625 3.557 10.276 4.324 10.276 5.373C10.276 5.781 10.616 5.998 11.169 5.998H13C14.263 5.998 15 6.769 15 7.998C15 8.651 14.641 9.221 14.111 9.522C14.12 9.596 14.125 9.671 14.125 9.748C14.125 10.401 13.766 10.971 13.235 11.272C13.245 11.346 13.25 11.421 13.25 11.498C13.25 12.151 12.891 12.721 12.36 13.022C12.37 13.096 12.375 13.171 12.375 13.248C12.375 14.214 11.591 14.998 10.625 14.998H7.125ZM3 7.56C3 6.836 2.724 6.248 2 6.248C1.276 6.248 1 6.836 1 7.56V13.685C1 14.41 1.276 14.998 2 14.998C2.724 14.998 3 14.41 3 13.685V7.56Z"
      fill="var(--badge-positive)"
    />
  </svg>
);

const DislikeIcon = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.875 2C8.878 2 10.391 1.974 11.155 2.635C11.98 3.349 12 4.625 12 4.625V8.037C12 8.037 12.003 9.922 11.246 10.793C10.489 11.665 8.815 12.679 8.286 13.407C7.757 14.136 7.452 15.129 6.614 15.129C5.777 15.129 5.375 14.608 5.375 13.818C5.375 13.441 5.724 12.674 5.724 11.625C5.724 11.217 5.384 11 4.831 11H3C1.737 11 1 10.229 1 9C1 8.347 1.359 7.777 1.889 7.476C1.88 7.402 1.875 7.327 1.875 7.25C1.875 6.597 2.234 6.027 2.765 5.726C2.755 5.652 2.75 5.577 2.75 5.5C2.75 4.847 3.109 4.277 3.64 3.976C3.63 3.902 3.625 3.827 3.625 3.75C3.625 2.784 4.409 2 5.375 2H6.25H8.875ZM13 9.438C13 10.162 13.276 10.75 14 10.75C14.724 10.75 15 10.162 15 9.438V3.313C15 2.588 14.724 2 14 2C13.276 2 13 2.588 13 3.313V9.438Z"
      fill="var(--badge-negative)"
    />
  </svg>
);

const InitiatorIcon = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_10302_4684)">
      <rect width="16" height="16" rx="4" fill="#6E43E5" fillOpacity="0.2" />
      <path d="M4 12V4H6V9L10 4H12V12H10V7L6 12H4Z" fill="#6E43E5" />
    </g>
    <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" stroke="#AA8BFE" />
    <defs>
      <clipPath id="clip0_10302_4684">
        <rect width="16" height="16" rx="4" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
