import { useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router";
import { paths } from "@/consts/routes";
import { addIcon24 } from "@/consts/icons";
import { isCompanyTypeUK } from "@/app/store/slices/user";
import SegmentedСontrols from "@/components/SegmentedСontrols";
import SearchByQueryTypeV2 from "@/components/V2/SearchByQueryTypeV2";
import HeadlineAlertV2 from "@/components/V2/HeadlineAlertV2";
import PageHeader from "@/components/PageHeader";
import { ExternalServices } from "@/types";
import { RootState, useAppDispatch } from "@/app/store";
import { ExternalServicesActions, toggleCreateServiceModal } from "@/app/store/slices/externalServices";
import { BaseButton } from "lokolo-web-ui-library";

const PaidServices: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const isUK = useSelector(isCompanyTypeUK);

  const { list: servicesList, isLoading: isServicesLoading } = useSelector(
    (state: RootState) => state.externalServices.services
  );
  const { query, key } = useSelector((state: RootState) => state.externalServices.orders.search);

  function createService() {
    dispatch(toggleCreateServiceModal(true));
  }

  function handleQueryChange(value: string) {
    if (value !== query) {
      dispatch(ExternalServicesActions.Order.setSearchQuery(value));
    }
  }

  function handleQueryKeyChange(_key: ExternalServices.Order.SearchKey) {
    if (key !== _key) {
      dispatch(ExternalServicesActions.Order.setSearchQueryType(_key));
    }
  }

  return (
    <>
      <PageHeader title="Платные услуги">
        {location.pathname === paths.externalServices.fullPath().services &&
        !isServicesLoading &&
        servicesList.length > 0 ? (
          <BaseButton startIcon={addIcon24()} onClick={createService}>
            Добавить услугу
          </BaseButton>
        ) : location.pathname === paths.externalServices.fullPath().orders ? (
          <SearchByQueryTypeV2
            query={query}
            type={key}
            typeOptions={[
              { value: ExternalServices.Order.SearchKey.Id, text: "По номеру заявки" },
              { value: ExternalServices.Order.SearchKey.FullName, text: "По фамилии" },
              { value: ExternalServices.Order.SearchKey.PhoneNum, text: "По телефону" },
            ]}
            onQueryChange={handleQueryChange}
            onTypeChange={(value) => handleQueryKeyChange(value as ExternalServices.Order.SearchKey)}
          />
        ) : null}
      </PageHeader>

      <div>
        <HeadlineAlertV2
          name="external-services"
          title="О разделе"
          verticalMargin="0"
          background="linear-gradient(92.62deg, #1E1F24 0%, #4259AF 100%)"
        >
          {isUK ? (
            <p className="text--primary">
              Раздел предназначен для добавления платных услуг, которыми могут воспользоваться жители домов в вашем
              управлении. Также здесь вы можете отслеживать соответствующие заявки. Жители могут воспользоваться
              платными услугами в Сервисах Локоло, в разделе “Электронный дом”
            </p>
          ) : (
            <p className="text--primary">
              Раздел предназначен для добавления платных услуг, которыми могут воспользоваться садоводы. Также здесь вы
              можете отслеживать соответствующие заявки. Садоводы могут воспользоваться платными услугами в Сервисах
              Локоло, в разделе “Электронный сад”
            </p>
          )}
        </HeadlineAlertV2>
      </div>

      <div style={{ padding: "12px 0" }}>
        <SegmentedСontrols
          list={[
            {
              text: "Услуги",
              link: paths.externalServices.fullPath().services,
            },
            {
              text: "Заявки",
              link: paths.externalServices.fullPath().orders,
            },
          ]}
        />
      </div>

      <div>
        <Outlet />
      </div>
    </>
  );
};

export default PaidServices;
