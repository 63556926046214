import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { paths } from "@/consts/routes";
import { Profile } from "@/types";
import { RootState } from "@/app/store";
import { ChevronRight16Icon } from "@/consts/icons-v2";
import SelectAddressDropdown from "@/components/SelectAddressDropdown";
import RoutesManager from "./RoutesManager";
import styles from "./styles.module.scss";

const Sidebar: React.FC = () => {
  const profile = useSelector((state: RootState) => state.user.profile);
  const company = useSelector((state: RootState) => state.user.company);

  return (
    <aside className={styles["b-sidebar"]}>
      <div>
        <div>
          {profile?.role === Profile.UserRole.Company && (
            <div className={styles["b-sidebar__toolbar"]}>
              {profile?.company_type === Profile.CompanyType.SNT ? (
                <div className={styles["b-sidebar__toolbar_snt"]}>
                  <div className="flex middle">
                    <span className="lkl-font--sf-text--semibold lkl-tpgrhy--text--primary lkl-color--text-primary">
                      {company?.Name}
                    </span>
                  </div>
                  <NavLink to={paths.snt().fullPath().passport.main} className="flex middle">
                    <span className="lkl-font--sf-text--medium lkl-tpgrhy--text--primary lkl-color--text-primary">
                      Паспорт садоводства
                    </span>
                    {ChevronRight16Icon("var(--lkl-c--icons-accent)")}
                  </NavLink>
                </div>
              ) : (
                <div className={styles["b-sidebar__toolbar_uk"]}>
                  <span className="lkl-font--sf-text--semibold lkl-tpgrhy--text--primary lkl-color--text-link">
                    Выбранный дом
                  </span>

                  <SelectAddressDropdown chevronColor="var(--lkl-c--icons-accent)" />
                </div>
              )}
            </div>
          )}
          <nav className={styles.menu}>
            <RoutesManager />
          </nav>
        </div>

        <footer>
          <div>
            <a className="sf-text-medium text--primary color--text-link" href="/documents/private-policy.pdf" download>
              Политика конфиденциальности
            </a>
          </div>
          <div>
            <a className="sf-text-medium text--primary color--text-link" href="/documents/about-system.pdf" download>
              Положение сервиса
            </a>
          </div>
          <div>
            <p className="sf-text-medium text--primary color--text-primary">Техническая поддержка:</p>
            <a className="sf-text-medium text--primary color--text-link" href="mailto:locolo.help@tatar.ru">
              locolo.help@tatar.ru
            </a>
            <br />
            <a className="sf-text-medium text--primary color--text-primary" href="tel:88435257063">
              8 (843) 525-70-63
            </a>
          </div>
        </footer>
      </div>
    </aside>
  );
};

export default Sidebar;
