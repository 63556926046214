import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseThunkAPI } from "@reduxjs/toolkit/dist/createAsyncThunk";
import { debounce } from "lodash";
import { ExternalServices, Pagination } from "@/types";
import { RootState } from "@/app/store";
import { ExternalServicesService } from "@/services/v2";
import {
  IExternalServicesState,
  toggleServicesLoading,
  toggleOrdersLoading,
  setServices,
  pushServices,
  setServicesOrders,
  pushServicesOrders,
  onAddService,
  onEditService,
  onDeleteService,
  setServicesFilters,
  setOrdersFilters,
  setServicesPagination,
  setOrdersPagination,
  setOrdersSearchQuery,
  setOrdersSearchKey,
  setServicesCategories,
  setOrdersStatuses,
  setAllServices,
} from "./";

export namespace ExternalServicesActions {
  export namespace Order {
    const debouncedHandler = debounce(setSearchQueryDebounced, 300);

    async function setSearchQueryDebounced({ dispatch }: BaseThunkAPI<unknown, unknown>) {
      await dispatch(fetchOrders() as any).unwrap();
    }

    export const setSearchQuery = createAsyncThunk(
      "external-services/orders/set-search-query",
      async (query: string, { getState, dispatch }) => {
        const rootState = getState() as RootState;
        if (rootState.externalServices.orders.search.query === query) {
          return;
        }

        dispatch(setOrdersSearchQuery(query));
        debouncedHandler({ dispatch, getState } as BaseThunkAPI<unknown, unknown>);
      }
    );

    export const setSearchQueryType = createAsyncThunk(
      "external-services/orders/set-search-key",
      async (key: ExternalServices.Order.SearchKey, { rejectWithValue, getState, dispatch }) => {
        const rootState = getState() as RootState;
        if (rootState.externalServices.orders.search.key === key) {
          return;
        }

        await dispatch(setOrdersSearchKey(key));
        if (rootState.externalServices.orders.search.query.length > 0) {
          await dispatch(fetchOrders()).unwrap();
        }
      }
    );

    export const fetchOrders = createAsyncThunk<ExternalServices.Order.Item[], undefined>(
      "external-services/orders/fetch-orders",
      async (payload, { dispatch, getState, rejectWithValue }) => {
        const rootState = getState() as RootState;

        dispatch(toggleOrdersLoading(true));
        dispatch(setServicesOrders([]));
        dispatch(setOrdersPagination(null));

        try {
          const { data } = await ExternalServicesService.Order.getOrdersList({
            SearchKey: rootState.externalServices.orders.search.key,
            SearchQuery: rootState.externalServices.orders.search.query,
            Status: rootState.externalServices.orders.filters.Status ?? undefined,
            ServiceId: rootState.externalServices.orders.filters.ServiceId ?? undefined,
            HouseId: rootState.externalServices.orders.filters.HouseId ?? undefined,
            CreatedAt: rootState.externalServices.orders.filters.CreatedAt ?? undefined,
          });
          await dispatch(setServicesOrders(data.Data));
          await dispatch(setOrdersPagination(data.Page));
          return data.Data;
        } catch (error: any) {
          return rejectWithValue(error?.response?.data);
        } finally {
          dispatch(toggleOrdersLoading(false));
        }
      }
    );

    export const loadMoreOrders = createAsyncThunk<Pagination, undefined>(
      "external-services/orders/load-more-orders",
      async (payload, { dispatch, getState, rejectWithValue }) => {
        const rootState = getState() as RootState;

        try {
          const { data } = await ExternalServicesService.Order.getOrdersList({
            SearchKey: rootState.externalServices.orders.search.key,
            SearchQuery: rootState.externalServices.orders.search.query,
            Status: rootState.externalServices.orders.filters.Status ?? undefined,
            ServiceId: rootState.externalServices.orders.filters.ServiceId ?? undefined,
            HouseId: rootState.externalServices.orders.filters.HouseId ?? undefined,
            CreatedAt: rootState.externalServices.orders.filters.CreatedAt ?? undefined,
            Page: (rootState.externalServices.orders.pagination?.Index ?? 0) + 1,
          });
          await dispatch(pushServicesOrders(data.Data));
          await dispatch(setOrdersPagination(data.Page));
          return data.Page;
        } catch (error: any) {
          return rejectWithValue(error?.response?.data);
        }
      }
    );

    export const getStatuses = createAsyncThunk<ExternalServices.Order.StatusModel[], undefined>(
      "external-services/orders/get-statuses",
      async (payload, { dispatch, getState, rejectWithValue }) => {
        const rootState = getState() as RootState;
        if (rootState.externalServices.orders.statuses.length > 0) {
          return rootState.externalServices.orders.statuses;
        }

        try {
          const { data } = await ExternalServicesService.Order.getStatuses();
          await dispatch(setOrdersStatuses(data.Data));
          return data.Data;
        } catch (error: any) {
          return rejectWithValue(error?.response?.data);
        }
      }
    );

    export const applyOrdersFilters = createAsyncThunk<any, IExternalServicesState["orders"]["filters"]>(
      "external-services/orders/apply-filters",
      async (payload, { dispatch, getState, rejectWithValue }) => {
        await dispatch(
          setOrdersFilters({
            Status: payload.Status,
            ServiceId: payload.ServiceId,
            HouseId: payload.HouseId,
            CreatedAt: payload.CreatedAt,
          })
        );

        await dispatch(fetchOrders()).unwrap();
      }
    );

    export const resetSearchFilters = createAsyncThunk(
      "external-services/orders/reset-filters",
      async (payload, { dispatch, getState, rejectWithValue }) => {
        const rootState = getState() as RootState;
        if (
          rootState.externalServices.orders.filters.Status === null &&
          rootState.externalServices.orders.filters.ServiceId === null &&
          rootState.externalServices.orders.filters.HouseId === null &&
          rootState.externalServices.orders.filters.CreatedAt === null
        ) {
          return;
        }

        dispatch(
          applyOrdersFilters({
            Status: null,
            ServiceId: null,
            HouseId: null,
            CreatedAt: null,
          })
        );
      }
    );

    export const changeOrderStatus = createAsyncThunk<
      any,
      { orderId: ExternalServices.Order.Item["Id"]; body: ExternalServices.Order.SetStatusBody }
    >("external-services/orders/change-order-status", async (payload, { dispatch, getState, rejectWithValue }) => {
      try {
        const { data } = await ExternalServicesService.Order.changeOrderStatus(payload.orderId, payload.body);
        return data.Data;
      } catch (error: any) {
        return rejectWithValue(error?.response?.data);
      }
    });

    export const getAllServices = createAsyncThunk<ExternalServices.Service.Item[], undefined>(
      "external-services/services/get-services",
      async (payload, { dispatch, getState, rejectWithValue }) => {
        const rootState = getState() as RootState;

        if (rootState.externalServices.orders.allServices.length > 0) {
          return rootState.externalServices.orders.allServices;
        }

        try {
          const { data } = await ExternalServicesService.Service.getServicesList({});
          await dispatch(setAllServices(data.Data));
          return data.Data;
        } catch (error: any) {
          return rejectWithValue(error?.response?.data);
        }
      }
    );
  }

  export namespace Service {
    export const setServicesFilter = createAsyncThunk(
      "external-services/services/set-filter",
      async (payload: IExternalServicesState["services"]["filters"], { rejectWithValue, getState, dispatch }) => {
        dispatch(setServicesFilters(payload));
        await dispatch(fetchServices()).unwrap();
      }
    );

    export const fetchServices = createAsyncThunk<ExternalServices.Service.Item[], undefined>(
      "external-services/services/fetch-services",
      async (payload, { dispatch, getState, rejectWithValue }) => {
        const rootState = getState() as RootState;

        dispatch(toggleServicesLoading(true));
        dispatch(setServices([]));
        dispatch(setServicesPagination(null));

        try {
          const { data } = await ExternalServicesService.Service.getServicesList({
            Enable: rootState.externalServices.services.filters.Enable ?? undefined,
          });
          await dispatch(setServices(data.Data));
          await dispatch(setServicesPagination(data.Page));
          return data.Data;
        } catch (error: any) {
          return rejectWithValue(error?.response?.data);
        } finally {
          dispatch(toggleServicesLoading(false));
        }
      }
    );

    export const loadMoreServices = createAsyncThunk<Pagination, undefined>(
      "external-services/services/load-more-services",
      async (payload, { dispatch, getState, rejectWithValue }) => {
        const rootState = getState() as RootState;

        try {
          const { data } = await ExternalServicesService.Service.getServicesList({
            Enable: rootState.externalServices.services.filters.Enable ?? undefined,
            Page: (rootState.externalServices.services.pagination?.Index ?? 0) + 1,
          });
          await dispatch(pushServices(data.Data));
          await dispatch(setServicesPagination(data.Page));
          return data.Page;
        } catch (error: any) {
          return rejectWithValue(error?.response?.data);
        }
      }
    );

    export const getCategories = createAsyncThunk<ExternalServices.Service.Category[], undefined>(
      "external-services/services/get-categories",
      async (payload, { dispatch, getState, rejectWithValue }) => {
        const rootState = getState() as RootState;
        if (rootState.externalServices.services.categories.length > 0) {
          return rootState.externalServices.services.categories;
        }

        try {
          const { data } = await ExternalServicesService.Service.getCategories();
          await dispatch(setServicesCategories(data.Data));
          return data.Data;
        } catch (error: any) {
          return rejectWithValue(error?.response?.data);
        }
      }
    );

    export const createService = createAsyncThunk<unknown, ExternalServices.Service.CreateBody>(
      "external-services/services/create-service",
      async (payload, { dispatch, rejectWithValue }) => {
        try {
          const { data } = await ExternalServicesService.Service.createService(payload);
          await dispatch(onAddService(data.Data));
          return data.Data;
        } catch (error: any) {
          return rejectWithValue(error?.response?.data);
        }
      }
    );

    export const updateService = createAsyncThunk<
      unknown,
      { serviceId: ExternalServices.Service.Item["Id"]; body: ExternalServices.Service.CreateBody }
    >("external-services/services/update-service", async (payload, { dispatch, rejectWithValue }) => {
      try {
        const { data } = await ExternalServicesService.Service.updateService(payload.serviceId, payload.body);
        await dispatch(onEditService(data.Data));
        return data.Data;
      } catch (error: any) {
        return rejectWithValue(error?.response?.data);
      }
    });

    export const deleteService = createAsyncThunk<unknown, ExternalServices.Service.Item["Id"]>(
      "external-services/services/delete-service",
      async (payload, { dispatch, rejectWithValue }) => {
        try {
          await ExternalServicesService.Service.deleteService(payload);
          dispatch(onDeleteService(payload));
          return payload;
        } catch (error: any) {
          return rejectWithValue(error?.response?.data);
        }
      }
    );
  }
}
