import { useSelector } from "react-redux";
import { To, useLocation, useNavigate } from "react-router-dom";
import { RootState, useAppDispatch } from "@/app/store";
import { acceptOccDetailsAndSave } from "@/app/store/slices/createOcc";
import BaseButton from "@/components/base-button";
import { BaseModal, BaseModalHeader, BaseModalContent, BaseModalActions } from "@/components/BaseModal";
import { showSnackbar, SnackbarType } from "@/app/store/slices/snackbar";

interface IConfirmOCCCreatingModalProps {
  isOpen: boolean;
  nextLink: To;
  onClose: () => void;
}

const ConfirmOCCCreatingModal: React.FC<IConfirmOCCCreatingModalProps> = ({ isOpen, onClose, nextLink }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const questions = useSelector((state: RootState) => state.createOcc.questions);
  const plannedEvents = useSelector((state: RootState) => state.createOcc.plannedEvents);
  const isAcceptOccLoading = useSelector((state: RootState) => state.createOcc.isAcceptOccLoading);

  const closeModal = () => {
    onClose();
  };

  const createOcc = async () => {
    if (questions.length === 0) {
      dispatch(
        showSnackbar({
          key: "create-occ-error",
          body: "Отсутствуют вопросы в повестке",
          type: SnackbarType.ERROR,
        })
      );
      return;
    }

    try {
      await dispatch(acceptOccDetailsAndSave()).unwrap();
      closeModal();
      navigate(nextLink, { state: location.state });
    } catch (error: any) {
      console.log(error);
      dispatch(
        showSnackbar({
          key: "create-occ-error",
          body: error?.Data?.Message ?? "Неизвестная ошибка. Попробуйте позже",
          type: SnackbarType.ERROR,
        })
      );
    }
  };

  return (
    <BaseModal isOpen={isOpen} maxWidth="421px" minWidth="421px">
      <BaseModalHeader title="Создать собрание?" onClose={closeModal} />

      <BaseModalContent>
        <p className="text--primary sf-text-regular">
          {plannedEvents?.Publication.Date} {plannedEvents?.Publication.Text.charAt(0).toLowerCase()}
          {plannedEvents?.Publication.Text.slice(1)}
        </p>
      </BaseModalContent>

      <BaseModalActions>
        <BaseButton variant="secondary" appearance="neutral" onClick={closeModal}>
          Отменить
        </BaseButton>

        <BaseButton isLoading={isAcceptOccLoading} variant="primary" appearance="positive" onClick={createOcc}>
          Создать
        </BaseButton>
      </BaseModalActions>
    </BaseModal>
  );
};

export default ConfirmOCCCreatingModal;
