import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";
import { paths } from "../consts/routes";
import { Profile } from "../types";

type IProtectedRouteProps = {
  children: JSX.Element;
  role?: Profile.UserRole;
  companyType?: Profile.CompanyType;
  institutionType?: Profile.InstitutionType;
};

const ProtectedRoute: React.FC<IProtectedRouteProps> = ({ children, role, companyType, institutionType }) => {
  const { token, profile } = useSelector((state: RootState) => state.user);

  if (!token) {
    return <Navigate to={paths.authorization} />;
  }

  if (role && role !== profile?.role) {
    return <div>403</div>;
  }

  if (companyType && companyType !== profile?.company_type) {
    return <div>403</div>;
  }

  if (institutionType && institutionType !== profile?.institution_type) {
    return <div>403</div>;
  }

  return children;
};

export default ProtectedRoute;
