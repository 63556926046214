import { ResponseAbstract, Profile, ResponseAbstractWithPagination } from "@/types";
import { House } from "@/types/house";
import client from "./config";

export namespace ProfileService {
  export function getUser() {
    return client.get<Profile.User>("/api/user");
  }

  export function getCompanyData() {
    return client.get<ResponseAbstract<Profile.CompanyData>>("/api/user/company/get-data");
  }

  export function login({
    email,
    password,
    phone,
    code,
  }: {
    email?: string;
    password?: string;
    phone?: string;
    code?: string;
  }) {
    return client.post<Profile.AuthResponse>("/api/auth/login", {
      email,
      password,
      phone,
      code,
    });
  }

  export function otpSend(phone: string) {
    return client.post<unknown>("/api/otp/send", {
      phone,
    });
  }

  export function logout() {
    return client.post<any>("/api/auth/logout");
  }

  export function getActiveAddress() {
    return client.get<ResponseAbstract<{ ActiveAddress: House.Item["FiasId"] }>>("/api/user/active-address");
  }

  export function setActiveAddress(FiasId: House.Item["FiasId"]) {
    return client.post<any>("/api/user/active-address", { FiasId });
  }

  export function getCadastralNumber(AddressId: House.Item["Id"]) {
    return client.get<ResponseAbstract<Profile.CadastralNumber>>("/api/address/cadastral-number-request", {
      params: { AddressId },
    });
  }

  export function setCadastralNumber(AddressId: House.Item["Id"], CadastralNumber: House.Item["CadastralNumber"]) {
    return client.post<ResponseAbstract<string>>("/api/address/cadastral-number-request", {
      AddressId,
      CadastralNumber,
    });
  }

  export function getUkHousesWithActualOCC(FiasId: House.Item["FiasId"], page: number = 1, limit: number = 50) {
    return client.get<ResponseAbstractWithPagination<Profile.HousesWithActualOCC[]>>("/api/user/company/my-houses", {
      params: { FiasId, page, limit },
    });
  }

  export function checkGisZhkh() {
    return client.post<ResponseAbstract<unknown>>("/api/user/company/check-gis-zhkh");
  }
}
