import { useMemo } from "react";
import BaseTooltip from "@/components/base-tooltip";
import BaseButton from "@/components/base-button";
import { editIcon } from "@/consts/icons";
import { SntRegistry } from "@/types";
import styles from "./styles.module.scss";

interface IOwnerItemProps {
  owner: SntRegistry.CreatedOwner | SntRegistry.SectionOwner;
  readonly: boolean;
  ownerChange: SntRegistry.SectionDetailsResponse["Included"];
  onOpen: () => void;
  onEdit: () => void;
  onRemove: () => void;
}

const OwnerItem: React.FC<IOwnerItemProps> = ({ owner, readonly, ownerChange, onEdit, onOpen, onRemove }) => {
  const isDeleteDisabled: boolean = useMemo(() => {
    return ownerChange.find((it) => it.OwnerId === owner.Id && !it.Attributes.Deleted) != null;
  }, [owner, ownerChange]);

  return (
    <div className={styles.owner_item} aria-readonly={readonly}>
      <div className={styles.owner_name}>
        <p className="sf-text-semibold text--primary color--text-primary">{owner.FullName}</p>
        <p className="sf-text-regular text--primary color--text-secondary">Собственник</p>
      </div>

      <div className={styles.owner_actions}>
        {readonly ? (
          <span className={styles.open_button} onClick={onOpen}>
            <span className="sf-text-regular caption--middle color--primary">Открыть</span>
          </span>
        ) : (
          <>
            <BaseTooltip title={!isDeleteDisabled ? "Удалить" : ""}>
              <BaseButton
                className={styles.owner_btn}
                disabled={isDeleteDisabled}
                variant="icon-basic"
                onClick={onRemove}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9 2.3C9 2.01997 9 1.87996 8.9455 1.773C8.89757 1.67892 8.82108 1.60243 8.727 1.5545C8.62004 1.5 8.48003 1.5 8.2 1.5H7.8C7.51997 1.5 7.37996 1.5 7.273 1.5545C7.17892 1.60243 7.10243 1.67892 7.0545 1.773C7 1.87996 7 2.01997 7 2.3V3H9V2.3ZM5.5 2.4V3.09091L1.71304 3.43518C1.30921 3.47189 1 3.81048 1 4.21598C1 4.64898 1.35102 5 1.78402 5H5.5H10.5H14.216C14.649 5 15 4.64898 15 4.21598C15 3.81048 14.6908 3.47189 14.287 3.43518L10.5 3.09091V2.4C10.5 1.55992 10.5 1.13988 10.3365 0.819014C10.1927 0.536771 9.96323 0.3073 9.68099 0.16349C9.36012 0 8.94008 0 8.1 0H7.9C7.05992 0 6.63988 0 6.31901 0.16349C6.03677 0.3073 5.8073 0.536771 5.66349 0.819014C5.5 1.13988 5.5 1.55992 5.5 2.4ZM3.0969 6.30816C3.04746 6.42715 3.06454 6.58088 3.0987 6.88834L3.76278 12.865C3.84667 13.62 3.88861 13.9975 4.06276 14.2824C4.21617 14.5333 4.43996 14.7336 4.70631 14.8584C5.00868 15 5.38849 15 6.1481 15H9.8519C10.6115 15 10.9913 15 11.2937 14.8584C11.56 14.7336 11.7838 14.5333 11.9372 14.2824C12.1114 13.9975 12.1533 13.62 12.2372 12.865L12.9013 6.88835C12.9355 6.58088 12.9525 6.42715 12.9031 6.30816C12.8597 6.20366 12.7821 6.11697 12.683 6.06228C12.5702 6 12.4155 6 12.1062 6H3.89381C3.58446 6 3.42978 6 3.31698 6.06228C3.21791 6.11697 3.14032 6.20366 3.0969 6.30816Z"
                    fill="#3A3B3D"
                  />
                </svg>
              </BaseButton>
            </BaseTooltip>

            <BaseTooltip title="Редактировать">
              <BaseButton className={styles.owner_btn} variant="icon-basic" onClick={onEdit}>
                {editIcon("#3A3B3D")}
              </BaseButton>
            </BaseTooltip>
          </>
        )}
      </div>
    </div>
  );
};

export default OwnerItem;
