import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import classNames from "classnames/bind";
import BaseLabel from "../BaseLabel";
import BaseDropdownMenuItem from "./menuItem";
import DropdownContext, { SelectMenuItem } from "./context";
import { dropDownIcon } from "@/consts/icons";
import styles from "./styles.module.scss";
import { Lockon16Icon } from "@/consts/icons-v2";

const cx = classNames.bind(styles);

interface IBaseDropdownProps {
  children?: JSX.Element | JSX.Element[] | React.ReactNode;
  customToggler?: JSX.Element;
  value?: SelectMenuItem["value"];
  display?: SelectMenuItem["display"];
  onSelect?: (value: SelectMenuItem["value"]) => void;
  label?: string;
  placeholder?: string;
  errorMessage?: string;
  isSelectable?: boolean;
  disabled?: boolean;
  required?: boolean;
  tooltipMessage?: string;
  variant?: "formfield";
  customIcon?: JSX.Element;
  className?: string;
  displayClassName?: string;
  optionsClassName?: string;
  usePortal?: boolean;
  transparentOnDisable?: boolean;
  locked?: boolean;
}

const BaseDropdown = ({
  children,
  customToggler,
  value,
  display,
  onSelect,
  label,
  placeholder,
  errorMessage,
  isSelectable = false,
  disabled = false,
  required,
  tooltipMessage,
  variant,
  customIcon,
  className,
  displayClassName,
  optionsClassName,
  usePortal = true,
  transparentOnDisable = false,
  locked = false,
}: IBaseDropdownProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [position, setPostion] = useState<{ left: string; right: string; top: string; width: string }>({
    left: "0",
    right: "0",
    top: "0",
    width: "auto",
  });

  const openDropdown = (event: React.SyntheticEvent) => {
    // event.stopPropagation();

    if (disabled) {
      return;
    }

    if (isOpen) {
      setOpen(false);
      return;
    }

    const rect = ref.current?.getBoundingClientRect();
    const scrolltop = window?.scrollY ?? 0;
    const top = scrolltop + (rect?.top ?? 0) + (rect?.height ?? 0);
    const windowWidth = window.innerWidth;
    let left = "auto";
    let right = "auto";
    if ((rect?.left ?? 0) + 300 > windowWidth) {
      right = `${windowWidth - (rect?.right ?? 0)}px`;
    } else {
      left = `${rect?.left ?? 0}px`;
    }
    setPostion({
      top: usePortal ? `${top}px` : `${rect?.height ?? 0}px`,
      left: usePortal ? left : "0",
      right: usePortal ? right : "0",
      width: `${rect?.width}px`,
    });
    setOpen(true);
  };

  const onSelectHandle = (value: SelectMenuItem["value"]) => {
    if (onSelect) {
      onSelect(value);
    }
    setOpen(false);
  };

  const onFocus = () => {
    setOpen(true);
  };

  const onResize = () => {
    setOpen(false);
  };

  const handleClickOutside = (event: any) => {
    if (
      isOpen &&
      ref.current &&
      !ref.current.contains(event.target) &&
      bodyRef.current &&
      !bodyRef.current.contains(event.target) &&
      !event.defaultPrevented
    ) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    window.addEventListener("resize", onResize, true);

    return () => {
      document.removeEventListener("click", handleClickOutside);
      window.removeEventListener("resize", onResize, true);
    };
  }, [ref, bodyRef, isOpen]);

  const roostClassname = cx(className, {
    root: true,
    disabled,
    "transparent-on-disable": transparentOnDisable,
    has_error: !!errorMessage,
    [`variant-${variant}`]: variant,
  });

  return (
    <DropdownContext.Provider
      value={{
        isOpen,
        value,
        onSelect: onSelectHandle,
        toggleOpen: setOpen,
      }}
    >
      <div ref={ref} data-error={errorMessage} className={roostClassname}>
        {label && (
          <BaseLabel required={required} tooltipMessage={tooltipMessage}>
            {label}
          </BaseLabel>
        )}

        <div className={cx(displayClassName, { display: true, flex: customToggler })} onClick={openDropdown}>
          {customToggler ? (
            customToggler
          ) : (
            <div>
              {display ? <span>{display}</span> : <span className={styles.placeholder}>{placeholder}</span>}
              <span className={cx({ icon: true, display_opened: isOpen })}>
                {locked ? Lockon16Icon("var(--icons-primary)") : customIcon ? customIcon : dropDownIcon}
              </span>
            </div>
          )}
          <input type="hidden" onFocus={onFocus} />
        </div>

        {isOpen &&
          createPortal(
            <div
              ref={bodyRef}
              className={cx(optionsClassName, { body: true, selectable: isSelectable })}
              style={{
                left: position.left,
                right: position.right,
                top: position.top,
                minWidth: position.width,
              }}
            >
              {children}
            </div>,
            !usePortal && ref.current ? ref.current : document.body
          )}
      </div>
    </DropdownContext.Provider>
  );
};

export default BaseDropdown;
export { BaseDropdown, BaseDropdownMenuItem };
