import { useSelector } from "react-redux";
import { RootState } from "@/app/store";
import MenuItem from "../../MenuItem";
import { paths } from "@/consts/routes";
import { CheckListIcon, KeyIcon } from "@/consts/icons-v2";

const InstitutionMunicipalityMenu: React.FC = () => {
  const companiesCount = useSelector((state: RootState) => state.institution.companies.count);

  return (
    <>
      <MenuItem
        path={paths.municipality().statistic}
        text="Статистические данные"
        icon={CheckListIcon("var(--lkl-c--icons-primary)")}
      />
      <MenuItem
        path={paths.municipality().companies.list}
        text="Организации"
        icon={KeyIcon("var(--lkl-c--icons-primary)")}
      >
        <span className="lkl-font--sf-text--medium lkl-tpgrhy--text--primary lkl-color--text-secondary">
          {companiesCount}
        </span>
      </MenuItem>
    </>
  );
};

export default InstitutionMunicipalityMenu;
