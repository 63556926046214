import { createAsyncThunk } from "@reduxjs/toolkit";
import { OccInitiatives, OCCV2Order, Pagination } from "@/types";
import { RootState } from "@/app/store";
import { showSnackbar, SnackbarType } from "@/app/store/slices/snackbar";
import { OccInitiativesService } from "@/services/v2";
import {
  IOccInitiativesState,
  toggleLoading,
  setInitiativeList,
  pushInitiative,
  setFilters,
  setPagination,
  setCount,
  setSelectedDetails,
  setSelectedQuestions,
  pushSelectedQuestions,
  setSelectedQuestionsPagination,
  toggleSelectedQuestionsLoading,
  setSelectedVotes,
  pushSelectedVotes,
  setSelectedVotesPagination,
  toggleSelectedVotesLoading,
  clearSelected,
} from "./";

export namespace OccInitiativeActions {
  export const setFilter = createAsyncThunk(
    "occ-initiatives/set-filter",
    async (payload: IOccInitiativesState["filters"], { rejectWithValue, getState, dispatch }) => {
      dispatch(setFilters(payload));
      await dispatch(fetchList()).unwrap();
    }
  );

  export const fetchList = createAsyncThunk<OccInitiatives.ListItem[], undefined>(
    "occ-initiatives/fetch-list",
    async (payload, { dispatch, getState, rejectWithValue }) => {
      const rootState = getState() as RootState;

      dispatch(toggleLoading(true));
      dispatch(setInitiativeList([]));
      dispatch(setPagination(null));

      try {
        const { data } = await OccInitiativesService.getList({
          Status: rootState.occInitiatives.filters.Status ?? undefined,
        });
        await dispatch(setInitiativeList(data.Data));
        await dispatch(setPagination(data.Page));
        return data.Data;
      } catch (error: any) {
        return rejectWithValue(error?.response?.data);
      } finally {
        dispatch(toggleLoading(false));
      }
    }
  );

  export const loadMore = createAsyncThunk<Pagination, undefined>(
    "occ-initiatives/load-more",
    async (payload, { dispatch, getState, rejectWithValue }) => {
      const rootState = getState() as RootState;

      try {
        const { data } = await OccInitiativesService.getList({
          Status: rootState.occInitiatives.filters.Status ?? undefined,
          Page: (rootState.occInitiatives.pagination?.Index ?? 0) + 1,
        });
        await dispatch(pushInitiative(data.Data));
        await dispatch(setPagination(data.Page));
        return data.Page;
      } catch (error: any) {
        return rejectWithValue(error?.response?.data);
      }
    }
  );

  export const getCount = createAsyncThunk<number, undefined>(
    "occ-initiatives/get-count",
    async (payload, { dispatch, rejectWithValue }) => {
      try {
        const { data } = await OccInitiativesService.getCount();
        await dispatch(setCount(data.Data));
        return data.Data;
      } catch (error: any) {
        dispatch(
          showSnackbar({
            key: "occ-initiatives-count",
            title: "Ошибка проверки поступивших заявок",
            body: error?.Data?.Message ?? "Попробуйте войти позднее",

            type: SnackbarType.ERROR,
          })
        );
        return rejectWithValue(error?.response?.data);
      }
    }
  );

  export const getInitiativeDetails = createAsyncThunk<
    OccInitiatives.FullDetails,
    { initiativeId: OccInitiatives.ListItem["Id"]; force?: boolean }
  >("occ-initiatives/get-initiative-details", async (payload, { getState, dispatch, rejectWithValue }) => {
    const rootState = getState() as RootState;

    try {
      if (payload.initiativeId !== rootState.occInitiatives.selected.details?.Id && !payload.force) {
        dispatch(clearSelected());
      }

      const { data } = await OccInitiativesService.getById(payload.initiativeId);
      await dispatch(setSelectedDetails(data.Data));
      return data.Data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  });

  export const getInitiativeQuestions = createAsyncThunk<OccInitiatives.Question[], OccInitiatives.ListItem["Id"]>(
    "occ-initiatives/get-initiative-questions",
    async (initiativeId, { getState, dispatch, rejectWithValue }) => {
      const rootState = getState() as RootState;
      if (
        initiativeId === rootState.occInitiatives.selected.details?.Id &&
        rootState.occInitiatives.selected.questions.list.length > 0
      ) {
        return rootState.occInitiatives.selected.questions.list;
      }

      dispatch(toggleSelectedQuestionsLoading(true));
      dispatch(setSelectedQuestions([]));
      dispatch(setSelectedQuestionsPagination(null));

      try {
        const { data } = await OccInitiativesService.getQuestions(initiativeId);
        await dispatch(setSelectedQuestions(data.Data));
        await dispatch(setSelectedQuestionsPagination(data.Page));
        return data.Data;
      } catch (error: any) {
        return rejectWithValue(error?.response?.data);
      } finally {
        dispatch(toggleSelectedQuestionsLoading(false));
      }
    }
  );

  export const loadMoreInitiativeQuestions = createAsyncThunk<Pagination, OccInitiatives.ListItem["Id"]>(
    "occ-initiatives/load-more-initiative-questions",
    async (initiativeId, { getState, dispatch, rejectWithValue }) => {
      const rootState = getState() as RootState;

      try {
        const { data } = await OccInitiativesService.getQuestions(
          initiativeId,
          (rootState.occInitiatives.selected.questions?.pagination?.Index ?? 0) + 1
        );
        await dispatch(pushSelectedQuestions(data.Data));
        await dispatch(setSelectedQuestionsPagination(data.Page));
        return data.Page;
      } catch (error: any) {
        return rejectWithValue(error?.response?.data);
      }
    }
  );

  export const getInitiativeVotes = createAsyncThunk<OccInitiatives.Vote[], OccInitiatives.ListItem["Id"]>(
    "occ-initiatives/get-initiative-votes",
    async (initiativeId, { getState, dispatch, rejectWithValue }) => {
      const rootState = getState() as RootState;
      if (
        initiativeId === rootState.occInitiatives.selected.details?.Id &&
        rootState.occInitiatives.selected.votes.list.length > 0
      ) {
        return rootState.occInitiatives.selected.votes.list;
      }

      dispatch(toggleSelectedVotesLoading(true));
      dispatch(setSelectedVotes([]));
      dispatch(setSelectedVotesPagination(null));

      try {
        const { data } = await OccInitiativesService.getVotes(initiativeId);
        await dispatch(setSelectedVotes(data.Data));
        await dispatch(setSelectedVotesPagination(data.Page));
        return data.Data;
      } catch (error: any) {
        return rejectWithValue(error?.response?.data);
      } finally {
        dispatch(toggleSelectedVotesLoading(false));
      }
    }
  );

  export const loadMoreInitiativeVotes = createAsyncThunk<Pagination, OccInitiatives.ListItem["Id"]>(
    "occ-initiatives/load-more-initiative-votes",
    async (initiativeId, { getState, dispatch, rejectWithValue }) => {
      const rootState = getState() as RootState;

      try {
        const { data } = await OccInitiativesService.getVotes(
          initiativeId,
          (rootState.occInitiatives.selected.questions?.pagination?.Index ?? 0) + 1
        );
        await dispatch(pushSelectedVotes(data.Data));
        await dispatch(setSelectedVotesPagination(data.Page));
        return data.Page;
      } catch (error: any) {
        return rejectWithValue(error?.response?.data);
      }
    }
  );

  export const acceptInitiative = createAsyncThunk<unknown, OccInitiatives.AcceptInitiativeBody>(
    "occ-initiatives/accept",
    async (payload, { getState, dispatch, rejectWithValue }) => {
      const rootState = getState() as RootState;

      try {
        if (!rootState.occInitiatives.selected.details?.Id) {
          throw Error("no selected initiative");
        }
        const { data } = await OccInitiativesService.accept(rootState.occInitiatives.selected.details.Id, payload);
        return data.Data;
      } catch (error: any) {
        return rejectWithValue(error?.response?.data);
      }
    }
  );

  export const createOcc = createAsyncThunk<OCCV2Order.Base["Id"], OccInitiatives.ListItem["Id"]>(
    "occ-initiatives/accept",
    async (payload, { rejectWithValue }) => {
      try {
        const { data } = await OccInitiativesService.createOcc(payload);
        return data.Data;
      } catch (error: any) {
        return rejectWithValue(error?.response?.data);
      }
    }
  );
}
