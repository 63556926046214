import { SxProps } from "@mui/material";
import classNames from "classnames/bind";
import StyledInput from "./styled-input";
import { Lockon16Icon } from "@/consts/icons-v2";
import BaseLabel from "../BaseLabel";
import ValueLengthCounter from "./counter";
import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

interface IBaseInputProps {
  value: string;
  onChange?: (value: string) => void;
  label?: string;
  type?: string;
  disabled?: boolean;
  required?: boolean;
  tooltipMessage?: string;
  placeholder?: string;
  errorMessage?: string;
  style?: React.CSSProperties;
  sx?: SxProps;
  maxlength?: number | null | undefined;
  counter?: boolean;
  locked?: boolean;
  endAdornment?: React.ReactNode;
  name?: string;
  onFocus?: () => void;
  onBlur?: (e: React.SyntheticEvent) => void;
}

const BaseInput = ({
  value,
  onChange,
  label,
  type,
  disabled,
  required,
  tooltipMessage,
  placeholder,
  errorMessage,
  style,
  sx,
  maxlength,
  counter = false,
  locked = false,
  endAdornment,
  name,
  onFocus,
  onBlur,
}: IBaseInputProps) => {
  function handleChange(value: string) {
    if (onChange) {
      onChange(value);
    }
  }

  function handleOnFocus() {
    if (onFocus) {
      onFocus();
    }
  }

  function handleOnBlur(e: React.SyntheticEvent) {
    if (onBlur) {
      onBlur(e);
    }
  }

  return (
    <div
      data-error={errorMessage}
      className={cx({
        container: true,
        has_error: !!errorMessage,
        locked,
      })}
    >
      {(label || (counter && maxlength)) && (
        <div className="flex between">
          <BaseLabel required={required} tooltipMessage={tooltipMessage}>
            {label}
          </BaseLabel>

          {counter && maxlength && <ValueLengthCounter value={value} maxlength={maxlength} />}
        </div>
      )}

      <div className={styles.field}>
        <StyledInput
          value={value}
          name={name}
          placeholder={placeholder}
          error={!!errorMessage}
          type={type ?? "text"}
          fullWidth
          disabled={disabled}
          readOnly={locked}
          style={style}
          sx={sx}
          inputProps={{ maxLength: maxlength }}
          onChange={(e) => handleChange(e.target.value)}
          endAdornment={endAdornment}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
        />
        {locked && <span className={styles.endAdornment}>{Lockon16Icon("var(--icons-primary)")}</span>}
      </div>
    </div>
  );
};

export default BaseInput;
