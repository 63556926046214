import { useSelector } from "react-redux";
import { RootState } from "@/app/store";
import { paths } from "@/consts/routes";
import { isNullOrUndefined } from "@/helpers/isNullOrUndefined";
import { ChevronRight16Icon, FavoriteBookIcon, PhoneIcon, PollsIcon, ShoppingCars24Icon } from "@/consts/icons-v2";
import MenuItem from "../../MenuItem";
import NestedMenuItem from "../../NestedMenuItem";
import styles from "../../MenuItem/styles.module.scss";

const CompanyCommonMenu: React.FC = () => {
  const isExternalServicesEnabled = useSelector((state: RootState) => state.user.isExternalServicesEnabled);
  const unreadChatsCount = useSelector((state: RootState) => state.chats.unreadCount);

  return (
    <>
      <NestedMenuItem text="Полезное" icon={FavoriteBookIcon("var(--lkl-c--icons-primary)")}>
        <MenuItem path={paths.contacts} text="Контакты">
          {ChevronRight16Icon("var(--lkl-c--icons-primary)")}
        </MenuItem>
        <MenuItem path={paths.documents} text="Документы">
          {ChevronRight16Icon("var(--lkl-c--icons-primary)")}
        </MenuItem>
      </NestedMenuItem>

      <MenuItem path={paths.users} text="Пользователи Локоло" icon={PhoneIcon("var(--lkl-c--icons-primary)")} />

      {isExternalServicesEnabled && (
        <MenuItem
          path={paths.externalServices.fullPath().root}
          text="Платные услуги"
          icon={ShoppingCars24Icon("var(--lkl-c--icons-primary)")}
        />
      )}

      <MenuItem path={paths.appeals.fullPath().list} text="Обращения" icon={PollsIcon("var(--lkl-c--icons-primary)")}>
        {!isNullOrUndefined(unreadChatsCount) && unreadChatsCount > 0 && (
          <span className={styles["b-menu-item__counter"]}>
            <span className="lkl-font--sf-text--medium lkl-tpgrhy--text--primary lkl-color--text-white">
              {unreadChatsCount}
            </span>
          </span>
        )}
      </MenuItem>
    </>
  );
};

export default CompanyCommonMenu;
